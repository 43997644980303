const ContactsSvg = () => (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_578_297)">
        <path d="M14.0332 8.60595C15.8719 8.60595 17.3675 7.11006 17.3675 5.27133C17.3675 3.4326 15.8719 1.93671 14.0332 1.93671C12.1945 1.93671 10.6982 3.4326 10.6982 5.27133C10.6982 7.11006 12.1945 8.60595 14.0332 8.60595ZM15.9389 5.27133C15.9389 6.32219 15.0837 7.17738 14.0332 7.17738C12.982 7.17738 12.1268 6.32219 12.1268 5.27133C12.1268 4.22048 12.982 3.36529 14.0332 3.36529C15.0837 3.36529 15.9389 4.22048 15.9389 5.27133Z" fill="white" />
        <path
            d="M14.036 9.67738C13.3635 9.67738 12.7204 9.78515 12.124 9.99756C11.7529 10.1297 11.559 10.5385 11.6908 10.9099C11.8118 11.2491 12.162 11.4271 12.506 11.3572C12.5388 11.3505 12.5715 11.3546 12.6039 11.3431C13.0455 11.1858 13.5275 11.106 14.036 11.106C15.2497 11.106 16.3895 11.5772 17.2447 12.4323C18.0999 13.2879 18.5714 14.4277 18.5714 15.6418C18.5714 15.9072 18.4682 16.1562 18.2805 16.3442C18.0929 16.5315 17.8467 16.6347 17.5858 16.6347H13.743C13.6927 16.6347 13.6487 16.6535 13.6013 16.6633C13.277 16.7309 13.0287 17.0048 13.0287 17.349C13.0287 17.7435 13.3482 18.0633 13.743 18.0633H17.5858C18.2282 18.0633 18.8337 17.8111 19.2906 17.3542C19.7482 16.897 20 16.2891 20 15.6418C20 14.0461 19.3799 12.5478 18.2547 11.4223C17.1296 10.2972 15.6313 9.67738 14.036 9.67738Z"
            fill="white"
        />
        <path d="M5.96678 8.60595C7.80551 8.60595 9.30175 7.11006 9.30175 5.27133C9.30175 3.4326 7.80551 1.93671 5.96678 1.93671C4.12805 1.93671 2.63251 3.4326 2.63251 5.27133C2.63251 7.11006 4.12805 8.60595 5.96678 8.60595ZM5.96678 3.36529C7.01798 3.36529 7.87317 4.22048 7.87317 5.27133C7.87317 6.32219 7.01798 7.17738 5.96678 7.17738C4.91627 7.17738 4.06108 6.32219 4.06108 5.27133C4.06108 4.22048 4.91627 3.36529 5.96678 3.36529Z" fill="white" />
        <path d="M2.4163 18.0608H9.51802C10.8503 18.0608 11.9343 16.9769 11.9343 15.6445C11.9343 12.3542 9.25713 9.67738 5.96681 9.67738C2.67648 9.67738 0 12.3542 0 15.6445C0 16.9769 1.08399 18.0608 2.4163 18.0608ZM5.96681 11.106C8.4696 11.106 10.5057 13.1421 10.5057 15.6445C10.5057 16.1893 10.0628 16.6323 9.51802 16.6323H2.4163C1.87152 16.6323 1.42857 16.1893 1.42857 15.6445C1.42857 13.1421 3.46471 11.106 5.96681 11.106Z" fill="white" />
    </g>
    <defs>
        <clipPath id="clip0_578_297">
            <rect width="20" height="20" fill="white" />
        </clipPath>
    </defs>
</svg>);

export default ContactsSvg