import { Drawer } from "@mui/material"
import { ReactNode } from "react"
import './CustomDrawer.scss'

type CustomDrawerProps = {
    children: ReactNode,
    open: boolean,
    close: () => void,
    backDropDisable?: boolean
}

function CustomDrawer(props: CustomDrawerProps) {
    return (
        <Drawer classes={{ root: 'custom-drawer' }} anchor="right" open={props.open} onClose={!props.backDropDisable ? props.close : undefined}>
            {props.children}
        </Drawer>
    );
}

export default CustomDrawer