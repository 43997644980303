const LogoutSvg = () => (<svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_585_352)">
  <path d="M10 11.7647C9.688 11.7647 9.38876 11.6408 9.16813 11.4201C8.9475 11.1995 8.82355 10.9003 8.82355 10.5882V1.17647C8.82355 0.864451 8.9475 0.565211 9.16813 0.34458C9.38876 0.123949 9.688 0 10 0C10.312 0 10.6113 0.123949 10.8319 0.34458C11.0525 0.565211 11.1765 0.864451 11.1765 1.17647V10.5882C11.1765 10.9003 11.0525 11.1995 10.8319 11.4201C10.6113 11.6408 10.312 11.7647 10 11.7647Z" fill="white" />
  <path
    d="M9.99999 20C7.99809 19.9978 6.04905 19.3573 4.43605 18.1716C2.82306 16.9859 1.63014 15.3168 1.03064 13.4068C0.43113 11.4967 0.456263 9.44532 1.10239 7.55055C1.74851 5.65579 2.98196 4.0164 4.62352 2.87059C4.74969 2.77278 4.89445 2.70167 5.04898 2.6616C5.20351 2.62153 5.36459 2.61332 5.52239 2.63749C5.68019 2.66166 5.83143 2.71769 5.96689 2.80217C6.10234 2.88665 6.21919 2.99782 6.31031 3.12891C6.40143 3.25999 6.46492 3.40825 6.49691 3.56466C6.5289 3.72106 6.52872 3.88234 6.49639 4.03868C6.46405 4.19501 6.40024 4.34313 6.30883 4.47402C6.21743 4.6049 6.10034 4.71581 5.96469 4.8C4.73182 5.65899 3.80523 6.88899 3.31975 8.31101C2.83427 9.73303 2.81523 11.2729 3.2654 12.7065C3.71557 14.14 4.61147 15.3926 5.82272 16.2818C7.03397 17.171 8.49738 17.6505 9.99999 17.6505C11.5026 17.6505 12.966 17.171 14.1773 16.2818C15.3885 15.3926 16.2844 14.14 16.7346 12.7065C17.1847 11.2729 17.1657 9.73303 16.6802 8.31101C16.1947 6.88899 15.2682 5.65899 14.0353 4.8C13.797 4.61532 13.6388 4.34624 13.5932 4.04828C13.5475 3.75031 13.618 3.4462 13.7901 3.19869C13.9621 2.95118 14.2226 2.77915 14.5178 2.7181C14.813 2.65705 15.1203 2.71163 15.3765 2.87059C17.018 4.0164 18.2515 5.65579 18.8976 7.55055C19.5437 9.44532 19.5688 11.4967 18.9693 13.4068C18.3698 15.3168 17.1769 16.9859 15.5639 18.1716C13.9509 19.3573 12.0019 19.9978 9.99999 20Z"
    fill="white"
  />
</g>
<defs>
  <clipPath id="clip0_585_352">
    <rect width="20" height="20" fill="white" />
  </clipPath>
</defs>
</svg>);

export default LogoutSvg