import { MouseEventHandler } from "react"

const LinkIcon = (props: { isActive?: boolean, onClick: MouseEventHandler<SVGSVGElement> }) => {
    return (<svg onClick={props.onClick} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_328_453)">
            <path d="M11.0443 1.53798L8.04186 4.5404C5.99646 6.58579 5.99646 9.91395 8.04186 11.9593C8.23717 12.1547 8.49303 12.2523 8.74889 12.2523C9.00475 12.2523 9.26061 12.1547 9.45592 11.9593C9.84654 11.569 9.84654 10.9357 9.45592 10.5453C8.19029 9.27966 8.19029 7.22009 9.45592 5.95446L12.4583 2.95204C13.724 1.68642 15.7835 1.68642 17.0492 2.95204C18.3148 4.21767 18.3148 6.27724 17.0492 7.54286L15.0477 9.54458C14.6571 9.93497 14.6571 10.5683 15.0477 10.9586C15.4374 11.3493 16.0712 11.3493 16.4618 10.9586L18.4632 8.95692C19.454 7.96618 19.9999 6.6488 19.9999 5.24743C19.9999 3.84606 19.454 2.52868 18.4632 1.53798C16.4178 -0.507451 13.0897 -0.507451 11.0443 1.53798Z" fill="black" />
            <path d="M5.24609 19.9985C6.58984 19.9985 7.93262 19.487 8.95555 18.4643L11.958 15.4619C14.0034 13.4165 14.0034 10.0884 11.958 8.04297C11.5678 7.65234 10.9341 7.65234 10.5439 8.04297C10.1533 8.43336 10.1533 9.06664 10.5439 9.45703C11.8095 10.7227 11.8095 12.7822 10.5439 14.0479L7.54148 17.0503C6.27586 18.3159 4.21629 18.3159 2.95066 17.0503C1.68504 15.7846 1.68504 13.7251 2.95066 12.4595L4.45211 10.9582C4.84273 10.5679 4.84273 9.93457 4.45211 9.54418C4.06148 9.15355 3.4277 9.15355 3.03805 9.54418L1.5366 11.0454C0.545899 12.0361 0 13.3535 0 14.7548C0 16.1562 0.545898 17.4736 1.53664 18.4643C2.55957 19.487 3.90234 19.9985 5.24609 19.9985Z" fill="black" />
        </g>
        <defs>
            <clipPath id="clip0_328_453">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>
    )
}

export default LinkIcon