import { useCallback, useEffect, useState } from 'react'
import TextInput from '../../../../components/Builder/TextInput/TextInput'
import Dropdown from '../../../../components/Builder/DropDown/DropDown'
import WidgetStyleIcon from '../../../../assets/images/builder/WidgetStyleIcon'
import DecreaseIndentIcon from '../../../../assets/images/builder/DecreaseIndentIcon'
import BoldIcon from '../../../../assets/images/builder/BoldIcon'
import ItalicIcon from '../../../../assets/images/builder/ItalicIcon'
import UnderlineIcon from '../../../../assets/images/builder/UnderlineIcon'
import CrossCharacterIcon from '../../../../assets/images/builder/CrossCharacterIcon'
import AlignLeftIcon from '../../../../assets/images/builder/AlignLeftIcon'
import AlignCenterIcon from '../../../../assets/images/builder/AlignCenterIcon'
import AlignRightIcon from '../../../../assets/images/builder/AlignRightIcon'
import AlignJustifyIcon from '../../../../assets/images/builder/AlignJustifyIcon'
import ColorPickIcon from '../../../../assets/images/builder/ColorPickIcon'
import ToolbarPlugins, { fontFamilies, fontSizes } from '../plugins/ToolbarPlugin'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $getSelection, $isRangeSelection } from 'lexical'
import { mergeRegister } from '@lexical/utils';
import SuperScript from '../../../../assets/images/builder/SuperScript'
import SubScript from '../../../../assets/images/builder/SubScript'
import NumberLine from '../../../../assets/images/builder/NumberLine'
import DotLine from '../../../../assets/images/builder/DotLine'
import FontIcon from '../../../../assets/images/builder/FontIcon'
import Checked from '../../../../assets/images/builder/CheckedList'
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import OutdentIcon from '../../../../assets/images/builder/Outdent'
import IndentIcon from '../../../../assets/images/builder/Indent'
import QuoteIcon from '../../../../assets/images/builder/Quote'
import CodeIcon from '../../../../assets/images/builder/CodeIcon'
import LinkIcon from '../../../../assets/images/builder/LinkIcon'
import FormatLineSpacingIcon from '@mui/icons-material/FormatLineSpacing';
import CapitalizeIcon from '../../../../assets/images/builder/CapitalizeIcon'
import FontSizeIcon from '../../../../assets/images/builder/FontSizeIcon'
import ClearFormattingIcon from '../../../../assets/images/builder/ClearFormattingIcon'

const Controls = () => {

    const [editor] = useLexicalComposerContext();
    const [isBold, setIsBold] = useState<boolean>(false);
    const [isItalic, setIsItalic] = useState<boolean>(false);
    const [isUnderline, setIsUnderline] = useState<boolean>(false);
    const [color, setColor] = useState<string | undefined>(undefined);
    const [isStrikethrough, setIsStrikethrough] = useState<boolean>(false);
    const [canUndo, setCanUndo] = useState<boolean>(false);
    const [canRedo, setCanRedo] = useState<boolean>(false);

    const toolbar = new ToolbarPlugins({ editor });

    const $updateToolbar = useCallback(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
            // Update text format
            setIsBold(selection.hasFormat('bold'));
            setIsItalic(selection.hasFormat('italic'));
            setIsUnderline(selection.hasFormat('underline'));
            setIsStrikethrough(selection.hasFormat('strikethrough'));

        }
    }, []);

    useEffect(() => {
        mergeRegister(
            editor.registerUpdateListener(({ editorState }) => {
                editorState.read(() => {
                    $updateToolbar();
                });
            }),
        );
    }, [editor, $updateToolbar]);

    return (
        <div className='controls-container'>
            <div className="control-section">
                {/* <div className="header">
                    <HeadingWidgetIcon />
                    Heading Widget
                </div>
                <div className="outer">
                    <TextInput placeholder={'Add URL to Link this Widget'} onChange={(val: any) => console.log(val)} icon={<UrlIcon />} />
                </div> */}
                <div className="header">
                    <WidgetStyleIcon />
                    Editor Controls
                </div>
                <div className="outer">
                    <div className="control-row">
                        <Dropdown sx={{ width: '100%' }} searchable icon={<FontIcon />} options={fontFamilies()} onSelect={(val) => toolbar.changeFontFamily(val.value)} />
                        <Dropdown sx={{ maxWidth: '140px' }} icon={<FontSizeIcon />} options={fontSizes()} defaultValue="16" onSelect={(val) => toolbar.changeTextSize(val.value)} />
                    </div>
                    <div className="control-row">
                        <Dropdown sx={{ width: '50%' }} icon={<CapitalizeIcon />} options={toolbar.headingFormats()} defaultValue= "Heading 1" onSelect={(val) => toolbar.formatHeading(val.value)} />
                        <div className='control-custom'>
                            <BoldIcon isActive={isBold} onClick={toolbar.boldText} />
                            <ItalicIcon isActive={isItalic} onClick={toolbar.italicText} />
                            <UnderlineIcon isActive={isUnderline} onClick={toolbar.underlineText} />
                            <NumberLine onClick={toolbar.numberedList} />
                            <DotLine onClick={toolbar.bulletedList} />
                            <Checked isActive={isStrikethrough} onClick={toolbar.checkedList} />
                        </div>
                    </div>
                    <div className="control-row">
                        <TextInput type='color' sx={{ width: '50%' }} onChange={(val: any) => toolbar.backgroundColorText(val.target.value)} icon={<FormatColorFillIcon style={{ color: '#134B5F' }} />} />
                        <TextInput type='color' sx={{ width: '50%' }} onChange={(val: any) => toolbar.colorText(val.target.value)} icon={<ColorPickIcon />} />
                    </div>
                    <div className="control-row" style={{ justifyContent: "space-between" }}>
                        <div className='control-custom'>
                            <AlignLeftIcon onClick={toolbar.leftAlignText} />
                            <AlignCenterIcon onClick={toolbar.centerAlignText} />
                            <AlignRightIcon onClick={toolbar.rightAlignText} />
                            <AlignJustifyIcon onClick={toolbar.justifyAlignText} />
                            <AlignLeftIcon onClick={toolbar.startAlignText} />
                            <AlignRightIcon onClick={toolbar.endAlignText} />
                        </div>
                        <div className='control-custom'>
                            <OutdentIcon onClick={toolbar.outdentText} />
                            <IndentIcon onClick={toolbar.indentText} />
                        </div>
                        <div className='control-custom'>
                            <SuperScript onClick={toolbar.superScript} />
                            <SubScript onClick={toolbar.subScript} />
                            <CrossCharacterIcon isActive={isStrikethrough} onClick={toolbar.strikethroughText} />
                        </div>
                    </div>
                    <div className="control-row">
                        <div className='control-custom'>
                            <QuoteIcon onClick={toolbar.quoteText} />
                            <CodeIcon onClick={toolbar.codeText} />
                            <LinkIcon onClick={toolbar.insertLink} />
                        </div>
                        <Dropdown sx={{ width: '35%' }} icon={<FormatLineSpacingIcon style={{ color: '#134B5F' }} />} options={toolbar.lineHights()} defaultValue="1" onSelect={(val) => toolbar.changeLineHight(val.value)} />
                        <Dropdown sx={{ width: '35%' }} icon={<DecreaseIndentIcon />} options={toolbar.letterSpacing()} defaultValue="0" onSelect={(val) => toolbar.changeLetterSpacing(val.value)} />
                        {/* <Dropdown sx={{ width: '100%' }} icon={<IncreaseIndentIcon />} options={toolbar.fontSizes()} defaultValue="34" onSelect={(val) => toolbar.changeWordSpacing(val.value)} /> */}
                    </div>
                    <hr />
                    <div className="control-row">
                        <button onClick={() => { toolbar.clearFormat()}} className="button border-btn" style={{ width: "100%", justifyContent: "center" }} type="button">
                            <span className="icon">
                                <ClearFormattingIcon />
                            </span>
                            <span className="txt fz-16 ttu primary-font">Clear Formatting</span>
                        </button>
                    </div>
                    {/* <div className="control-row">
                        <Dropdown sx={{ maxWidth: '156px', width: '100%' }} icon={<ParagraphSpacingIcon2 />} options={toolbar.lineHights()} defaultValue="1" onSelect={(val) => toolbar.changeParagraphSpacing(val.value)} />
                        <div className='control-custom'>
                            <Capitalize onClick={toolbar.upperCase} />
                            <SentenceCase onClick={toolbar.capitalize} />
                            <LowerCase onClick={toolbar.lowerCase} />
                            <AlignJustifyIcon onClick={toolbar.justifyAlignText} />
                        </div>
                    </div> */}
                </div>
                {/* <div className="header">
                    <MarginsIcon />
                    Widget Positioning
                </div>
                <div className="outer">
                    <div className="control-row">
                        <Dropdown sx={{ width: '100%' }} icon={<ParagraphSpacingIcon />} options={toolbar.lineHights()} defaultValue="1" onSelect={(val) => toolbar.changeLineHight(val.value)} />
                        <Dropdown sx={{ width: '100%' }} icon={<DecreaseIndentIcon />} options={toolbar.fontSizes()} defaultValue="34" onSelect={(val) => { console.log(val) }} />
                        <Dropdown sx={{ width: '100%' }} icon={<IncreaseIndentIcon />} options={toolbar.fontSizes()} defaultValue="34" onSelect={(val) => toolbar.changeLetterSpacing(val.value)} />
                    </div>
                </div> */}
            </div>
        </div >
    )
}

export default Controls