import React from 'react'

const Positioning = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_291_2609)">
                <path d="M10.2225 2.04798L8.30569 0.131142C8.2188 0.0442489 8.1029 -0.000238074 7.98309 2.20835e-05C7.86108 -0.00114863 7.74258 0.0442489 7.65568 0.131142L5.77838 2.00844C5.60252 2.18431 5.60252 2.47022 5.77838 2.64596L6.02046 2.88803C6.19021 3.05779 6.48653 3.05779 6.65642 2.88803L7.3751 2.16779V4.8145C7.3751 4.82257 7.37979 4.8292 7.38122 4.83675C7.40073 5.06738 7.59416 5.24988 7.82999 5.24988H8.1721C8.42068 5.24988 8.62503 5.048 8.62503 4.79941V4.50387C8.62503 4.50257 8.62503 4.50166 8.62503 4.50062V2.20707L9.34398 2.92745C9.51984 3.10332 9.80511 3.10332 9.98084 2.92745L10.2225 2.68563C10.3984 2.50963 10.3984 2.22359 10.2225 2.04798Z" fill="#251C15" />
                <path d="M10.2224 13.3148L9.98049 13.0723C9.81073 12.9023 9.51428 12.9023 9.34427 13.0723L8.62532 13.7927V11.5093C8.62532 11.508 8.62532 11.5072 8.62532 11.506V11.2105C8.62532 10.9618 8.42084 10.75 8.17252 10.75H7.83015C7.59458 10.75 7.40115 10.9372 7.38151 11.1681C7.38008 11.1757 7.3754 11.1875 7.3754 11.1954V13.8322L6.65671 13.112C6.48683 12.9421 6.18986 12.9421 6.01997 13.112L5.77803 13.354C5.60242 13.5298 5.60268 13.8158 5.77855 13.9917L7.65598 15.8689C7.74066 15.9538 7.8537 16 7.97038 16H7.98729C8.10813 16 8.22091 15.9539 8.30572 15.8689L10.2224 13.9522C10.3983 13.7764 10.3983 13.4905 10.2224 13.3148Z" fill="#251C15" />
                <path d="M4.78922 7.37654H4.75072H4.49225C4.49134 7.37654 4.49043 7.37654 4.48939 7.37654H2.21691L2.93729 6.66006C3.02223 6.57525 3.06893 6.46391 3.06893 6.34332C3.06893 6.22261 3.02223 6.11035 2.93729 6.02567L2.69521 5.78411C2.51934 5.60825 2.2333 5.60851 2.05769 5.78437L0.140849 7.70109C0.0539559 7.78785 0.00751776 7.90427 0.00985918 8.02368C0.00738768 8.14791 0.0539559 8.2642 0.140849 8.35109L2.01815 10.2285C2.10608 10.3165 2.22146 10.3604 2.33697 10.3604C2.45248 10.3604 2.56786 10.3165 2.65579 10.2285L2.89774 9.98657C2.98268 9.90176 3.02951 9.78846 3.02951 9.66788C3.02951 9.54717 2.98281 9.4327 2.89774 9.34802L2.17762 8.6266H4.80288C4.81081 8.6266 4.82382 8.62465 4.83136 8.62322C5.06212 8.6037 5.25061 8.41288 5.25061 8.17718V7.83455C5.25061 7.58623 5.0378 7.37654 4.78922 7.37654Z" fill="#251C15" />
                <path d="M15.8596 7.70083L13.9428 5.78424C13.7671 5.60838 13.481 5.60838 13.3053 5.78424L13.0635 6.02632C12.9785 6.111 12.9317 6.2243 12.9317 6.34488C12.9317 6.4656 12.9784 6.57486 13.0635 6.65967L13.7837 7.37615H11.4967C11.4955 7.37615 11.4946 7.37615 11.4935 7.37615H11.1981C10.9495 7.37615 10.75 7.58571 10.75 7.83416V8.17679C10.75 8.41249 10.9309 8.60305 11.1618 8.6227C11.1695 8.62426 11.1751 8.62621 11.1829 8.62621H13.8231L13.1026 9.3475C13.0178 9.43218 12.971 9.54639 12.971 9.66671C12.971 9.78755 13.0177 9.90111 13.1026 9.98592L13.3448 10.228C13.4326 10.3159 13.548 10.36 13.6635 10.36C13.779 10.36 13.8943 10.3161 13.9822 10.2281L15.8598 8.3507C15.9464 8.26407 15.9928 8.14765 15.9907 8.02811C15.9927 7.90388 15.9463 7.78746 15.8596 7.70083Z" fill="#251C15" />
                <path d="M8.00058 6.75C7.31103 6.75 6.75 7.31103 6.75 8.00071C6.75 8.69026 7.31103 9.25142 8.00058 9.25142C8.69013 9.25142 9.25129 8.69026 9.25129 8.00071C9.25129 7.31103 8.69013 6.75 8.00058 6.75Z" fill="#251C15" />
            </g>
            <defs>
                <clipPath id="clip0_291_2609">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default Positioning