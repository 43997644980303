interface CapitalizeTIconProps {
    color?: string;
}
const CapitalizeTIcon: React.FC<CapitalizeTIconProps> = ({ color }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.9085 1.72656H0.635687C0.388156 1.72656 0.1875 1.92722 0.1875 2.17475V4.86397C0.1875 5.1115 0.388156 5.31216 0.635687 5.31216H1.53209C1.77963 5.31216 1.98028 5.1115 1.98028 4.86397V3.96756H4.65156V12.4834H3.53106C3.28353 12.4834 3.08288 12.684 3.08288 12.9316V13.828C3.08288 14.0755 3.28353 14.2762 3.53106 14.2762H8.01306C8.26059 14.2762 8.46125 14.0755 8.46125 13.828V12.9316C8.46125 12.684 8.26059 12.4834 8.01306 12.4834H6.89256V3.96756H9.56384V4.86397C9.56384 5.1115 9.7645 5.31216 10.012 5.31216H10.9084C11.156 5.31216 11.3566 5.1115 11.3566 4.86397V2.17475C11.3567 1.92722 11.156 1.72656 10.9085 1.72656Z" fill={color ?? "#251C15"} />
            <path d="M15.5219 6.15234H8.87216C8.71194 6.15234 8.58203 6.28225 8.58203 6.44247V8.18325C8.58203 8.34347 8.71194 8.47337 8.87216 8.47337H9.45241C9.61262 8.47337 9.74253 8.34347 9.74253 8.18325V7.603H11.4717V13.1154H10.7464C10.5861 13.1154 10.4562 13.2453 10.4562 13.4056V13.9858C10.4562 14.146 10.5862 14.2759 10.7464 14.2759H13.6477C13.8079 14.2759 13.9378 14.146 13.9378 13.9858V13.4056C13.9378 13.2453 13.8079 13.1154 13.6477 13.1154H12.9223V7.603H14.6515V8.18325C14.6515 8.34347 14.7814 8.47337 14.9416 8.47337H15.5219C15.6821 8.47337 15.812 8.34347 15.812 8.18325V6.44247C15.812 6.28225 15.6821 6.15234 15.5219 6.15234Z" fill={color ?? "#251C15"} />
        </svg>

    )
}

export default CapitalizeTIcon