import "./Notification.scss";
import CustomDrawer from "../Mui/CustomDrawer/CustomDrawer";
import goBack from "../../assets/images/go-back.svg";
import calender from "../../assets/images/calender2.svg";
import warning from "../../assets/images/warning.svg";
import clock from "../../assets/images/clock.svg";
import headphones from "../../assets/images/headphones.svg";

type NotificationProps = {
  readonly open: boolean;
  readonly close: () => void;
};

function Notification({ open, close }: NotificationProps) {
  return (
    <CustomDrawer backDropDisable open={open} close={close}>
      <div className="exp-modal-inner notifications-inner">
        <div className="top-middle">
          <div className="top">
            <div className="gb-head">
              <img aria-hidden src={goBack} className="go-back-arrow" alt="go back" onClick={close} />
              <span className="fz-32 primary-font">Notifications</span>
            </div>
            <button className="fz-16 fw-600 ttu primary-font c-orange mark-read">Mark all As read</button>
          </div>
          <div className="middle mb-0">
            <div className="notification-item unread">
              <div className="icon">
                <img src={calender} alt="" />
              </div>
              <div className="txt">
                <h4 className="title fz-16 primary-font fw-400">New Booking Received - Flight to Paris</h4>
                <span className="db fz-12 des">Hello Yashodara, A new booking has been received for a flight to Paris. Customer details: Chamika Samarathunga</span>
                <span className="db time c-orange fz-12">Now</span>
              </div>
            </div>
            <div className="notification-item unread">
              <div className="icon">
                <img src={warning} alt="" />
              </div>
              <div className="txt">
                <h4 className="title fz-16 primary-font fw-400">Low Inventory Warning - Hotel Rooms in Rome</h4>
                <span className="db fz-12 des">Hello Yashodara, A new booking has been received for a flight to Paris. Customer details: Chamika Samarathunga</span>
                <span className="db time c-orange fz-12">Now</span>
              </div>
            </div>
            <div className="notification-item">
              <div className="icon">
                <img src={clock} alt="" />
              </div>
              <div className="txt">
                <h4 className="title fz-16 primary-font fw-400">Payment Reminder - Pending Invoice for Booking #1243</h4>
                <span className="db fz-12 des">Hello Yashodara, A new booking has been received for a flight to Paris. Customer details: Chamika Samarathunga</span>
                <span className="db time c-orange fz-12">Now</span>
              </div>
            </div>
            <div className="notification-item">
              <div className="icon">
                <img src={headphones} alt="" />
              </div>
              <div className="txt">
                <h4 className="title fz-16 primary-font fw-400">New Customer Support Ticket - Refund Request</h4>
                <span className="db fz-12 des">Hello Yashodara, A new booking has been received for a flight to Paris. Customer details: Chamika Samarathunga</span>
                <span className="db time c-orange fz-12">Now</span>
              </div>
            </div>
            <div className="notification-item">
              <div className="icon">
                <img src={calender} alt="" />
              </div>
              <div className="txt">
                <h4 className="title fz-16 primary-font fw-400">New Booking Received - Flight to Paris</h4>
                <span className="db fz-12 des">Hello Yashodara, A new booking has been received for a flight to Paris. Customer details: Chamika Samarathunga</span>
                <span className="db time c-orange fz-12">Now</span>
              </div>
            </div>
            <div className="notification-item">
              <div className="icon">
                <img src={calender} alt="" />
              </div>
              <div className="txt">
                <h4 className="title fz-16 primary-font fw-400">New Booking Received - Flight to Paris</h4>
                <span className="db fz-12 des">Hello Yashodara, A new booking has been received for a flight to Paris. Customer details: Chamika Samarathunga</span>
                <span className="db time c-orange fz-12">Now</span>
              </div>
            </div>
            <div className="notification-item">
              <div className="icon">
                <img src={calender} alt="" />
              </div>
              <div className="txt">
                <h4 className="title fz-16 primary-font fw-400">New Booking Received - Flight to Paris</h4>
                <span className="db fz-12 des">Hello Yashodara, A new booking has been received for a flight to Paris. Customer details: Chamika Samarathunga</span>
                <span className="db time c-orange fz-12">Now</span>
              </div>
            </div>
            <div className="notification-item">
              <div className="icon">
                <img src={calender} alt="" />
              </div>
              <div className="txt">
                <h4 className="title fz-16 primary-font fw-400">New Booking Received - Flight to Paris</h4>
                <span className="db fz-12 des">Hello Yashodara, A new booking has been received for a flight to Paris. Customer details: Chamika Samarathunga</span>
                <span className="db time c-orange fz-12">Now</span>
              </div>
            </div>
            <div className="notification-item">
              <div className="icon">
                <img src={calender} alt="" />
              </div>
              <div className="txt">
                <h4 className="title fz-16 primary-font fw-400">New Booking Received - Flight to Paris</h4>
                <span className="db fz-12 des">Hello Yashodara, A new booking has been received for a flight to Paris. Customer details: Chamika Samarathunga</span>
                <span className="db time c-orange fz-12">Now</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CustomDrawer>
  );
}

export default Notification;
