import { CAN_REDO_COMMAND, CAN_UNDO_COMMAND, CLEAR_EDITOR_COMMAND, CommandListenerPriority, ElementFormatType, FORMAT_ELEMENT_COMMAND, FORMAT_TEXT_COMMAND, INDENT_CONTENT_COMMAND, LexicalEditor, OUTDENT_CONTENT_COMMAND, REDO_COMMAND, SELECTION_CHANGE_COMMAND, UNDO_COMMAND, createCommand } from 'lexical';
import { mergeRegister } from '@lexical/utils';
import { CHANGE_BACKGROUND_TEXT_COLOR_COMMAND, CHANGE_FONT_FAMILY_COMMAND, CHANGE_HEADER_COMMAND, CHANGE_LETTER_SPACING_COMMAND, CHANGE_LINE_HIGHT_COMMAND, CHANGE_PARAGRAPH_SPACING_COMMAND, CHANGE_QUOTE_COMMAND, CHANGE_SUB_SCRIPT_COMMAND, CHANGE_SUPPER_SCRIPT_COMMAND, CHANGE_TEXT_CAPITALIZE_COMMAND, CHANGE_TEXT_COLOR_COMMAND, CHANGE_TEXT_LOWERCASE_COMMAND, CHANGE_TEXT_SIZE_COMMAND, CHANGE_TEXT_UPPERCASE_Command, CHANGE_WORD_SPACING_COMMAND, CLEAR_FORMATTING_COMMAND, INSERT_CHECKED_LIST_COMMAND, INSERT_LINK_COMMAND, INSERT_ORDERED_LIST_COMMAND, INSERT_UNORDERED_LIST_COMMAND, applyBackgroundTextColor, applyFontFamily, applyLetterSpacing, applyLineHight, applyParagraphSpacing, applySubscript, applySuperscript, applyTextCapitalize, applyTextColor, applyTextLowerCase, applyTextSize, applyTextUpperCase, applyWordSpacing, clearFormatting, insertLink, isSelectionOrderedList, toggleHeading, toggleQuote } from './Commands';
import { insertList, removeList } from '@lexical/list'
export const lowPriority: CommandListenerPriority = 1;
export const normalPriority: CommandListenerPriority = 2;


class ToolbarPlugins {
    editor: LexicalEditor;

    constructor(props: { editor: LexicalEditor }) {
        this.editor = props.editor;

        mergeRegister(
            props.editor.registerCommand(
                CHANGE_TEXT_COLOR_COMMAND,
                (color: string) => {
                    applyTextColor(color);
                    return true;
                },
                normalPriority
            ),
            props.editor.registerCommand(
                CHANGE_BACKGROUND_TEXT_COLOR_COMMAND,
                (color: string) => {
                    applyBackgroundTextColor(color);
                    return true;
                },
                normalPriority
            ),
            props.editor.registerCommand(
                CHANGE_TEXT_SIZE_COMMAND,
                (size: string) => {
                    applyTextSize(size);
                    return true;
                },
                normalPriority
            ),
            props.editor.registerCommand(
                CHANGE_FONT_FAMILY_COMMAND,
                (size: string) => {
                    applyFontFamily(size);
                    return true;
                },
                normalPriority
            ),
            props.editor.registerCommand(
                CHANGE_LINE_HIGHT_COMMAND,
                (size: string) => {
                    applyLineHight(size);
                    return true;
                },
                normalPriority
            ),
            props.editor.registerCommand(
                CHANGE_LETTER_SPACING_COMMAND,
                (size: string) => {
                    applyLetterSpacing(size);
                    return true;
                },
                normalPriority
            ),
            props.editor.registerCommand(
                CHANGE_WORD_SPACING_COMMAND,
                (size: string) => {
                    applyWordSpacing(size);
                    return true;
                },
                normalPriority
            ),
            props.editor.registerCommand(
                CHANGE_SUPPER_SCRIPT_COMMAND,
                () => {
                    applySuperscript();
                    return true;
                },
                normalPriority
            ),
            props.editor.registerCommand(
                CHANGE_SUB_SCRIPT_COMMAND,
                () => {
                    applySubscript();
                    return true;
                },
                normalPriority
            ),
            props.editor.registerCommand(
                INSERT_ORDERED_LIST_COMMAND,
                () => {
                    if (isSelectionOrderedList()) {
                        removeList(this.editor);
                        return true;
                    }
                    else {
                        insertList(this.editor, 'number');
                        return true;
                    }

                },
                normalPriority
            ),
            props.editor.registerCommand(
                INSERT_UNORDERED_LIST_COMMAND,
                () => {
                    if (isSelectionOrderedList()) {
                        removeList(this.editor);
                        return true;
                    }
                    else {
                        insertList(this.editor, 'bullet');
                        return true;
                    }
                },
                lowPriority
            ),
            props.editor.registerCommand(
                INSERT_CHECKED_LIST_COMMAND,
                () => {
                    if (isSelectionOrderedList()) {
                        removeList(this.editor);
                        return true;
                    }
                    else {
                        insertList(this.editor, 'check');
                        return true;
                    }

                },
                normalPriority
            ),
            props.editor.registerCommand(
                CHANGE_PARAGRAPH_SPACING_COMMAND,
                (size: string) => {
                    applyParagraphSpacing(this.editor, size);
                    return true;
                },
                normalPriority
            ),
            props.editor.registerCommand(
                CHANGE_TEXT_UPPERCASE_Command,
                () => {
                    applyTextUpperCase();
                    return true;
                },
                normalPriority
            ),
            props.editor.registerCommand(
                CHANGE_TEXT_LOWERCASE_COMMAND,
                () => {
                    applyTextLowerCase();
                    return true;
                },
                normalPriority
            ),
            props.editor.registerCommand(
                CHANGE_TEXT_CAPITALIZE_COMMAND,
                () => {
                    applyTextCapitalize();
                    return true;
                },
                normalPriority
            ),
            props.editor.registerCommand(
                CHANGE_HEADER_COMMAND,
                (input: "h1" | "h2" | "h3") => {
                    toggleHeading(input);
                    return true;
                },
                normalPriority
            ),
            props.editor.registerCommand(
                CHANGE_QUOTE_COMMAND,
                () => {
                    toggleQuote();
                    return true;
                },
                normalPriority
            ),
            props.editor.registerCommand(
                INSERT_LINK_COMMAND,
                () => {
                    insertLink("https://playground.lexical.dev/");
                    return true;
                },
                normalPriority
            ),
            props.editor.registerCommand(
                CLEAR_FORMATTING_COMMAND,
                () => {
                    clearFormatting();
                    return true;
                },
                normalPriority
            )


        );
    }

    letterSpacing = () => Array.from({ length: 13 }, (_, i) => {
        return { value: `${i}px`, label: `${i}` };
    });

    lineHights = () => [
        { label: '24', value: '24px' },
        { label: '26', value: '26px' },
        { label: '28', value: '28px' },
        { label: '30', value: '30px' },
        { label: '32', value: '32px' },
        { label: '34', value: '34px' },
        { label: '36', value: '36px' },
    ]

    headingFormats = () => [{ label: 'Heading 1', value: 'h1' }, { label: 'Heading 2', value: 'h2' }, { label: 'Heading 3', value: 'h3' }]

    // Text formatting methods
    boldText = () => this.editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');

    italicText = () => this.editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');

    underlineText = () => this.editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');

    strikethroughText = () => this.editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough');

    codeText = () => this.editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'code');

    quoteText = () => this.editor.dispatchCommand(CHANGE_QUOTE_COMMAND, '');

    // Text alignment methods
    leftAlignText = () => this.editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left');

    rightAlignText = () => this.editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'right');

    centerAlignText = () => this.editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center');

    justifyAlignText = () => this.editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'justify');

    startAlignText = () => this.editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'start');

    endAlignText = () => this.editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'end');

    // text styling methods
    colorText = (color: string) => this.editor.dispatchCommand(CHANGE_TEXT_COLOR_COMMAND, color);

    changeTextSize = (size: string) => this.editor.dispatchCommand(CHANGE_TEXT_SIZE_COMMAND, size);

    changeFontFamily = (font: string) => this.editor.dispatchCommand(CHANGE_FONT_FAMILY_COMMAND, font)

    changeLineHight = (size: string) => this.editor.dispatchCommand(CHANGE_LINE_HIGHT_COMMAND, size)

    changeLetterSpacing = (size: string) => this.editor.dispatchCommand(CHANGE_LETTER_SPACING_COMMAND, size)

    changeParagraphSpacing = (size: string) => this.editor.dispatchCommand(CHANGE_PARAGRAPH_SPACING_COMMAND, size)

    capitalize = () => this.editor.dispatchCommand(CHANGE_TEXT_CAPITALIZE_COMMAND, '')

    upperCase = () => this.editor.dispatchCommand(CHANGE_TEXT_UPPERCASE_Command, '')

    lowerCase = () => this.editor.dispatchCommand(CHANGE_TEXT_LOWERCASE_COMMAND, '')

    superScript = () => this.editor.dispatchCommand(CHANGE_SUPPER_SCRIPT_COMMAND, '')

    backgroundColorText = (color: string) => this.editor.dispatchCommand(CHANGE_BACKGROUND_TEXT_COLOR_COMMAND, color);

    changeWordSpacing = (size: string) => this.editor.dispatchCommand(CHANGE_WORD_SPACING_COMMAND, size)

    subScript = () => this.editor.dispatchCommand(CHANGE_SUB_SCRIPT_COMMAND, '')

    bulletedList = () => this.editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, '')

    numberedList = () => this.editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, '')

    checkedList = () => this.editor.dispatchCommand(INSERT_CHECKED_LIST_COMMAND, '')

    undo = () => this.editor.dispatchCommand(UNDO_COMMAND, undefined)

    redo = () => this.editor.dispatchCommand(REDO_COMMAND, undefined)

    formatHeading = (header: string) => this.editor.dispatchCommand(CHANGE_HEADER_COMMAND, header)

    indentText = () => this.editor.dispatchCommand(INDENT_CONTENT_COMMAND, undefined)

    outdentText = () => this.editor.dispatchCommand(OUTDENT_CONTENT_COMMAND, undefined)

    insertLink = () => this.editor.dispatchCommand(INSERT_LINK_COMMAND, '')

    clearFormat = () => this.editor.dispatchCommand(CLEAR_FORMATTING_COMMAND, '')

}

export const toolbarPaddings = () => {
    let lineHeights = [];
    for (let i = 0; i <= 60; i += 1) {
        lineHeights.push({ label: `${i}`, value: `${i}` });
    }
    return lineHeights;
};
export const fontSizes = () => Array.from({ length: 61 }, (_, i) => {
    const size = 12 + i;
    return { value: `${size}px`, label: `${size}` };
});

export const fontFamilies = () => [{ label: 'Times New Roman', value: 'Times New Roman' }, { label: 'Georgia', value: 'Georgia' }, { label: 'Garamond', value: 'Garamond' },
{ label: 'Palatino Linotype', value: 'Palatino Linotype' }, { label: 'Arial', value: 'Arial' }, { label: 'Helvetica', value: 'Helvetica' },
{ label: 'Verdana', value: 'Verdana' }, { label: 'Tahoma', value: 'Tahoma' }, { label: 'Trebuchet MS', value: 'Trebuchet MS' },
{ label: 'Courier New', value: 'Courier New' }, { label: 'Lucida Console', value: 'Lucida Console' }, { label: 'Consolas', value: 'Consolas' },
{ label: 'Comic Sans MS', value: 'Comic Sans MS' }, { label: 'Brush Script MT', value: 'Brush Script MT' }, { label: 'Impact', value: 'Impact' },
{ label: 'Papyrus', value: 'Papyrus' }, { label: 'Segoe UI', value: 'Segoe UI' }, { label: 'Roboto', value: 'Roboto' }];

export default ToolbarPlugins;

