import { ReactElement, useEffect, useState } from 'react';
import './DropDown.scss';
import AccordionDownIcon from '../../../assets/images/AccordionDownIcon';
import { SearchOutlined } from '@mui/icons-material';

const Dropdown = ({ icon, options, defaultValue, onSelect, searchable, sx, hideSuffix }: { hideSuffix?: boolean, sx?: React.CSSProperties, searchable?: boolean, icon?: ReactElement, options: { value: string, label: string }[], defaultValue?: string, onSelect: (val: { value: string, label: string }) => void }) => {
    const [selectedValue, setSelectedValue] = useState(defaultValue);
    const [searchValue, setSearchValue] = useState<string | undefined>();
    const [isOpen, setIsOpen] = useState(false);

    useEffect(()=> {
        setSelectedValue(defaultValue);
    },[defaultValue])

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleSelect = (data: { value: string, label: string }) => {
        setSelectedValue(data.label);
        setSearchValue(data.label)
        onSelect(data);
        setIsOpen(false);
    };

    const getOption = () => {
        if (!searchValue) return options;

        if (searchable && searchValue !== '') {
            return options.filter(t => (t.label.includes(searchValue)));
        }
        return options;
    }

    const onChange = (val: any) => {
        setSearchValue(val.target.value);
    }

    const onMouseLeave = (val: any) => {
        setIsOpen(false)
    }

    return (
        <div style={sx} onMouseLeave={onMouseLeave} className="dropdown-container">
            <div className="dropdown-header" >
                {icon ? (<span className="dropdown-icon">{icon}</span>): (<></>)}
                {searchable
                    ? (<input
                        onClick={toggleDropdown}
                        value={searchValue}
                        type="text"
                        className="text-input"
                        placeholder='Search'
                        onChange={onChange}
                    />)
                    : (<span onClick={toggleDropdown} className="dropdown-value">{selectedValue}</span>)}
                {!hideSuffix ? <span onClick={toggleDropdown} className={`dropdown-arrow ${isOpen ?? 'rotate'}}`}>
                    {searchable ? (<SearchOutlined />) : (<AccordionDownIcon />)}
                </span> : <></>}
            </div>
            {isOpen && (
                <div className="dropdown-menu-content">
                    {getOption().length === 0
                        ? <div className="dropdown-item">Not found</div>
                        : getOption().map((option, index) => (
                            <div key={index} className="dropdown-item" onClick={() => handleSelect(option)}>
                                {option.label}
                            </div>
                        ))
                    }
                </div>
            )}
        </div>
    );
};

export default Dropdown;
