const CalenderSvg = () => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_5660_7966)">
                <path d="M3.4285 0.571429C3.4285 0.255837 3.17266 0 2.85707 0C2.54148 0 2.28564 0.255837 2.28564 0.571429V1.14286C2.28564 1.45845 2.54148 1.71429 2.85707 1.71429C3.17266 1.71429 3.4285 1.45845 3.4285 1.14286V0.571429Z" fill="#134B5F" />
                <path d="M9.71415 0.571429C9.71415 0.255837 9.45831 0 9.14272 0C8.82713 0 8.57129 0.255837 8.57129 0.571429V1.14286C8.57129 1.45845 8.82713 1.71429 9.14272 1.71429C9.45831 1.71429 9.71415 1.45845 9.71415 1.14286V0.571429Z" fill="#134B5F" />
                <path
                    d="M0.285645 4.28613V10.8576C0.285645 11.4887 0.797359 12.0004 1.4285 12.0004H10.5714C11.2025 12.0004 11.7142 11.4887 11.7142 10.8576V4.28613H0.285645ZM3.71422 10.0004C3.71422 10.3161 3.4585 10.5718 3.14279 10.5718H2.57136C2.25564 10.5718 1.99993 10.3161 1.99993 10.0004V9.42899C1.99993 9.11328 2.25564 8.85756 2.57136 8.85756H3.14279C3.4585 8.85756 3.71422 9.11328 3.71422 9.42899V10.0004ZM3.71422 6.85756C3.71422 7.17328 3.4585 7.42899 3.14279 7.42899H2.57136C2.25564 7.42899 1.99993 7.17328 1.99993 6.85756V6.28613C1.99993 5.97042 2.25564 5.7147 2.57136 5.7147H3.14279C3.4585 5.7147 3.71422 5.97042 3.71422 6.28613V6.85756ZM6.85707 10.0004C6.85707 10.3161 6.60136 10.5718 6.28565 10.5718H5.71422C5.3985 10.5718 5.14279 10.3161 5.14279 10.0004V9.42899C5.14279 9.11328 5.3985 8.85756 5.71422 8.85756H6.28565C6.60136 8.85756 6.85707 9.11328 6.85707 9.42899V10.0004ZM6.85707 6.85756C6.85707 7.17328 6.60136 7.42899 6.28565 7.42899H5.71422C5.3985 7.42899 5.14279 7.17328 5.14279 6.85756V6.28613C5.14279 5.97042 5.3985 5.7147 5.71422 5.7147H6.28565C6.60136 5.7147 6.85707 5.97042 6.85707 6.28613V6.85756ZM9.99993 10.0004C9.99993 10.3161 9.74422 10.5718 9.4285 10.5718H8.85707C8.54136 10.5718 8.28564 10.3161 8.28564 10.0004V9.42899C8.28564 9.11328 8.54136 8.85756 8.85707 8.85756H9.4285C9.74422 8.85756 9.99993 9.11328 9.99993 9.42899V10.0004ZM9.99993 6.85756C9.99993 7.17328 9.74422 7.42899 9.4285 7.42899H8.85707C8.54136 7.42899 8.28564 7.17328 8.28564 6.85756V6.28613C8.28564 5.97042 8.54136 5.7147 8.85707 5.7147H9.4285C9.74422 5.7147 9.99993 5.97042 9.99993 6.28613V6.85756Z"
                    fill="#134B5F"
                />
                <path d="M11.7142 3.71456V2.00028C11.7142 1.36914 11.2025 0.857422 10.5714 0.857422H10.2856V1.14314C10.2856 1.77342 9.77307 2.28599 9.14279 2.28599C8.5125 2.28599 7.99993 1.77342 7.99993 1.14314V0.857422H3.99993V1.14314C3.99993 1.77342 3.48736 2.28599 2.85707 2.28599C2.22679 2.28599 1.71422 1.77342 1.71422 1.14314V0.857422H1.4285C0.797359 0.857422 0.285645 1.36914 0.285645 2.00028V3.71456H11.7142Z" fill="#134B5F" />
            </g>
            <defs>
                <clipPath id="clip0_5660_7966">
                    <rect width="12" height="12" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default CalenderSvg