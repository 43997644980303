const SummeryIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_7220_11348)">
                <path d="M19.6568 7.29797L18.6786 6.3198C18.2217 5.86285 17.4782 5.86293 17.0213 6.3198L14.9609 8.38016V1.75781C14.9609 0.788555 14.1724 0 13.2031 0H1.75781C0.788555 0 0 0.788555 0 1.75781V18.2422C0 19.2114 0.788555 20 1.75781 20H13.2031C14.1724 20 14.9609 19.2114 14.9609 18.2422V13.5925L19.6568 8.95527C20.1137 8.49836 20.1137 7.75488 19.6568 7.29797ZM12.649 14.2286L10.8864 15.0511L11.7082 13.2903L16.0822 8.91625L17.0493 9.88328L12.649 14.2286ZM13.7891 18.2422C13.7891 18.5653 13.5262 18.8281 13.2031 18.8281H1.75781C1.43473 18.8281 1.17188 18.5653 1.17188 18.2422V1.75781C1.17188 1.43473 1.43473 1.17188 1.75781 1.17188H13.2031C13.5262 1.17188 13.7891 1.43473 13.7891 1.75781V9.55203C10.5399 12.8012 10.7521 12.5678 10.6898 12.7011L9.14305 16.0157C9.03887 16.239 9.08547 16.5036 9.25969 16.6778C9.43391 16.8521 9.69859 16.8986 9.9218 16.7945L13.2364 15.2476C13.3579 15.1909 13.3779 15.1557 13.7891 14.7496V18.2422ZM17.883 9.05988L16.9108 8.08762L17.85 7.14844L18.8278 8.12691L17.883 9.05988Z" fill="#134B5F" />
                <path d="M3.125 4.96094H11.875C12.1986 4.96094 12.4609 4.69859 12.4609 4.375C12.4609 4.05141 12.1986 3.78906 11.875 3.78906H3.125C2.80141 3.78906 2.53906 4.05141 2.53906 4.375C2.53906 4.69859 2.80141 4.96094 3.125 4.96094Z" fill="#134B5F" />
                <path d="M3.125 8.08594H10C10.3236 8.08594 10.5859 7.82359 10.5859 7.5C10.5859 7.17641 10.3236 6.91406 10 6.91406H3.125C2.80141 6.91406 2.53906 7.17641 2.53906 7.5C2.53906 7.82359 2.80141 8.08594 3.125 8.08594Z" fill="#134B5F" />
                <path d="M8.125 10.0391H3.125C2.80141 10.0391 2.53906 10.3014 2.53906 10.625C2.53906 10.9486 2.80141 11.2109 3.125 11.2109H8.125C8.44859 11.2109 8.71094 10.9486 8.71094 10.625C8.71094 10.3014 8.44859 10.0391 8.125 10.0391Z" fill="#134B5F" />
            </g>
            <defs>
                <clipPath id="clip0_7220_11348">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default SummeryIcon