const DotLine = (props: any) => {
  return (<svg onClick={props.onClick} style={{ cursor: 'pointer' }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_5186_13225)">
      <path d="M2.91672 1.67969H0.416718C0.186615 1.67969 0 1.8663 0 2.09641V4.59641C0 4.82635 0.186615 5.01297 0.416718 5.01297H2.91672C3.14667 5.01297 3.33328 4.82635 3.33328 4.59641V2.09641C3.33328 1.8663 3.14667 1.67969 2.91672 1.67969Z" fill="#251C15" />
      <path d="M2.91672 8.34766H0.416718C0.186615 8.34766 0 8.53427 0 8.76422V11.2642C0 11.4943 0.186615 11.6809 0.416718 11.6809H2.91672C3.14667 11.6809 3.33328 11.4943 3.33328 11.2642V8.76422C3.33328 8.53427 3.14667 8.34766 2.91672 8.34766Z" fill="#251C15" />
      <path d="M2.91672 15.0117H0.416718C0.186615 15.0117 0 15.1985 0 15.4284V17.9284C0 18.1584 0.186615 18.3452 0.416718 18.3452H2.91672C3.14667 18.3452 3.33328 18.1584 3.33328 17.9284V15.4284C3.33328 15.1985 3.14667 15.0117 2.91672 15.0117Z" fill="#251C15" />
      <path d="M6.66742 4.17844H19.1674C19.6282 4.17844 20.0007 3.80597 20.0007 3.34515C20.0007 2.88434 19.6282 2.51172 19.1674 2.51172H6.66742C6.2066 2.51172 5.83398 2.88434 5.83398 3.34515C5.83398 3.80597 6.2066 4.17844 6.66742 4.17844Z" fill="#251C15" />
      <path d="M19.1674 9.17969H6.66742C6.2066 9.17969 5.83398 9.55215 5.83398 10.013C5.83398 10.4738 6.2066 10.8464 6.66742 10.8464H19.1674C19.6282 10.8464 20.0007 10.4738 20.0007 10.013C20.0007 9.55215 19.6282 9.17969 19.1674 9.17969Z" fill="#251C15" />
      <path d="M19.1674 15.8477H6.66742C6.2066 15.8477 5.83398 16.2201 5.83398 16.6809C5.83398 17.1418 6.2066 17.5142 6.66742 17.5142H19.1674C19.6282 17.5142 20.0007 17.1418 20.0007 16.6809C20.0007 16.2201 19.6282 15.8477 19.1674 15.8477Z" fill="#251C15" />
    </g>
    <defs>
      <clipPath id="clip0_5186_13225">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>)
}

export default DotLine