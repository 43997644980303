import { ReactElement } from "react";
import "./Dashboard.scss";
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";

type LayoutProps = {
  children: ReactElement;
  headerTitle: string;
};

const Dashboard = ({ children, headerTitle }: LayoutProps) => {
  

  return (
    <section className="dashboard">
      <Sidebar />
      <section className="content">
        <Header title={headerTitle} />
        <div className="inner-content">{children}</div>
      </section>
    </section>
  );
};

export default Dashboard;
