import { getAspectRatio } from "../../../../helpers/helperfunctions";
import { IBuilderView } from "../../../../helpers/Interfaces";

export const pdfHtmlContent = (layout: IBuilderView, resolution: string) => (`

    <body>
    <style>
            .PlaygroundEditorTheme__ltr {
                text-align: left;
            }
            .PlaygroundEditorTheme__rtl {
                text-align: right;
            }
            .PlaygroundEditorTheme__paragraph {
                margin: 0;
                position: relative;
            }
            .PlaygroundEditorTheme__quote {
                margin: 0;
                margin-left: 20px;
                margin-bottom: 10px;
                font-size: 15px;
                color: rgb(101, 103, 107);
                border-left-color: rgb(206, 208, 212);
                border-left-width: 4px;
                border-left-style: solid;
                padding-left: 16px;
            }
            .PlaygroundEditorTheme__h1 {
                font-size: 24px;
                color: rgb(5, 5, 5);
                font-weight: 400;
                margin: 0;
                position: relative;
            }
            .PlaygroundEditorTheme__h2 {
                font-size: 15px;
                color: rgb(101, 103, 107);
                font-weight: 700;
                margin: 0;
                position: relative;
            }
            .PlaygroundEditorTheme__h3 {
                font-size: 12px;
                margin: 0;
                position: relative;
            }
            .PlaygroundEditorTheme__indent {
                --lexical-indent-base-value: 40px;
            }
            .PlaygroundEditorTheme__textBold {
                font-weight: bold;
            }
            .PlaygroundEditorTheme__textItalic {
                font-style: italic;
            }
            .PlaygroundEditorTheme__textUnderline {
                text-decoration: underline;
            }
            .PlaygroundEditorTheme__textStrikethrough {
                text-decoration: line-through;
            }
            .PlaygroundEditorTheme__textUnderlineStrikethrough {
                text-decoration: underline line-through;
            }
            .PlaygroundEditorTheme__textSubscript {
                font-size: 0.8em;
                vertical-align: sub !important;
            }
            .PlaygroundEditorTheme__textSuperscript {
                font-size: 0.8em;
                vertical-align: super;
            }
            .PlaygroundEditorTheme__textCode {
                background-color: rgb(240, 242, 245);
                padding: 1px 0.25rem;
                font-family: Menlo, Consolas, Monaco, monospace;
                font-size: 94%;
            }
            .PlaygroundEditorTheme__hashtag {
                background-color: rgba(88, 144, 255, 0.15);
                border-bottom: 1px solid rgba(88, 144, 255, 0.3);
            }
            .PlaygroundEditorTheme__link {
                color: rgb(33, 111, 219);
                text-decoration: none;
            }
            .PlaygroundEditorTheme__link:hover {
                text-decoration: underline;
                cursor: pointer;
            }
            .PlaygroundEditorTheme__code {
                background-color: rgb(240, 242, 245);
                font-family: Menlo, Consolas, Monaco, monospace;
                display: block;
                padding: 8px 8px 8px 52px;
                line-height: 1.53;
                font-size: 13px;
                margin: 0;
                margin-top: 8px;
                margin-bottom: 8px;
                overflow-x: auto;
                position: relative;
                tab-size: 2;
            }
            .PlaygroundEditorTheme__code:before {
                content: attr(data-gutter);
                position: absolute;
                background-color: #eee;
                left: 0;
                top: 0;
                border-right: 1px solid #ccc;
                padding: 8px;
                color: #777;
                white-space: pre-wrap;
                text-align: right;
                min-width: 25px;
            }
            .PlaygroundEditorTheme__table {
                border-collapse: collapse;
                border-spacing: 0;
                overflow-y: scroll;
                overflow-x: scroll;
                table-layout: fixed;
                width: max-content;
                margin: 0px 25px 30px 0px;
            }
            .PlaygroundEditorTheme__tableSelection *::selection {
                background-color: transparent;
            }
            .PlaygroundEditorTheme__tableSelected {
                outline: 2px solid rgb(60, 132, 244);
            }
            .PlaygroundEditorTheme__tableCell {
                border: 1px solid #bbb;
                width: 75px;
                min-width: 75px;
                vertical-align: top;
                text-align: start;
                padding: 6px 8px;
                position: relative;
                outline: none;
            }
            .PlaygroundEditorTheme__tableCellSortedIndicator {
                display: block;
                opacity: 0.5;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 4px;
                background-color: #999;
            }
            .PlaygroundEditorTheme__tableCellResizer {
                position: absolute;
                right: -4px;
                height: 100%;
                width: 8px;
                cursor: ew-resize;
                z-index: 10;
                top: 0;
            }
            .PlaygroundEditorTheme__tableCellHeader {
                background-color: #f2f3f5;
                text-align: start;
            }
            .PlaygroundEditorTheme__tableCellSelected {
                background-color: #c9dbf0;
            }
            .PlaygroundEditorTheme__tableCellPrimarySelected {
                border: 2px solid rgb(60, 132, 244);
                display: block;
                height: calc(100% - 2px);
                position: absolute;
                width: calc(100% - 2px);
                left: -1px;
                top: -1px;
                z-index: 2;
            }
            .PlaygroundEditorTheme__tableCellEditing {
                box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
                border-radius: 3px;
            }
            .PlaygroundEditorTheme__tableAddColumns {
                position: absolute;
                background-color: #eee;
                height: 100%;
                animation: table-controls 0.2s ease;
                border: 0;
                cursor: pointer;
            }
            .PlaygroundEditorTheme__tableAddColumns:after {
                /* background-image: url(../images/icons/plus.svg); */
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                display: block;
                content: ' ';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0.4;
            }
            .PlaygroundEditorTheme__tableAddColumns:hover,
            .PlaygroundEditorTheme__tableAddRows:hover {
                background-color: #c9dbf0;
            }
            .PlaygroundEditorTheme__tableAddRows {
                position: absolute;
                width: calc(100% - 25px);
                background-color: #eee;
                animation: table-controls 0.2s ease;
                border: 0;
                cursor: pointer;
            }
            .PlaygroundEditorTheme__tableAddRows:after {
                /* background-image: url(../images/icons/plus.svg); */
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                display: block;
                content: ' ';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0.4;
            }
            @keyframes table-controls {
                0% {
                opacity: 0;
                }
                100% {
                opacity: 1;
                }
            }
            .PlaygroundEditorTheme__tableCellResizeRuler {
                display: block;
                position: absolute;
                width: 1px;
                background-color: rgb(60, 132, 244);
                height: 100%;
                top: 0;
            }
            .PlaygroundEditorTheme__tableCellActionButtonContainer {
                display: block;
                right: 5px;
                top: 6px;
                position: absolute;
                z-index: 4;
                width: 20px;
                height: 20px;
            }
            .PlaygroundEditorTheme__tableCellActionButton {
                background-color: #eee;
                display: block;
                border: 0;
                border-radius: 20px;
                width: 20px;
                height: 20px;
                color: #222;
                cursor: pointer;
            }
            .PlaygroundEditorTheme__tableCellActionButton:hover {
                background-color: #ddd;
            }
            .PlaygroundEditorTheme__characterLimit {
                display: inline;
                background-color: #ffbbbb !important;
            }
            .PlaygroundEditorTheme__ol1 {
                padding: 0;
                margin: 0;
                list-style-position: outside;
            }
            .PlaygroundEditorTheme__ol2 {
                padding: 0;
                margin: 0;
                list-style-type: upper-alpha;
                list-style-position: outside;
            }
            .PlaygroundEditorTheme__ol3 {
                padding: 0;
                margin: 0;
                list-style-type: lower-alpha;
                list-style-position: outside;
            }
            .PlaygroundEditorTheme__ol4 {
                padding: 0;
                margin: 0;
                list-style-type: upper-roman;
                list-style-position: outside;
            }
            .PlaygroundEditorTheme__ol5 {
                padding: 0;
                margin: 0;
                list-style-type: lower-roman;
                list-style-position: outside;
            }
            .PlaygroundEditorTheme__ul {
                padding: 0;
                margin: 0;
                list-style-position: outside;
            }
            .PlaygroundEditorTheme__listItem {
                margin: 0 32px;
            }
            .PlaygroundEditorTheme__listItemChecked,
            .PlaygroundEditorTheme__listItemUnchecked {
                position: relative;
                margin-left: 8px;
                margin-right: 8px;
                padding-left: 24px;
                padding-right: 24px;
                list-style-type: none;
                outline: none;
            }
            .PlaygroundEditorTheme__listItemChecked {
                text-decoration: line-through;
            }
            .PlaygroundEditorTheme__listItemUnchecked:before,
            .PlaygroundEditorTheme__listItemChecked:before {
                content: '';
                width: 16px;
                height: 16px;
                top: 2px;
                left: 0;
                cursor: pointer;
                display: block;
                background-size: cover;
                position: absolute;
            }
            .PlaygroundEditorTheme__listItemUnchecked[dir='rtl']:before,
            .PlaygroundEditorTheme__listItemChecked[dir='rtl']:before {
                left: auto;
                right: 0;
            }
            .PlaygroundEditorTheme__listItemUnchecked:focus:before,
            .PlaygroundEditorTheme__listItemChecked:focus:before {
                box-shadow: 0 0 0 2px #a6cdfe;
                border-radius: 2px;
            }
            .PlaygroundEditorTheme__listItemUnchecked:before {
                border: 1px solid #999;
                border-radius: 2px;
            }
            .PlaygroundEditorTheme__listItemChecked:before {
                border: 1px solid rgb(61, 135, 245);
                border-radius: 2px;
                background-color: #3d87f5;
                background-repeat: no-repeat;
            }
            .PlaygroundEditorTheme__listItemChecked:after {
                content: '';
                cursor: pointer;
                border-color: #fff;
                border-style: solid;
                position: absolute;
                display: block;
                top: 6px;
                width: 3px;
                left: 7px;
                right: 7px;
                height: 6px;
                transform: rotate(45deg);
                border-width: 0 2px 2px 0;
            }
            .PlaygroundEditorTheme__nestedListItem {
                list-style-type: none;
            }
            .PlaygroundEditorTheme__nestedListItem:before,
            .PlaygroundEditorTheme__nestedListItem:after {
                display: none;
            }
            .PlaygroundEditorTheme__tokenComment {
                color: slategray;
            }
            .PlaygroundEditorTheme__tokenPunctuation {
                color: #999;
            }
            .PlaygroundEditorTheme__tokenProperty {
                color: #905;
            }
            .PlaygroundEditorTheme__tokenSelector {
                color: #690;
            }
            .PlaygroundEditorTheme__tokenOperator {
                color: #9a6e3a;
            }
            .PlaygroundEditorTheme__tokenAttr {
                color: #07a;
            }
            .PlaygroundEditorTheme__tokenVariable {
                color: #e90;
            }
            .PlaygroundEditorTheme__tokenFunction {
                color: #dd4a68;
            }
            .PlaygroundEditorTheme__mark {
                background: rgba(255, 212, 0, 0.14);
                border-bottom: 2px solid rgba(255, 212, 0, 0.3);
                padding-bottom: 2px;
            }
            .PlaygroundEditorTheme__markOverlap {
                background: rgba(255, 212, 0, 0.3);
                border-bottom: 2px solid rgba(255, 212, 0, 0.7);
            }
            .PlaygroundEditorTheme__mark.selected {
                background: rgba(255, 212, 0, 0.5);
                border-bottom: 2px solid rgba(255, 212, 0, 1);
            }
            .PlaygroundEditorTheme__markOverlap.selected {
                background: rgba(255, 212, 0, 0.7);
                border-bottom: 2px solid rgba(255, 212, 0, 0.7);
            }
            .PlaygroundEditorTheme__embedBlock {
                user-select: none;
            }
            .PlaygroundEditorTheme__embedBlockFocus {
                outline: 2px solid rgb(60, 132, 244);
            }
            .PlaygroundEditorTheme__layoutContainer {
                display: grid;
                gap: 10px;
                margin: 10px 0;
            }
            .PlaygroundEditorTheme__layoutItem {
                border: 1px dashed #ddd;
                padding: 8px 16px;
            }
            .PlaygroundEditorTheme__autocomplete {
                color: #ccc;
            }
            .PlaygroundEditorTheme__hr {
                padding: 2px 2px;
                border: none;
                margin: 1em 0;
                cursor: pointer;
            }
            .PlaygroundEditorTheme__hr:after {
                content: '';
                display: block;
                height: 2px;
                background-color: #ccc;
                line-height: 2px;
            }
            .PlaygroundEditorTheme__hr.selected {
                outline: 2px solid rgb(60, 132, 244);
                user-select: none;
            }
//   ---------------------------------------------------------------------------
            .builder-container-outer {
                display: flex;
                gap: 28px;
            }
            .builder-container {
                width: 100%;
            }
            .builder-outer {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .builder-title {
                display: flex;
                align-items: center;
                font-weight: 500;
                gap: 10px;
            }
            .editor-area {
                min-width: 50px;
            }
            .editor-area:focus-visible {
                outline: none;
                border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
                margin-right: 10px;
            }
            .builder-outer-controls {
                display: flex;
                gap: 24px;
            }
            .icon-builder-btn {
                aspect-ratio: 1/1;
                border-radius: 10px;
                background-color: var(--c-light-orange);
                display: grid;
                place-items: center;
                border-radius: clamp(5rem, get-vw(8), 8rem);
                transition: all 0.35s;
            }
            .icon-builder-btn svg path, 
            .icon-builder-btn svg rect {
                transition: all 0.35s;
            }
            .icon-builder-btn:hover {
                background-color: var(--c-orange);
            }
            .icon-builder-btn:hover svg path {
                fill: var(--c-white);
            }
            .icon-builder-btn:hover svg rect {
                fill: var(--c-orange);
            }
            .builder-inner {
                display: flex;
                background-color: #fbfbfb;
                border-radius: 8px;
                margin-top: 25px;
                height: calc(100vh - 13rem);
                padding: 20px 40px;
            }
            .layout-select-outer {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                gap: 30px;
            }
            .monitor-image {
                width: 100px;
                height: 100px;
            }
            .header-description {
                width: 510px;
            }
            .editor-container {
                flex: 1;
                display: flex;
                flex-direction: column;
            }
            .editor-controls {
                display: flex;
                justify-content: space-between;
            }
            .controls-outer {
                margin-bottom: 20px;
                padding: 0px 10px;
                border-radius: 10px;
                background-color: var(--c-white);
                height: clamp(32px, 4vw, 42px);
                display: flex;
                align-items: center;
                gap: 18px;
            }
            .editor-inner-container {
                flex: 1;
                display: flex;
                background-color: white;
                border-radius: 10px;
                max-height: calc(-22rem + 100vh);
            }
            .editor-image {
                position: relative;
                flex: 1;
                height: calc(-22rem + 100vh);
                position: relative;
            }
            .img {
                position: absolute;
                z-index: 1;
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: absolute;
            }
            .editor-inner img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: absolute;
                left: 0;
                top: 0;
                border-radius: 10px;
            }
            .editor-overlay-input {
                padding: 5px 10px;
                min-width: 100px;
                min-height: 100px;
                overflow-y: auto;
                max-height: calc(-27rem + 100vh);
                // border: 1px dotted #d9d9d9;
                background-color: transparent;
                border-radius: inherit;
            }
            .editor-overlay-input:focus-visible {
                outline: none;
            }
            .editor-input {
                padding: 5px 10px;
                height: 100%;
                // border: 1px dotted #d9d9d9;
                background-color: transparent;
                max-height: calc(100vh - 21.6rem);
                overflow-y: auto;
            }
            .editor-input:focus-visible {
                outline: none;
            }
            .latest-updated {
                display: flex;
                padding: 10px 0px;
                font-size: 16px;
                color: #b4b4b4;
            }
            .latest-updated img {
                margin-right: 8px;
            }
            .builder-content {
                flex: 1;
            }
            .builder-controls {
                min-width: 450px;
            }
            .upper-bts {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }
            .upper-bts-content {
                display: flex;
                gap: 18px;
                background-color: rgba(205, 205, 206, 0.2);
                border-radius: 14px;
                padding: clamp(0.625rem, 2.78125vw, 0.9375rem) clamp(0.9375rem, 0.0416666667vw, 1.25rem);
            }
            .editor-page-divider{
                margin-bottom: 20px;

            }
            
        </style>
      <div class="editor-page-divider">
                        <style>
                            .side-image{
                                // border-radius: ${layout?.view?.overlay ? '10px' : layout?.view?.split && !layout?.canvas?.split ? '10px 0px 0px 10px' : layout?.view?.split && layout?.canvas?.split ? '0px 10px 10px 0px' : ''};
                                opacity: ${layout?.image?.imageOpacity};
                            }
                            .editor-overlay-outer {
                                position: absolute;
                                padding-left: ${layout.editor?.leftPadding}px;
                                padding-right: ${layout.editor?.rightPadding}px;
                                padding-top: ${layout.editor?.topPadding}px;
                                padding-bottom: ${layout.editor?.bottomPadding}px;
                                background-color: ${layout?.editor?.overlayColor};
                                border0radius: ${layout?.editor?.radiusTLeft}px ${layout?.editor?.radiusTRight}px ${layout?.editor?.radiusBRight}px ${layout?.editor?.radiusBLeft}px;
                                background-color: ${layout?.editor?.backgroundColor};
                                top: ${layout?.editor?.positionTop}px;
                                left: ${layout?.editor?.positionLeft}px;
                            }
                            .pdf-editor-inner {
                                flex: 1;
                                overflow: hidden;
                                box-sizing: border-box;
                                position: relative;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                max-height: calc(-22rem + 100vh);
                                ${!layout.view?.overlay
            ? (`
                                        padding-left: ${layout.editor?.leftPadding}px;
                                        padding-right: ${layout.editor?.rightPadding}px;
                                        padding-top: ${layout.editor?.topPadding}px;
                                        padding-bottom: ${layout.editor?.bottomPadding}px;
                                        background: ${layout?.editor?.overlayColor};
                                        height: calc((100vh - 22.6rem + calc(45px - ${layout?.editor?.bottomPadding}px) + calc(45px - ${layout?.editor?.topPadding}px)));
                                    `)
            : (`
                                        height: calc((100vh - 22.6rem + calc(45px - ${layout?.editor?.bottomPadding}px) + calc(45px - ${layout?.editor?.topPadding}px)));
                                        `)
        }
                            }
                            .editor-image-content {
                                position: absolute;
                                z-index: 2;
                                background-color: ${layout.image?.backgroundColor};
                                border-radius: ${layout?.view?.overlay ? '10px' : layout?.view?.split && !layout?.canvas?.split ? '10px 0px 0px 10px' : layout?.view?.split && layout?.canvas?.split ? '0px 10px 10px 0px' : ''};
                                padding-left: ${layout.image?.leftSplitPadding}px;
                                padding-right: ${layout.image?.rightSplitPadding}px;
                                padding-top: ${layout.image?.topSplitPadding}px;
                                padding-bottom: ${layout.image?.bottomSplitPadding}px;
                            }
                            .pdf-header-title {
                                font-size: ${layout?.image?.title?.fontSize ? layout?.image?.title?.fontSize  : '32px'};
                                font-family: ${layout.image?.title?.fontFamily ? layout.image?.title?.fontFamily : 'var(--primary-font)'};
                                font-weight: ${layout.image?.title?.bold ? 'bold' : 'normal'};
                                text-decoration: ${layout.image?.title?.underLine ? 'underline' : ''} ${layout.image?.title?.strickThrough ? 'line-through' : ''};
                                font-style: ${layout.image?.title?.italic ? 'italic' : 'normal'};
                                color: ${layout.image?.title?.color ? layout.image?.title?.color : 'black'};
                                text-align: ${layout.image?.title?.align as any}
                            }
                            .pdf-sub-header-title {
                                font-size: ${layout?.image?.subTitle?.fontSize ? layout?.image?.subTitle?.fontSize  : '32px'};
                                font-family: ${layout.image?.subTitle?.fontFamily ? layout.image?.subTitle?.fontFamily : 'var(--primary-font)'};
                                font-weight: ${layout.image?.subTitle?.bold ? 'bold' : 'normal'};
                                text-decoration: ${layout.image?.subTitle?.underLine ? 'underline' : ''} ${layout.image?.subTitle?.strickThrough ? 'line-through' : ''};
                                font-style: ${layout.image?.subTitle?.italic ? 'italic' : 'normal'};
                                color: ${layout.image?.subTitle?.color ? layout.image?.subTitle?.color : 'black'};
                                text-align: ${layout.image?.subTitle?.align as any}
                            }
                        </style>

                            <div style="background-color: ${layout?.view?.overlay ? layout?.editor?.overlayColor : 'white'}; flex-direction:${layout?.canvas?.split ? 'row-reverse' : 'row'}; aspect-ratio: ${getAspectRatio(resolution)}" class="editor-inner-container" >
                                <div style="display: ${(layout?.view?.blank || layout?.view?.overlay) ? 'none' : 'initial'};" class="editor-image">
                                    ${layout?.image?.imageUrl
            ? `<img class="img side-image" src="${layout?.image?.imageUrl}" alt="" />`
            : ''}
                                    <div class="editor-image-content img">
                                        <div class="pdf-header-title">${layout?.image?.title?.text || ''}</div>
                                        <div class="pdf-sub-header-title">${layout?.image?.subTitle?.text || ''}</div>
                                    </div>
                                </div>
                                <div  class="pdf-editor-inner">
                                    ${(layout?.image?.imageUrl && !layout?.view?.split)
            ? `<img class="img" style="border-radius: ${layout?.view?.overlay ? '10px' : layout?.view?.split && !layout?.canvas?.split ? '10px 0px 0px 10px' : layout?.view?.split && layout?.canvas?.split ? '0px 10px 10px 0px' : ''}; opacity: ${layout?.image?.imageOpacity}; position: ${layout?.view?.overlay ? 'static' : 'revert-layer'};" src="${layout?.image?.imageUrl}" alt="" />`
            : ''}
                                    ${!layout?.view?.overlay
            ? `<div class="editor-input">
                                                ${layout?.html}
                                            </div>`
            : `<div class="editor-overlay-outer">
                                                <div  class="editor-overlay-input">
                                                    ${layout?.html}
                                                </div>
                                            </div>`}
                                </div> 
                            </div>
                    </div>
        </body>             
`);
