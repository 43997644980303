import { t } from "i18next";
import { ICreateLeadData, ICreateLeadUser, ILead, ILeadValidation } from "../../helpers/Interfaces";
import { validateEmail, validatePhoneNumber } from "../../helpers/helperfunctions";

class NewLeadFunctions {
    customInformationValidate = (step: number, lead: ICreateLeadUser, leadError: ILeadValidation, setLeadError: CallableFunction) => {
        if (step !== 0) return true;
        let newError = leadError;
        if (lead.name === '') {
            newError.name = true;
            setLeadError({ ...newError });
            return false;
        } else {
            newError.name = false;
            setLeadError({ ...newError });
        }

        if (!validatePhoneNumber(lead.phoneNumber)) {
            newError.phoneNumber = true;
            setLeadError({ ...newError });
            return false;
        } else {
            newError.phoneNumber = false;
            setLeadError({ ...newError });
        }

        if (lead.email === '' || !validateEmail(lead.email)) {
            newError.email = true;
            setLeadError({ ...newError });
            return false;
        } else {
            newError.email = false;
            setLeadError({ ...newError });
        }

        if (lead.nationality === '') {
            newError.nationality = true;
            setLeadError({ ...newError });
            return false;
        } else {
            newError.nationality = false;
            setLeadError({ ...newError });
        }

        return true;
    }

    createItineraryValidate = (step: number, lead: ICreateLeadData, leadError: ILeadValidation, setLeadError: CallableFunction) => {
        if (step !== 1) return true;
        let newError = leadError;
        if (!lead.portArrival) {
            newError.portArrival = true;
            setLeadError({ ...newError });
            return false;
        } else {
            newError.portArrival = false;
            setLeadError({ ...newError });
        }

        if (!lead.portDeparture && !lead.departFromSame) {
            newError.portDeparture = true;
            setLeadError({ ...newError });
            return false;
        } else {
            newError.portDeparture = false;
            setLeadError({ ...newError });
        }

        return true;
    }
}

export default NewLeadFunctions;