import axios from "axios";
import { ILoginData } from "../helpers/Interfaces";
import { getBaseURL} from "../helpers/helperfunctions";
import { getFromLocalStorage } from "../helpers/storageConfig";

export default class AuthService {

    async userLogin(data: ILoginData) {
        const ax = await this.ax();
        return await ax.post("/user/login", data);
    }

    async refreshToken(data: any) {
        const ax = await this.ax();
        return await ax.post("/user/refreshToken", data);
    }

    private async ax() {
        let baseUlr = getBaseURL();
        let tenantId = getFromLocalStorage('tenantId');

        return axios.create({
            baseURL: baseUlr,
            timeout: 60000,
            headers: {
                "Content-Type": "application/json",
                'x-tenant-id' : tenantId ?? ''
            }
        });
    }
}