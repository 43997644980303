import { collection, doc, getDoc, getDocs, setDoc, updateDoc } from "firebase/firestore"
import { auth, firestore, storage } from "../services/firebaseService"
import { getFromLocalStorage } from "./storageConfig";
import { IBuilderView, IItinerary } from "./Interfaces";
import { signInWithCustomToken, signInWithEmailAndPassword } from "firebase/auth";
import { getDownloadURL, ref, uploadBytes, uploadString } from "firebase/storage";
import jsPDF from "jspdf";

class FirebaseFunctions {

    authenticateUser = async (token: string) => {
        try {
            // auth.tenantId = 'explor-7zlpo';
            // await signInWithEmailAndPassword(auth, 'chamikase8@gmail.com', 'Abcd123@')
            // auth.tenantId = 'explor-7zlpo';
            await signInWithCustomToken(auth, token)
            return;
        } catch (error: any) {

            throw new Error(error);
        }
    }

    getItinerary = async (itineraryId: string) => {
        try {
            const itineraryRef = doc(firestore, `${getFromLocalStorage('tenantId')}/itineraries/info`, itineraryId);
            const itinerarySnap = await getDoc(itineraryRef);

            if (itinerarySnap.exists()) {
                const itinerary = { id: itinerarySnap.id, ...itinerarySnap.data() } as IItinerary
                return itinerary;
            }
        } catch (error) {
            console.log(error);
        }
    }

    updateItinerary = async (itineraryId: string, itineraryData: IItinerary) => {
        try {
            const itineraryRef = doc(firestore, `${getFromLocalStorage('tenantId')}/itineraries/info/`, itineraryId);
            itineraryData.modifiedDate = new Date().toISOString();
            const updateRef = await setDoc(itineraryRef, itineraryData as any);
            return;

        } catch (error) {
            console.log(error);
        }
    }


    addImageFirebase = async (fileName: string, file: string) => {
        try {
            const tenantId = getFromLocalStorage('tenantId');
            const storageRef = ref(storage, `${tenantId}/medias/${fileName}`);
            let downloadLink = "";

            const compressedFile = await this.compressImage(file);

            await uploadString(storageRef, compressedFile, "data_url").then(async (snapshot) => {
                await getDownloadURL(snapshot.ref).then((link: string) => {
                    downloadLink = link;
                })
            })
            return downloadLink;
        } catch (error) {
            console.log(error);
        }
    }

    compressImage = (file: string, quality: number = 0.5) => {
        return new Promise<string>((resolve, reject) => {
            const img = new Image();
            img.src = file;
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                const maxWidth = 800;
                const scaleSize = maxWidth / img.width;

                canvas.width = maxWidth;
                canvas.height = img.height * scaleSize;

                ctx?.drawImage(img, 0, 0, canvas.width, canvas.height);

                const compressedData = canvas.toDataURL('image/jpeg', quality);
                resolve(compressedData);
            };
            img.onerror = (error) => reject(error);
        });
    };

    savePDFToFirebase = async (pdf: jsPDF, itineraryId: string) => {
        try {
            const tenantId = getFromLocalStorage('tenantId');

            const pdfBlob = pdf.output("blob");
            const pdfRef = ref(storage, `${tenantId}/pdfs/pdf_${itineraryId}.pdf`);

            let downloadLink = "";
            await uploadBytes(pdfRef, pdfBlob).then(async (snapshot) => {
                await getDownloadURL(snapshot.ref).then((link: string) => {
                    downloadLink = link;
                })
            });

            return downloadLink;

        } catch (error) {
            console.error("Error uploading PDF to Firebase Storage:", error);
        }
    };
}

export default FirebaseFunctions;