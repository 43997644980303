import React from 'react'

const TopLeftRadius = () => {
    return (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_291_2683" maskUnits="userSpaceOnUse" x="0" y="0" width="21" height="20">
                <path d="M0.5 20L0.499999 0L20.5 -8.74228e-07L20.5 20L0.5 20Z" fill="white" />
            </mask>
            <g mask="url(#mask0_291_2683)">
                <path d="M16.3333 4.16667L16.3333 2.5L18 2.5L18 4.16667L16.3333 4.16667ZM14.6667 4.16667L14.6667 2.5L13 2.5L13 4.16667L14.6667 4.16667ZM11.3333 17.5L11.3333 15.8333L9.66667 15.8333L9.66667 17.5L11.3333 17.5ZM14.6667 17.5L14.6667 15.8333L13 15.8333L13 17.5L14.6667 17.5ZM8 17.5L8 15.8333L6.33333 15.8333L6.33333 17.5L8 17.5ZM4.66667 17.5L4.66667 15.8333L3 15.8333L3 17.5L4.66667 17.5ZM4.66667 14.1667L4.66667 12.5L3 12.5L3 14.1667L4.66667 14.1667ZM18 7.5L18 5.83333L16.3333 5.83333L16.3333 7.5L18 7.5ZM18 10.8333L18 9.16667L16.3333 9.16667L16.3333 10.8333L18 10.8333ZM18 7.5L18 5.83333L16.3333 5.83333L16.3333 7.5L18 7.5ZM18 14.1667L18 12.5L16.3333 12.5L16.3333 14.1667L18 14.1667ZM18 17.5L18 15.8333L16.3333 15.8333L16.3333 17.5L18 17.5ZM7.16667 2.5C4.86667 2.5 3 4.36667 3 6.66667L3 10.8333L4.66667 10.8333L4.66667 6.66667C4.66667 5.29167 5.79167 4.16667 7.16667 4.16667L11.3333 4.16667L11.3333 2.5L7.16667 2.5Z" fill="#134B5F" />
            </g>
        </svg>

    )
}

export default TopLeftRadius