import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUser } from "../../helpers/Interfaces";
import { getUser } from "../../helpers/storageConfig";

const initialState: string = '';

export const userSlice = createSlice({
    name: 'itineraryId',
    initialState,
    reducers: {
        setItineraryId: (state, action: PayloadAction<string>) => {
            return action.payload;
        },
        removeItineraryId: () => {
            return '';
        }
    }
});

export const { setItineraryId, removeItineraryId } = userSlice.actions;
export default userSlice.reducer;