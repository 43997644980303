import React from 'react'

const BackgroundIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_285_1702)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.1673 6.66634C15.1673 5.65367 14.3467 4.83301 13.334 4.83301H6.66732C5.65465 4.83301 4.83398 5.65367 4.83398 6.66634V13.333C4.83398 14.3457 5.65465 15.1663 6.66732 15.1663H13.334C14.3467 15.1663 15.1673 14.3457 15.1673 13.333V6.66634ZM14.1673 6.66634V13.333C14.1673 13.793 13.794 14.1663 13.334 14.1663H6.66732C6.20732 14.1663 5.83398 13.793 5.83398 13.333V6.66634C5.83398 6.20634 6.20732 5.83301 6.66732 5.83301H13.334C13.794 5.83301 14.1673 6.20634 14.1673 6.66634Z" fill="#251C15" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.16732 9.20701L2.25465 11.1197C2.38732 11.1503 2.52532 11.1663 2.66732 11.1663H4.16732V9.20701ZM4.37598 5.66501L0.833984 9.20701V9.33301C0.833984 9.82901 1.03065 10.279 1.35065 10.609L4.16732 7.79234V6.66634C4.16732 6.31034 4.24198 5.97101 4.37598 5.66501ZM7.79332 0.833008L0.833984 7.79234V5.87367L5.87465 0.833008H7.79332ZM4.45998 0.833008H2.66732C1.65465 0.833008 0.833984 1.65367 0.833984 2.66634V4.45901L4.45998 0.833008ZM5.66598 4.37501C5.97198 4.24101 6.31132 4.16634 6.66732 4.16634H7.79332L10.61 1.34967C10.28 1.02967 9.82998 0.833008 9.33398 0.833008H9.20798L5.66598 4.37501ZM9.20798 4.16634H11.1673V2.66634C11.1673 2.52434 11.1513 2.38634 11.1207 2.25367L9.20798 4.16634Z" fill="#251C15" />
            </g>
            <defs>
                <clipPath id="clip0_285_1702">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default BackgroundIcon