import './ItineraryCard.scss'
import calendarIcon from "../../assets/images/lead-card/calendar.svg";
import { IItinerary } from '../../helpers/Interfaces';
import { formatDate } from '../../helpers/helperfunctions';
import { MoreVert } from '@mui/icons-material';
import { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const ItineraryCard = (props: { itinerary?: IItinerary, onClick: CallableFunction, selected?: boolean, onEdit?: CallableFunction }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const menuItems = []

    if (props.onEdit) {
        menuItems.push({ action: props.onEdit ? props.onEdit : undefined, text: 'Edit Itinerary' })
    }
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div onClick={() => props.onClick(props.itinerary)} className={`itinerary-card-outer ${props.selected ? 'active-card' : ''}`}>
            <img className='itinerary-image' src={'https://media.istockphoto.com/id/1317323736/photo/a-view-up-into-the-trees-direction-sky.jpg?s=2048x2048&w=is&k=20&c=oo7SGfjmPkybpqoNccDsYWG-4uxSmn8G79NiLA1mNvs='} alt="" />
            <div className="card-details">
                <div className="primary-font card-header">
                    <span>{props.itinerary?.name}</span>
                    {(menuItems.length > 0) ? (<button onClick={handleClick}><MoreVert /></button>) : (<></>)}
                </div>
                <div className='card-details-inner primary-font'>
                    <ul className="normal-list lead-icon-list">
                        {/* {props.itinerary?.country ? (<li className="lead-icon-item"><span className="icon"><img src={globeIcon} alt="Phone" /></span><span className="txt">{props.itinerary?.country}</span></li>) : (<></>)}
                        {props.itinerary?.budgetRangeFrom ? (<li className="lead-icon-item"><span className="icon"><img src={currencyIcon} alt="Phone" /></span><span className="txt">{`£${props.itinerary?.budgetRangeFrom} ${props.itinerary?.budgetRangeTo ? (' - £' + props.itinerary?.budgetRangeTo) : ''}`}</span></li>) : (<></>)} */}
                        {props.itinerary?.createdDate ? (<li className="lead-icon-item"><span className="icon"><img src={calendarIcon} alt="Phone" /></span><span className="txt"> {props.itinerary?.createdDate ? formatDate(props.itinerary?.createdDate, 'DD/MM/YYYY') : ''}</span></li>) : (<></>)}
                    </ul>
                    <ul className="normal-list lead-icon-list">
                        <li className="lead-icon-item">0 Revisions</li>
                        {/* {props.itinerary?.reviews ? (<li className="lead-icon-item">{props.itinerary?.reviews} Revisions</li>) : (<></>)} */}
                        {/* {props.itinerary?.reviews ? (<li className="lead-icon-item"><span className="icon"><img src={family} alt="Phone" /></span><span className="txt">{props.itinerary?.travelGroupAdults}</span></li>) : (<></>)} */}
                    </ul>
                </div>
            </div>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose} >
                {menuItems.map((i) => <MenuItem onClick={() => {
                    handleClose();
                    if (i.action) i.action();
                }}>{i.text}</MenuItem>)}
            </Menu>
        </div>
    )
}

export default ItineraryCard