import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { leadTrackerStages } from "../../helpers/constants";
import { getFromLocalStorage } from "../../helpers/storageConfig";

const stages = getFromLocalStorage('leadStages');
const initialState: string[] = stages ? stages?.split(', ') : leadTrackerStages;

export const leadStagesSlice = createSlice({
    name: 'leadStages',
    initialState,
    reducers: {
        setLeadStages: (state, action: PayloadAction<string[]>) => {
            return action.payload;
        },
        removeLeadStages: () => {
            return [];
        }
    }
});

export const { setLeadStages, removeLeadStages } = leadStagesSlice.actions;
export default leadStagesSlice.reducer;