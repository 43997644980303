const SuperScript = (props: any) => {
  return (<svg onClick={props.onClick} style={{ cursor: 'pointer' }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_5186_13242)">
      <path d="M19.0904 7.27369H16.3631V7.04647C16.3631 6.42009 16.8732 5.91006 17.4996 5.91006C18.8785 5.91006 19.9994 4.7893 19.9994 3.41019C19.9994 2.03109 18.8785 0.910156 17.4996 0.910156H15.4541C14.9512 0.910156 14.5449 1.31648 14.5449 1.81918C14.5449 2.32206 14.9512 2.72838 15.4541 2.72838H17.4996C17.8749 2.72838 18.1814 3.03466 18.1814 3.41019C18.1814 3.78556 17.8749 4.09201 17.4996 4.09201C15.8704 4.09201 14.5449 5.41735 14.5449 7.04647V8.18288C14.5449 8.68559 14.9512 9.09191 15.4541 9.09191H19.0904C19.5931 9.09191 19.9994 8.68559 19.9994 8.18288C19.9994 7.68018 19.5931 7.27369 19.0904 7.27369Z" fill="#251C15" />
      <path d="M11.4709 4.73846C11.0736 4.42569 10.5047 4.4966 10.1945 4.89127L5.90924 10.3448L1.62377 4.89127C1.31383 4.4966 0.742877 4.42569 0.347372 4.73846C-0.0471343 5.04841 -0.116214 5.62036 0.19473 6.01486L4.75286 11.8166L0.19473 17.6183C-0.116214 18.0138 -0.0471343 18.5848 0.347372 18.8947C0.51383 19.0266 0.711916 19.0893 0.908336 19.0893C1.17833 19.0893 1.44467 18.9701 1.62377 18.7421L5.90924 13.2884L10.1945 18.7421C10.3737 18.9701 10.64 19.0893 10.91 19.0893C11.1064 19.0893 11.3047 19.0266 11.4709 18.8947C11.8655 18.5848 11.9345 18.013 11.6238 17.6183L7.06546 11.8166L11.6238 6.01486C11.9345 5.62036 11.8655 5.04841 11.4709 4.73846Z" fill="#251C15" />
    </g>
    <defs>
      <clipPath id="clip0_5186_13242">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>)
}

export default SuperScript