import React from 'react'

const Image = () => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_287_1932)">
<path d="M5.70795 15.4437C4.37384 15.4437 3.18713 14.5895 2.75621 13.3178L2.72707 13.222C2.62544 12.8852 2.58287 12.602 2.58287 12.3186V6.63672L0.561182 13.3852C0.301166 14.3778 0.893679 15.4069 1.88781 15.6811L14.774 19.1321C14.9349 19.1738 15.0957 19.1938 15.2541 19.1938C16.084 19.1938 16.8424 18.6429 17.055 17.8311L17.8057 15.4437H5.70795Z" fill="#134B5F"/>
<path d="M7.99879 6.69289C8.918 6.69289 9.6654 5.94535 9.6654 5.02613C9.6654 4.10692 8.918 3.35938 7.99879 3.35938C7.07958 3.35938 6.33203 4.10692 6.33203 5.02613C6.33203 5.94535 7.07958 6.69289 7.99879 6.69289Z" fill="#134B5F"/>
<path d="M18.4157 0.859375H5.91536C4.76711 0.859375 3.83203 1.79446 3.83203 2.94286V12.1096C3.83203 13.2581 4.76711 14.1931 5.91536 14.1931H18.4157C19.5641 14.1931 20.4992 13.2581 20.4992 12.1096V2.94286C20.4992 1.79446 19.5641 0.859375 18.4157 0.859375ZM5.91536 2.52613H18.4157C18.6458 2.52613 18.8324 2.71275 18.8324 2.94286V8.85884L16.1999 5.78702C15.9206 5.45955 15.5164 5.28453 15.0823 5.27461C14.6506 5.27705 14.2456 5.46871 13.969 5.80044L10.8738 9.51544L9.8655 8.50956C9.29557 7.93963 8.36797 7.93963 7.7988 8.50956L5.49879 10.8088V2.94286C5.49879 2.71275 5.68541 2.52613 5.91536 2.52613Z" fill="#134B5F"/>
</g>
<defs>
<clipPath id="clip0_287_1932">
<rect width="20" height="20" fill="white" transform="translate(0.5)"/>
</clipPath>
</defs>
</svg>

  )
}

export default Image