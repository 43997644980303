import { resolve } from "path";
import { DropdownItemProps } from "../../../components/Mui/CustomAutocomplete/CustomAutocomplete";
import { ICreateLeadData, ICreateLeadUser, IItinerary, IItineraryOptions, ILead, ILeadUser } from "../../../helpers/Interfaces";
import { sortLeads } from "../../../helpers/helperfunctions";
import ApiService from '../../../services/ApiService'
import { rejects } from "assert";
import { AxiosResponse } from "axios";
class LeadFunctions {
    apiService = new ApiService();

    createLead = async (lead: ICreateLeadData, organizationUser: ICreateLeadUser, setLoading: CallableFunction, refreshFunction?: CallableFunction) => {
        const createLeadPromise = new Promise((resolve, reject) => {
            setLoading(true);
            this.apiService.createLead({
                lead, organizationUser
            }).then((response) => {
                if (refreshFunction)
                    refreshFunction();
                resolve(response);
            }).catch((error) => {
                console.log(error);
                reject(error)
            }).finally(() => {
                setLoading(false);
            });
        })

        return createLeadPromise;
    }

    updateLead = async (lead: ICreateLeadData, setLoading: CallableFunction, refreshFunction?: CallableFunction) => {
        const updateClientPromise = new Promise((resolve, reject) => {
            setLoading(true);
            this.apiService.updateLead({ lead })
                .then((response) => {
                    if (refreshFunction)
                        refreshFunction();
                    resolve(response);
                }).catch((error) => {
                    console.log(error);
                    reject(error)
                }).finally(() => {
                    setLoading(false);
                });
        })

        return updateClientPromise;
    }

    createClient = async (organizationUser: ICreateLeadUser, setLoading: CallableFunction, refreshFunction?: CallableFunction) => {
        setLoading(true);
        const createClientPromise = new Promise((resolve, reject) => {
            this.apiService.createClient({
                organizationUser
            }).then((response) => {
                if (refreshFunction)
                    refreshFunction();
                resolve(response);
            }).catch((error) => {
                console.log(error);
                reject(error)
            }).finally(() => {
                setLoading(false);
            })
        })

        return createClientPromise;
    }

    getAllLeads = (setLeads: CallableFunction, setLoading?: CallableFunction) => {
        if (setLoading) setLoading(true)
        this.apiService.getAllLeads().then((response) => {
            const leads = sortLeads(response.data.data as ILead[])
            setLeads(leads);
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            if (setLoading) setLoading(false)
        })
    }


    getAllOrganizationUsers = (setUsers: CallableFunction) => {

        this.apiService.getAllOrganizationUsers().then((response) => {
            const users = response.data.data;
            setUsers(users);
        }).catch((error) => {
            console.log(error);
            setUsers([]);
        }).catch((error) => console.log(error))
    }

    getAllUser = (setUsers: CallableFunction) => {
        try {
            this.apiService.getAllUsers().then((response) => {
                let users = response.data.data as DropdownItemProps[];
                return setUsers(users);
            });
        } catch (error) {
            setUsers([])
        }
    }

    getAllItineraries = (setItineraries: CallableFunction, setItineraryLoading: CallableFunction) => {
        setItineraryLoading(true);
        this.apiService.getAllItineraries().then((response) => {
            let itineraries = response.data.data.sent;
            if (itineraries) {
                setItineraries(itineraries);
            } else {
                setItineraries({ draft: [], sent: [] })
            }
            return setItineraryLoading(false);
        }).catch(() => {
            setItineraries({ draft: [], sent: [] });
        }).finally(() => {
            setItineraryLoading(false);
        });
    }


    handleGenerateItinerary = (lead: ICreateLeadData, leadUser: ICreateLeadUser, leadId: string, selectedItinerary?: IItinerary) => {
        const itineraryContent: IItineraryOptions = {
            name: `New Itinerary for ${leadUser.name}`,
            daysAndNights: lead.daysAndNights,
            budgetRangeFrom: lead.budgetRangeFrom,
            budgetRangeTo: lead.budgetRangeTo,
            travelGroupAdults: lead.travelGroupAdults,
            travelGroupChildren: lead.travelGroupChildren,
            travelGroupInfants: lead.travelStyle
        }

        const data = {
            itineraryId: selectedItinerary?.id,
            leadId: leadId,
            itineraryData: itineraryContent
        }

        const createItineraryPromise = new Promise((resolve, rejects) => {
            this.apiService.getAddItineraries(data).then((response) => {
                resolve(response);
            }).catch((error) => {
                rejects(error);
            })
        });

        return createItineraryPromise;
    }


}

export default LeadFunctions;