const CancelIcon = () => {
    return (
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="49" height="49" rx="9.5" fill="#F5E3D7" fill-opacity="0.6" stroke="#E27C37" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.616 16.616C16.8504 16.3816 17.1683 16.25 17.4997 16.25C17.8312 16.25 18.1491 16.3816 18.3835 16.616L24.9997 23.2322L31.616 16.616C31.7313 16.4966 31.8692 16.4014 32.0217 16.3359C32.1742 16.2703 32.3383 16.2359 32.5042 16.2344C32.6702 16.233 32.8348 16.2646 32.9885 16.3275C33.1421 16.3903 33.2816 16.4831 33.399 16.6005C33.5163 16.7179 33.6092 16.8574 33.6721 17.011C33.7348 17.1647 33.7665 17.3293 33.7651 17.4952C33.7636 17.6612 33.7291 17.8252 33.6636 17.9777C33.5981 18.1302 33.5028 18.2682 33.3835 18.3835L26.7672 24.9997L33.3835 31.616C33.6112 31.8517 33.7372 32.1675 33.7343 32.4952C33.7315 32.823 33.6001 33.1365 33.3682 33.3682C33.1365 33.6 32.823 33.7315 32.4952 33.7344C32.1675 33.7372 31.8517 33.6112 31.616 33.3835L24.9997 26.7672L18.3835 33.3835C18.1477 33.6112 17.832 33.7372 17.5042 33.7344C17.1765 33.7315 16.863 33.6 16.6312 33.3682C16.3995 33.1365 16.268 32.823 16.2652 32.4952C16.2623 32.1675 16.3883 31.8517 16.616 31.616L23.2322 24.9997L16.616 18.3835C16.3816 18.1491 16.25 17.8312 16.25 17.4997C16.25 17.1683 16.3816 16.8504 16.616 16.616Z" fill="#E27C37" />
        </svg>

    )
}

export default CancelIcon