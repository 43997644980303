import { t } from "i18next"
import { ReactElement } from "react"
import { Link } from "react-router-dom"
import { useLocation } from 'react-router-dom'

type MainMenuProps = {
    title: string,
    icon: ReactElement,
    childList?: string[],
    defaultTab?: number,
    notificationCount?: number
}

const MainMenu = ({ title, icon, childList, defaultTab, notificationCount }: MainMenuProps) => {
    const location = useLocation();
    const routes = childList ? childList?.map(child => (`/dashboard/${child.replaceAll(' ', '-')}`)) : [`/dashboard/${title.replaceAll(' ', '-')}`];

   

    return (
        <li className="main-manu-item">
            <Link to={routes[defaultTab ?? 0]} className={`main-manu-link ${routes.includes(location.pathname) && 'active'}`}>
                <span className="icon">
                    {icon}
                </span>
                <span className="txt">{t(title)}</span>
                {notificationCount ? <span className="msg-count">{notificationCount}</span> : <></>}
            </Link>
            {childList
                && (routes.includes(location.pathname)
                    && (<ul className="child-list normal-list">
                        {childList?.map((child, index) => (
                            <li key={child} className="child-list-item">
                                <Link to={routes[index]} className={`child-list-link ${location.pathname === routes[index] && 'active'}`}><span className="txt-item">{t(child)}</span></Link>
                            </li>))}
                    </ul>))}
        </li>
    )
}

export default MainMenu