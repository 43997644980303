const InboxSvg = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.58142 6.57564C5.58142 6.17213 5.89378 5.84502 6.27909 5.84502H10C10.3853 5.84502 10.6977 6.17213 10.6977 6.57564C10.6977 6.97915 10.3853 7.30627 10 7.30627H6.27909C5.89378 7.30627 5.58142 6.97915 5.58142 6.57564Z" fill="white" />
        <path d="M5.58142 10.4723C5.58142 10.0688 5.89378 9.7417 6.27909 9.7417H13.721C14.1063 9.7417 14.4186 10.0688 14.4186 10.4723C14.4186 10.8758 14.1063 11.203 13.721 11.203H6.27909C5.89378 11.203 5.58142 10.8758 5.58142 10.4723Z" fill="white" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.10831 0H11.8917C13.1677 0 14.1681 -9.69686e-06 14.9722 0.0687862C15.7909 0.138839 16.4672 0.283854 17.0794 0.610522C18.0859 1.14755 18.9042 2.00447 19.417 3.05845C19.7289 3.69959 19.8674 4.40791 19.9343 5.26533C20 6.10734 20 7.15499 20 8.49127V9.6776V9.8207C20.0002 11.3293 20.0004 12.2496 19.7839 13.0226C19.2713 14.8533 17.9043 16.2849 16.1562 16.8217C15.418 17.0484 14.5392 17.0482 13.0987 17.048C13.0537 17.048 13.0082 17.048 12.962 17.048H12.4515L12.3954 17.0481C11.5887 17.0534 10.803 17.3172 10.1435 17.8038L10.0978 17.8379L7.66921 19.6546C6.26967 20.7014 4.43058 19.1896 5.06934 17.5172C5.15519 17.2925 4.99712 17.048 4.76596 17.048H4.20619C1.88317 17.048 0 15.0759 0 12.6431V8.49129C0 7.155 -9.25951e-06 6.10735 0.0656837 5.26533C0.132577 4.40791 0.271051 3.69959 0.582986 3.05845C1.09579 2.00447 1.91406 1.14755 2.92051 0.610522C3.53273 0.283854 4.2091 0.138839 5.02785 0.0687862C5.83189 -9.69686e-06 6.83229 0 8.10831 0ZM5.14147 1.52519C4.40902 1.58786 3.93671 1.70829 3.55399 1.91251C2.81009 2.30944 2.20528 2.94282 1.82625 3.72185C1.63125 4.12266 1.51624 4.61727 1.4564 5.38431C1.39589 6.15989 1.39535 7.14796 1.39535 8.52398V12.6431C1.39535 14.2689 2.6538 15.5867 4.20619 15.5867H4.76596C5.98429 15.5867 6.81736 16.8753 6.36489 18.0599C6.24369 18.3772 6.59264 18.6641 6.85818 18.4655L9.33972 16.6093C10.2319 15.951 11.2951 15.5941 12.3865 15.5868L12.4515 15.5867H12.962C14.5824 15.5867 15.2431 15.5793 15.7635 15.4194C17.0556 15.0227 18.066 13.9645 18.4449 12.6114C18.5976 12.0664 18.6046 11.3745 18.6046 9.6776V8.52398C18.6046 7.14796 18.6041 6.15989 18.5436 5.38431C18.4837 4.61727 18.3687 4.12266 18.1738 3.72185C17.7947 2.94282 17.1899 2.30944 16.446 1.91251C16.0632 1.70829 15.591 1.58786 14.8585 1.52519C14.1179 1.46182 13.1744 1.46125 11.8605 1.46125H8.13953C6.82557 1.46125 5.88206 1.46182 5.14147 1.52519Z"
            fill="white"
        />
    </svg>
);


export default InboxSvg