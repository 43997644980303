import { MouseEventHandler } from "react"

const UnderlineIcon = (props: { isActive: boolean, onClick: MouseEventHandler<SVGSVGElement> }) => {
    return (<svg onClick={props.onClick} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.6667 17H4.33333C3.97971 17 3.64057 17.1405 3.39052 17.3905C3.14048 17.6406 3 17.9797 3 18.3333C3 18.687 3.14048 19.0261 3.39052 19.2761C3.64057 19.5262 3.97971 19.6667 4.33333 19.6667H15.6667C16.0203 19.6667 16.3594 19.5262 16.6095 19.2761C16.8595 19.0261 17 18.687 17 18.3333C17 17.9797 16.8595 17.6406 16.6095 17.3905C16.3594 17.1405 16.0203 17 15.6667 17Z" fill={`${props.isActive ? '#e27c37' : '#251C15'}`} />
        <path d="M1.33398 1.66536C1.33398 2.01899 1.47446 2.35813 1.72451 2.60817C1.97456 2.85822 2.3137 2.9987 2.66732 2.9987H3.00065V8.66537C3.00065 10.5219 3.73815 12.3024 5.0509 13.6151C6.36366 14.9279 8.14414 15.6654 10.0007 15.6654C11.8572 15.6654 13.6376 14.9279 14.9504 13.6151C16.2632 12.3024 17.0007 10.5219 17.0007 8.66537V2.9987H17.334C17.6876 2.9987 18.0267 2.85822 18.2768 2.60817C18.5268 2.35813 18.6673 2.01899 18.6673 1.66536C18.6673 1.31174 18.5268 0.972604 18.2768 0.722556C18.0267 0.472507 17.6876 0.332031 17.334 0.332031H14.0007C13.647 0.332031 13.3079 0.472507 13.0578 0.722556C12.8078 0.972604 12.6673 1.31174 12.6673 1.66536C12.6673 2.01899 12.8078 2.35813 13.0578 2.60817C13.3079 2.85822 13.647 2.9987 14.0007 2.9987H14.334V8.66537C14.334 9.81464 13.8774 10.9168 13.0648 11.7295C12.2521 12.5422 11.1499 12.9987 10.0007 12.9987C8.85138 12.9987 7.74918 12.5422 6.93652 11.7295C6.12386 10.9168 5.66732 9.81464 5.66732 8.66537V2.9987H6.00065C6.35427 2.9987 6.69341 2.85822 6.94346 2.60817C7.19351 2.35813 7.33398 2.01899 7.33398 1.66536C7.33398 1.31174 7.19351 0.972604 6.94346 0.722556C6.69341 0.472507 6.35427 0.332031 6.00065 0.332031H2.66732C2.3137 0.332031 1.97456 0.472507 1.72451 0.722556C1.47446 0.972604 1.33398 1.31174 1.33398 1.66536Z" fill={`${props.isActive ? '#e27c37' : '#251C15'}`} />
    </svg>
    )
}

export default UnderlineIcon