import "./Sidebar.scss";
import logoImg from "../../assets/images/logo.svg";
import MainMenu from "./MainMenu";
import ItinerariesSvg from "../../assets/images/dashboard/ItinerariesSvg";
import DashboardSvg from "../../assets/images/dashboard/DashboardSvg";
import InboxSvg from "../../assets/images/dashboard/InboxSvg";
import LibrarySvg from "../../assets/images/dashboard/LibrarySvg";
import ContactsSvg from "../../assets/images/dashboard/ContactsSvg";
import ReportsSvg from "../../assets/images/dashboard/ReportsSvg";
import AccountSvg from "../../assets/images/dashboard/AccountSvg";
import SettingsSvg from "../../assets/images/dashboard/SettingsSvg";
import LogoutSvg from "../../assets/images/dashboard/LogoutSvg";
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from "../../store/store";
import { removeUser } from "../../store/slices/userSlice";
import { clearStorage } from "../../helpers/storageConfig";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { changeSideBar } from "../../store/slices/sideBarSlice";
import { useEffect } from "react";

const Sidebar = () => {
  const user = useSelector((state: RootState) => state.user);
  const minimize = useSelector((state: RootState) => state.sideBar);
  const dispatch = useDispatch<AppDispatch>();

  const toggleClick = () => dispatch(changeSideBar(!minimize));

  useEffect(() => {
    const isSafari = navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') === -1;
    if (isSafari) {
      let div_list = document.querySelectorAll('.child-list-link') as any;
      let div_array = [...div_list];
      div_array.forEach(div => {
        div?.classList.add('mac-child-list-link');
      });
    }
  })

  return (
    <aside className={`sidebar flex-0-a ${minimize ? 'collapsed' : 'expand'}`}>
      <div className="sidebar-inner">
        <div className="top">
          <div className="logo">
            <div className="logo-inner"><img src={logoImg} className="logo-img" alt="logo" /></div>
          </div>
          <nav className="nav-vertical">
            <ul className="main-manu list-s-none">
              <MainMenu title={"dashboard"} icon={<DashboardSvg />} childList={['overview', 'lead tracker', 'calendar']} defaultTab={1} />
              <MainMenu title={"inbox"} icon={<InboxSvg />} childList={['primary', 'important']} notificationCount={0} />
              <MainMenu title={"itineraries"} icon={<ItinerariesSvg />} childList={['builder', 'templates']} />
              <MainMenu title={"library"} icon={<LibrarySvg />} childList={['attraction', 'activity', 'transport', 'accommodation']} />
              <MainMenu title={"contacts"} icon={<ContactsSvg />} childList={['vendors', 'partners', 'clients']} />
              <MainMenu title={"accounts"} icon={<AccountSvg />} />
              <MainMenu title={"reports"} icon={<ReportsSvg />} />
              <MainMenu title={"settings"} icon={<SettingsSvg />} childList={['company', 'my profile', 'permission', 'subscription', 'team', 'support']} />
            </ul>
          </nav>
        </div>
        <div className="bottom">
          <div className="toggle-inner">
            <button onClick={toggleClick}>
              {minimize ? (<KeyboardArrowRight classes={{ root: 'image-icon' }} />) : <KeyboardArrowLeft classes={{ root: 'image-icon' }} />}
            </button>
          </div>
          <div className="bottom-inner">
            <div className="lft flex-0-a">
              <span className="name fz-16 ttu primary-font c-white db">{user.displayName}</span>
              <span className="role ttu primary-font c-white db fz-10">Administrator</span>
            </div>
            <div className="rht flex-0-a">
              <button className="icon" onClick={() => {
                clearStorage();
                dispatch(removeUser());
              }}>
                <LogoutSvg />
              </button>
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
