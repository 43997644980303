import React from 'react'

const BorderRadiusIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_291_2607)">
                <path d="M4.44727 15.4922H0.931641C0.688934 15.4922 0.492188 15.2954 0.492188 15.0527V4.44736C0.492188 2.26648 2.26648 0.492188 4.44736 0.492188H15.0527C15.2954 0.492188 15.4922 0.688934 15.4922 0.931641V4.44727C15.4922 4.68997 15.2954 4.88672 15.0527 4.88672H4.88672V15.0527C4.88672 15.2954 4.68997 15.4922 4.44727 15.4922Z" fill="#251C15" />
            </g>
            <defs>
                <clipPath id="clip0_291_2607">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default BorderRadiusIcon