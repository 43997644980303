import Dashboard from '../../components/Dashboard/Dashboard'

function NotImplemented() {
  return (
    <Dashboard headerTitle='Not Implemented'>
        <div className='m-1'>This Page is not implemented</div>
    </Dashboard>
  )
}

export default NotImplemented