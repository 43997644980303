import React from 'react'
import { ICreateLeadData, IStepperItem } from '../../helpers/Interfaces'
import CustomMultiSelect from '../Mui/CustomMultiSelect/CustomMultiSelect'
import CustomTextField from '../Mui/CustomTextField/CustomTextField'
import Stepper from '../Stepper/Stepper'
import { getMultiSelectDefaultValues, getMultiSelectedItemsString } from '../../helpers/helperfunctions'
import { accessibilityRequirements, dietaryRestrictions, spacialCargoEquipment } from '../../helpers/constants'
import { t } from 'i18next'

type SpecialRequirementsProps = {
  divARef: React.MutableRefObject<HTMLDivElement | null>
  divBRef: React.MutableRefObject<HTMLDivElement | null>
  stepperSteps: IStepperItem[],
  lead: ICreateLeadData,
  setLead: CallableFunction
}

const SpecialRequirements = ({ divARef, divBRef, stepperSteps, lead, setLead }: SpecialRequirementsProps) => {

  return (
    <div className="middle">
      <Stepper activeStep={3} divARef={divARef} divBRef={divBRef} steps={stepperSteps} />
      <div className="rht flex-0-a">
        <div className="lead-rht-inner">
          <div ref={divBRef} className="scroll-div">
            <h4 className="header primary-font fz-16 ttu fw-400">{t('specialRequirements')}</h4>
            <div className="form-outer items-1 mb-40">
              <div className="form-group">
                <CustomMultiSelect helperText={t('fieldRequired')} label={t('specialCargo')} options={spacialCargoEquipment}
                  defaultValue={getMultiSelectDefaultValues(spacialCargoEquipment, lead.spacialCargoEquipment)} onSelect={(value) => {
                    if (!value) return;
                    let newLead = lead;
                    newLead.spacialCargoEquipment = getMultiSelectedItemsString(value);
                    setLead({ ...newLead });
                  }} />
              </div>
              <div className="form-group">
                <CustomMultiSelect helperText={t('fieldRequired')} label={t('accessibilityRequirements')} options={accessibilityRequirements}
                  defaultValue={getMultiSelectDefaultValues(accessibilityRequirements, lead.accessibility)} onSelect={(value) => {
                    if (!value) return;
                    let newLead = lead; 
                    newLead.accessibility = getMultiSelectedItemsString(value);
                    setLead({ ...newLead });
                  }} />
              </div>
              <div className="form-group">
                <CustomMultiSelect helperText={t('fieldRequired')} label={t('dietaryRestrictions')} options={dietaryRestrictions}
                  defaultValue={getMultiSelectDefaultValues(dietaryRestrictions, lead.accessibility)} onSelect={(value) => {
                    if (!value) return;
                    let newLead = lead;
                    newLead.dietaryRestrictions = getMultiSelectedItemsString(value);
                    setLead({ ...newLead });
                  }} />
              </div>
              <div className="form-group">
                <CustomTextField label={t('specialNotes')} helperText={t('fieldRequired')} multiline maxRows={6}
                  defaultValue={lead.notes} onChange={(val) => {
                    if (!val) return;
                    let newLead = lead;
                    newLead.notes = val.target.value;
                    setLead({ ...newLead });
                  }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SpecialRequirements