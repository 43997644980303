import React from 'react'

const SendIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_5186_13520)">
                <path d="M19.4161 0.581765C19.357 0.523366 19.2697 0.507077 19.1934 0.540827L1.04396 8.65829C0.779471 8.77653 0.589939 9.01708 0.538221 9.30169C0.485291 9.5863 0.576424 9.8786 0.780642 10.0832L4.6906 13.9931L15.3424 4.36407C15.3818 4.32774 15.4433 4.3293 15.4815 4.36751C15.5198 4.40555 15.521 4.46723 15.4853 4.50751L5.85744 15.1593L6.35326 18.9902C6.36935 19.1188 6.4506 19.2301 6.5674 19.2853C6.68564 19.3409 6.91814 19.2892 6.99939 19.1879C7.45478 18.6246 8.25853 17.54 8.25853 17.54L9.91385 19.216C10.1193 19.4206 10.4109 19.5114 10.6953 19.4588C10.9808 19.4064 11.2208 19.2172 11.3389 18.9532L19.4567 0.803991C19.4912 0.728601 19.474 0.640202 19.4161 0.581765Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_5186_13520">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default SendIcon