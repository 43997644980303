import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { useEffect, useState } from 'react'
import './CustomTaskStageDropDown.scss'
import { LeadTaskStages } from '../../../helpers/types';
import { taskStages } from '../../../helpers/constants';
import { getStageColor } from '../../../helpers/helperfunctions';

const CustomTaskStageDropDown = ({ defaultStage, changeStage }: { defaultStage: LeadTaskStages, changeStage: CallableFunction }) => {
    const [stage, setStage] = useState<LeadTaskStages>('PENDING');

    useEffect(() => {
        setStage(defaultStage)
    }, [defaultStage])

    const handleChange = (event: SelectChangeEvent) => {
        setStage(event.target.value as LeadTaskStages);
        changeStage(event.target.value);
    };

   

    return (
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small"
            classes={{ root: "custom-mui-field custom-task-stage-select-dropdown" }}>
            <Select
                IconComponent={"span"}
                id="demo-select-small"
                value={stage}
                sx={{ backgroundColor: `var(${getStageColor(stage)})` }}
                className='ttu'
                onChange={handleChange} >
                {taskStages.map((stage: string) => <MenuItem value={stage}>{stage.replaceAll('_', ' ')}</MenuItem>)}
            </Select>
        </FormControl>
    )
}

export default CustomTaskStageDropDown