import { MouseEventHandler } from "react"

const AlignRightIcon = (props: { isActive?: boolean, onClick: MouseEventHandler<SVGSVGElement> }) => {
    return (<svg onClick={props.onClick} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.4993 1.66797H2.49935C2.27834 1.66797 2.06637 1.75577 1.91009 1.91205C1.75381 2.06833 1.66602 2.28029 1.66602 2.5013C1.66602 2.72232 1.75381 2.93428 1.91009 3.09056C2.06637 3.24684 2.27834 3.33464 2.49935 3.33464H17.4993C17.7204 3.33464 17.9323 3.24684 18.0886 3.09056C18.2449 2.93428 18.3327 2.72232 18.3327 2.5013C18.3327 2.28029 18.2449 2.06833 18.0886 1.91205C17.9323 1.75577 17.7204 1.66797 17.4993 1.66797Z" fill={`${props.isActive ? '#e27c37' : '#251C15'}`} />
        <path d="M17.4994 8.33464C17.7204 8.33464 17.9323 8.24684 18.0886 8.09056C18.2449 7.93428 18.3327 7.72232 18.3327 7.5013C18.3327 7.28029 18.2449 7.06833 18.0886 6.91205C17.9323 6.75577 17.7204 6.66797 17.4994 6.66797H7.49935C7.27834 6.66797 7.06637 6.75577 6.91009 6.91205C6.75381 7.06833 6.66602 7.28029 6.66602 7.5013C6.66602 7.72232 6.75381 7.93428 6.91009 8.09056C7.06637 8.24684 7.27834 8.33464 7.49935 8.33464H17.4994Z" fill={`${props.isActive ? '#e27c37' : '#251C15'}`} />
        <path d="M1.66602 12.5013C1.66602 12.7223 1.75381 12.9343 1.91009 13.0906C2.06637 13.2468 2.27834 13.3346 2.49935 13.3346H17.4993C17.7204 13.3346 17.9323 13.2468 18.0886 13.0906C18.2449 12.9343 18.3327 12.7223 18.3327 12.5013C18.3327 12.2803 18.2449 12.0683 18.0886 11.912C17.9323 11.7558 17.7204 11.668 17.4993 11.668H2.49935C2.27834 11.668 2.06637 11.7558 1.91009 11.912C1.75381 12.0683 1.66602 12.2803 1.66602 12.5013Z" fill={`${props.isActive ? '#e27c37' : '#251C15'}`} />
        <path d="M18.3327 17.5013C18.3327 17.2803 18.2449 17.0683 18.0886 16.912C17.9323 16.7558 17.7204 16.668 17.4994 16.668H7.49935C7.27834 16.668 7.06637 16.7558 6.91009 16.912C6.75381 17.0683 6.66602 17.2803 6.66602 17.5013C6.66602 17.7223 6.75381 17.9343 6.91009 18.0906C7.06637 18.2468 7.27834 18.3346 7.49935 18.3346H17.4994C17.7204 18.3346 17.9323 18.2468 18.0886 18.0906C18.2449 17.9343 18.3327 17.7223 18.3327 17.5013Z" fill={`${props.isActive ? '#e27c37' : '#251C15'}`} />
    </svg>
    )
}

export default AlignRightIcon