import { t } from "i18next";
import { DropdownItemProps } from "../components/Mui/CustomAutocomplete/CustomAutocomplete";
import { ILeadStageLocalization } from "./Interfaces";

export const nationality: DropdownItemProps[] = [
    { label: "Sri Lankan", id: 1 },
    { label: "Indian", id: 2 },
    { label: "American", id: 3 },
    { label: "Americansdadas dsas", id: 4 },
];

export const modeOfArrival: DropdownItemProps[] = [
    { label: 'notDecidedYet', id: 1 },
    { label: 'airplane', id: 2 },
    { label: 'train', id: 3 },
    { label: 'cruiseShip', id: 4 },
    { label: 'privateTransfer', id: 5 },
    { label: 'busCoach', id: 6 },
    { label: 'ferry', id: 7 },
    { label: 'other', id: 8 }
];

export const modeOfDeparture: DropdownItemProps[] = [
    { label: 'sameAsArrival', id: 1 },
    { label: 'notDecidedYet', id: 2 },
    { label: 'airplane', id: 3 },
    { label: 'train', id: 4 },
    { label: 'cruiseShip', id: 5 },
    { label: 'privateTransfer', id: 6 },
    { label: 'busCoach', id: 7 },
    { label: 'ferry', id: 8 },
    { label: 'other', id: 9 }
];

export const visaRequirement: DropdownItemProps[] = [
    { label: 'clientRequiresAssistance', id: 1 },
    { label: 'visaOnArrival', id: 2 },
    { label: 'clientObtainedVisa', id: 3 },
    { label: 'noVisaRequired', id: 4 },
    { label: 'unsure', id: 5 }
];

export const arrivalDeparture: DropdownItemProps[] = [
    { label: 'requiresAssistance', id: 1 },
    { label: 'independentArrival', id: 2 },
    { label: 'unsure', id: 3 }
];

export const spacialCargoEquipment: DropdownItemProps[] = [
    { label: 'none', id: 1 },
    { label: 'liveAnimals', id: 2 },
    { label: 'excessBaggage', id: 3 },
    { label: 'sportsLeisureEquipment', id: 4 },
    { label: 'musicalInstruments', id: 5 },
    { label: 'firearmsAmmunition', id: 6 },
    { label: 'perishables', id: 7 },
    { label: 'divingEquipment', id: 8 },
    { label: 'campingGear', id: 9 },
    { label: 'filmEquipment', id: 10 },
    { label: 'medicalEquipment', id: 11 },
    { label: 'mobilityAids', id: 12 },
    { label: 'oversizedFragileItems', id: 13 },
    { label: 'dangerousGoods', id: 14 },
    { label: 'excessLuggage', id: 15 },
    { label: 'diplomaticCargo', id: 16 },
    { label: 'petsServiceAnimals', id: 17 },
    { label: 'audioVisualEquipment', id: 18 },
    { label: 'electronics', id: 19 },
    { label: 'machinery', id: 20 },
    { label: 'householdGoods', id: 21 },
    { label: 'humanRemains', id: 22 },
    { label: 'otherSpecifyInComments', id: 23 }
];
export const accessibilityRequirements: DropdownItemProps[] = [
    { label: 'none', id: 1 },
    { label: 'mobilityAssistance', id: 2 },
    { label: 'visualImpairment', id: 3 },
    { label: 'hearingImpairment', id: 4 },
    { label: 'lowerFloorsPreferred', id: 5 },
    { label: 'noStairsAccessNeeded', id: 6 },
    { label: 'requiresAccessibleBathroom', id: 7 },
    { label: 'requiresInRoomAssistance', id: 8 },
    { label: 'otherSpecifyInComments', id: 9 }
];

export const dietaryRestrictions: DropdownItemProps[] = [
    { label: 'none', id: 1 },
    { label: 'vegetarian', id: 2 },
    { label: 'vegan', id: 3 },
    { label: 'glutenFree', id: 4 },
    { label: 'dairyFree', id: 5 },
    { label: 'nutFree', id: 6 },
    { label: 'pescatarian', id: 7 },
    { label: 'kosher', id: 8 },
    { label: 'halal', id: 9 },
    { label: 'diabetic', id: 10 },
    { label: 'otherSpecifyInComments', id: 11 }
];

export const allergyRestrictions: DropdownItemProps[] = [
    { label: 'none', id: 1 },
    { label: 'peanutsTreeNuts', id: 2 },
    { label: 'shellfish', id: 3 },
    { label: 'gluten', id: 4 },
    { label: 'dairy', id: 5 },
    { label: 'eggs', id: 6 },
    { label: 'soy', id: 7 },
    { label: 'wheat', id: 8 },
    { label: 'sesame', id: 9 },
    { label: 'fish', id: 10 },
    { label: 'environmentalAllergens', id: 11 },
    { label: 'otherSpecifyInComments', id: 12 }
];

export const typeOfTrip: DropdownItemProps[] = [
    { label: 'beachVacation', id: 1 },
    { label: 'spaWellnessRetreat', id: 2 },
    { label: 'allInclusiveResortStay', id: 3 },
    { label: 'luxuryGetaway', id: 4 },
    { label: 'natureMindfulnessExperience', id: 5 },
    { label: 'wildlifeSafari', id: 6 },
    { label: 'hikingTrekkingAdventure', id: 7 },
    { label: 'backpackingCampingTrip', id: 8 },
    { label: 'whitewaterRaftingKayaking', id: 9 },
    { label: 'offBeatenPathExploration', id: 10 },
    { label: 'historicalCulturalTour', id: 11 },
    { label: 'foodWineTour', id: 12 },
    { label: 'volunteerEcoTourismTrip', id: 13 },
    { label: 'languageImmersionProgram', id: 14 },
    { label: 'festivalCulturalEventExperience', id: 15 },
    { label: 'culinaryTourCookingClass', id: 16 },
    { label: 'photographyWorkshopTour', id: 17 },
    { label: 'cyclingBikingAdventure', id: 18 },
    { label: 'divingSnorkelingTrip', id: 19 },
    { label: 'artArchitectureTour', id: 20 },
    { label: 'romanticGetaway', id: 21 },
    { label: 'familyVacation', id: 22 },
    { label: 'soloTravelAdventure', id: 23 },
    { label: 'soloFemaleTravelExperience', id: 24 },
    { label: 'lgbtqTravelTour', id: 25 },
    { label: 'digitalNomadRetreat', id: 26 },
    { label: 'workationPackageCoWorkingSpace', id: 27 },
    { label: 'luxuryHotelBusinessAmenities', id: 28 },
    { label: 'cityBreakRemoteWorkOptions', id: 29 },
    { label: 'wellnessRetreatRemoteWorkFlexibility', id: 30 },
    { label: 'otherSpecifyInComments', id: 32 }
];

export const travelStyle: DropdownItemProps[] = [
    { label: 'budget', id: 1 },
    { label: 'midRange', id: 2 },
    { label: 'luxury', id: 3 },
    { label: 'allInclusive', id: 4 },
    { label: 'backpacking', id: 5 }
];

export const pointOfReferrals: DropdownItemProps[] = [
    { label: 'whatsapp', id: 1 },
    { label: 'facebook', id: 2 },
    { label: 'phoneCall', id: 3 },
    { label: 'email', id: 4 },
    { label: 'website', id: 5 },
    { label: 'referringPartner', id: 6 },
    { label: 'tradeShow', id: 7 },
];

export const levelOfActivity: DropdownItemProps[] = [
    { label: 'relaxed', id: 1 },
    { label: 'moderate', id: 2 },
    { label: 'active', id: 3 },
    { label: 'adventurous', id: 4 }
];

export const preferredPace: DropdownItemProps[] = [
    { label: 'leisurely', id: 1 },
    { label: 'moderate', id: 2 },
    { label: 'fastPaced', id: 3 },
    { label: 'inDepth', id: 4 }
];

export const preferredLandscapes: DropdownItemProps[] = [
    { label: 'openToAll', id: 1 },
    { label: 'mountainsHikingTrails', id: 2 },
    { label: 'beachesCoastlines', id: 3 },
    { label: 'desertsAridLandscapes', id: 4 },
    { label: 'rainforestsJungles', id: 5 },
    { label: 'lakesRivers', id: 6 },
    { label: 'citiesUrbanCulture', id: 7 },
    { label: 'countrysideRuralCharm', id: 8 },
    { label: 'islandsArchipelagos', id: 9 },
    { label: 'glaciersArcticRegions', id: 10 },
    { label: 'volcanicLandscapesHotSprings', id: 11 },
    { label: 'historicalSitesCulturalLandmarks', id: 12 },
    { label: 'nationalParksWildlifeReserves', id: 13 }
];

export const climatePreferences: DropdownItemProps[] = [
    { label: 'openToAll', id: 1 },
    { label: 'warmSunny', id: 2 },
    { label: 'moderateSpringWeather', id: 3 },
    { label: 'coolRefreshing', id: 4 },
    { label: 'avoidingExtremeHeatHumidity', id: 5 },
    { label: 'avoidingExtremeColdSnow', id: 6 }
];

export const culturalInterests: DropdownItemProps[] = [
    { label: 'ancientHistoryRuins', id: 1 },
    { label: 'medievalHistoryCastles', id: 2 },
    { label: 'worldWarMilitaryHistory', id: 3 },
    { label: 'museumsArtGalleries', id: 4 },
    { label: 'religiousSitesPilgrimage', id: 5 },
    { label: 'classicalRenaissanceArt', id: 6 },
    { label: 'modernContemporaryArt', id: 7 },
    { label: 'streetArtMurals', id: 8 },
    { label: 'architecturalStylesLandmarks', id: 9 },
    { label: 'unescoWorldHeritageSites', id: 10 },
    { label: 'wineCulinaryExperiences', id: 11 },
    { label: 'streetFoodLocalMarkets', id: 12 },
    { label: 'coffeeCultureCafes', id: 13 },
    { label: 'traditionalFestivalsEvents', id: 14 },
    { label: 'cookingClassesFoodTours', id: 15 },
    { label: 'operaBalletClassicalMusic', id: 16 },
    { label: 'theaterLivePerformances', id: 17 },
    { label: 'traditionalMusicDance', id: 18 },
    { label: 'concertsMusicFestivals', id: 19 },
    { label: 'nightlifeEntertainment', id: 20 },
    { label: 'wildlifeWatchingSafaris', id: 21 },
    { label: 'hikingTrekkingAdventures', id: 22 },
    { label: 'nationalParksOutdoorRecreation', id: 23 },
    { label: 'scubaDivingSnorkeling', id: 24 },
    { label: 'ecotourismSustainableTravel', id: 25 },
    { label: 'shoppingLocalCrafts', id: 26 },
    { label: 'relaxationWellnessRetreats', id: 27 },
    { label: 'volunteerProgramsCulturalExchange', id: 28 },
    { label: 'learningNewLanguage', id: 29 },
    { label: 'uniqueCulturalExperiences', id: 30 },
    { label: 'other', id: 31 },
    { label: 'otherSpecifyInComments', id: 32 }
];

export const daysAndNights: DropdownItemProps[] = [
    { label: 'days1', id: 1 },
    { label: 'days2', id: 2 },
    { label: 'days3', id: 3 },
    { label: 'days4', id: 4 },
    { label: 'days5', id: 5 },
    { label: 'days6', id: 6 },
    { label: 'days7', id: 7 },
    { label: 'days8', id: 8 },
    { label: 'days9', id: 9 },
    { label: 'days10', id: 10 },
    { label: 'days11', id: 11 },
    { label: 'days12', id: 12 },
    { label: 'days13', id: 13 },
    { label: 'days14', id: 14 },
    { label: 'days15', id: 15 },
    { label: 'days16', id: 16 },
    { label: 'days17', id: 17 },
    { label: 'days18', id: 18 },
    { label: 'days19', id: 19 },
    { label: 'days20', id: 20 },
    { label: 'days21', id: 21 },
    { label: 'days22', id: 22 },
    { label: 'days23', id: 23 },
    { label: 'days24', id: 24 },
    { label: 'days25', id: 25 },
    { label: 'days26', id: 26 },
    { label: 'days27', id: 27 },
    { label: 'days28', id: 28 },
    { label: 'days29', id: 29 },
    { label: 'days30', id: 30 },
    { label: 'days31', id: 31 },
    { label: 'days32', id: 32 },
    { label: 'days33', id: 33 },
    { label: 'days34', id: 34 },
    { label: 'days35', id: 35 },
    { label: 'days36', id: 36 },
    { label: 'days37', id: 37 },
    { label: 'days38', id: 38 },
    { label: 'days39', id: 39 },
    { label: 'days40', id: 40 },
    { label: 'days41', id: 41 },
    { label: 'days42', id: 42 },
    { label: 'days43', id: 43 },
    { label: 'days44', id: 44 },
    { label: 'days45', id: 45 },
    { label: 'days46', id: 46 },
    { label: 'days47', id: 47 },
    { label: 'days48', id: 48 },
    { label: 'days49', id: 49 },
    { label: 'days50', id: 50 },
    { label: 'days51', id: 51 },
    { label: 'days52', id: 52 },
    { label: 'days53', id: 53 },
    { label: 'days54', id: 54 },
    { label: 'days55', id: 55 },
    { label: 'days56', id: 56 },
    { label: 'days57', id: 57 },
    { label: 'days58', id: 58 },
    { label: 'days59', id: 59 },
    { label: 'days60', id: 60 },
    { label: 'days61', id: 61 },
    { label: 'days62', id: 62 },
    { label: 'days63', id: 63 },
    { label: 'days64', id: 64 },
    { label: 'days65', id: 65 },
    { label: 'days66', id: 66 },
    { label: 'days67', id: 67 },
    { label: 'days68', id: 68 },
    { label: 'days69', id: 69 },
    { label: 'days70', id: 70 },
    { label: 'days71', id: 71 },
    { label: 'days72', id: 72 },
    { label: 'days73', id: 73 },
    { label: 'days74', id: 74 },
    { label: 'days75', id: 75 },
    { label: 'days76', id: 76 },
    { label: 'days77', id: 77 },
    { label: 'days78', id: 78 },
    { label: 'days79', id: 79 },
    { label: 'days80', id: 80 },
    { label: 'days81', id: 81 },
    { label: 'days82', id: 82 },
    { label: 'days83', id: 83 },
    { label: 'days84', id: 84 },
    { label: 'days85', id: 85 },
    { label: 'days86', id: 86 },
    { label: 'days87', id: 87 },
    { label: 'days88', id: 88 },
    { label: 'days89', id: 89 },
    { label: 'days90', id: 90 }
];

export const portOfArrivalDeparture: DropdownItemProps[] = [
    // Major International Airports
    { label: "Hartsfield-Jackson Atlanta International Airport (ATL)", id: 1 },
    { label: "Los Angeles International Airport (LAX)", id: 2 },
    { label: "Chicago O'Hare International Airport (ORD)", id: 3 },
    { label: "Dallas/Fort Worth International Airport (DFW)", id: 4 },
    { label: "Denver International Airport (DEN)", id: 5 },
    { label: "John F. Kennedy International Airport (JFK)", id: 6 },
    { label: "San Francisco International Airport (SFO)", id: 7 },
    { label: "Seattle-Tacoma International Airport (SEA)", id: 8 },
    { label: "Toronto Pearson International Airport (YYZ)", id: 9 },
    { label: "Vancouver International Airport (YVR)", id: 10 },
    { label: "Mexico City International Airport (MEX)", id: 11 },
    { label: "São Paulo-Guarulhos International Airport (GRU)", id: 12 },
    { label: "El Dorado International Airport (BOG)", id: 13 },
    { label: "Jorge Chávez International Airport (LIM)", id: 14 },
    { label: "Arturo Merino Benítez International Airport (SCL)", id: 15 },
    { label: "Ministro Pistarini International Airport (EZE)", id: 16 },
    { label: "Heathrow Airport (LHR)", id: 17 },
    { label: "Charles de Gaulle Airport (CDG)", id: 18 },
    { label: "Frankfurt Airport (FRA)", id: 19 },
    { label: "Amsterdam Schiphol Airport (AMS)", id: 20 },
    { label: "Madrid Barajas Airport (MAD)", id: 21 },
    { label: "Istanbul Airport (IST)", id: 22 },
    { label: "Munich Airport (MUC)", id: 23 },
    { label: "Rome Fiumicino Airport (FCO)", id: 24 },
    { label: "Zurich Airport (ZRH)", id: 25 },
    { label: "Copenhagen Airport (CPH)", id: 26 },
    { label: "Lisbon Portela Airport (LIS)", id: 27 },
    { label: "Vienna International Airport (VIE)", id: 28 },
    { label: "Dublin Airport (DUB)", id: 29 },
    { label: "Oslo Gardermoen Airport (OSL)", id: 30 },
    { label: "Helsinki-Vantaa Airport (HEL)", id: 31 },
    { label: "Brussels Airport (BRU)", id: 32 },
    { label: "Stockholm Arlanda Airport (ARN)", id: 33 },
    { label: "Athens International Airport (ATH)", id: 34 },
    { label: "Warsaw Chopin Airport (WAW)", id: 35 },
    { label: "Beijing Capital International Airport (PEK)", id: 36 },
    { label: "Tokyo Haneda Airport (HND)", id: 37 },
    { label: "Singapore Changi Airport (SIN)", id: 38 },
    { label: "Hong Kong International Airport (HKG)", id: 39 },
    { label: "Dubai International Airport (DXB)", id: 40 },
    { label: "Seoul Incheon International Airport (ICN)", id: 41 },
    { label: "Bangkok Suvarnabhumi Airport (BKK)", id: 42 },
    { label: "Shanghai Pudong International Airport (PVG)", id: 43 },
    { label: "Kuala Lumpur International Airport (KUL)", id: 44 },
    { label: "Delhi Indira Gandhi International Airport (DEL)", id: 45 },
    { label: "Mumbai Chhatrapati Shivaji Maharaj International Airport (BOM)", id: 46 },
    { label: "Jakarta Soekarno-Hatta International Airport (CGK)", id: 47 },
    { label: "Manila Ninoy Aquino International Airport (MNL)", id: 48 },
    { label: "Sydney Kingsford Smith Airport (SYD)", id: 49 },
    { label: "Auckland Airport (AKL)", id: 50 },
    { label: "Brisbane Airport (BNE)", id: 51 },
    { label: "Melbourne Airport (MEL)", id: 52 },
    { label: "Perth Airport (PER)", id: 53 },
    { label: "O. R. Tambo International Airport (JNB)", id: 54 },
    { label: "Cairo International Airport (CAI)", id: 55 },
    { label: "Cape Town International Airport (CPT)", id: 56 },
    { label: "Mohammed V International Airport (CMN)", id: 57 },
    { label: "Murtala Muhammed International Airport (LOS)", id: 58 },
    { label: "Adolfo Suárez Madrid-Barajas Airport (MAD)", id: 59 },
    { label: "Barcelona El Prat Airport (BCN)", id: 60 },
    { label: "Milan Malpensa Airport (MXP)", id: 61 },
    { label: "Brussels South Charleroi Airport (CRL)", id: 62 },
    { label: "London Gatwick Airport (LGW)", id: 63 },
    { label: "Manchester Airport (MAN)", id: 64 },
    { label: "Berlin Tegel Airport (TXL)", id: 65 },
    { label: "Munich Airport (MUC)", id: 66 },
    { label: "Hamburg Airport (HAM)", id: 67 },
    { label: "Vienna International Airport (VIE)", id: 68 },
    { label: "Zurich Airport (ZRH)", id: 69 },
    { label: "Geneva Airport (GVA)", id: 70 },
    { label: "Lisbon Airport (LIS)", id: 71 },
    { label: "Warsaw Chopin Airport (WAW)", id: 72 },
    { label: "Stockholm Arlanda Airport (ARN)", id: 73 },
    { label: "Copenhagen Airport (CPH)", id: 74 },
    { label: "Helsinki Airport (HEL)", id: 75 },
    { label: "Oslo Airport (OSL)", id: 76 },
    { label: "Athens International Airport (ATH)", id: 77 },
    { label: "Dublin Airport (DUB)", id: 78 },
    { label: "Edinburgh Airport (EDI)", id: 79 },
    { label: "Istanbul Atatürk Airport (IST)", id: 80 },
    { label: "Moscow Sheremetyevo Airport (SVO)", id: 81 },
    { label: "St. Petersburg Pulkovo Airport (LED)", id: 82 },
    { label: "Prague Václav Havel Airport (PRG)", id: 83 },
    { label: "Budapest Ferenc Liszt International Airport (BUD)", id: 84 },
    { label: "Sofia Airport (SOF)", id: 85 },
    { label: "Bucharest Henri Coandă International Airport (OTP)", id: 86 },
    { label: "Belgrade Nikola Tesla Airport (BEG)", id: 87 },
    { label: "Zagreb Airport (ZAG)", id: 88 },
    { label: "Ljubljana Jože Pučnik Airport (LJU)", id: 89 },
    { label: "Sarajevo International Airport (SJJ)", id: 90 },
    { label: "Skopje International Airport (SKP)", id: 91 },
    { label: "Tirana International Airport (TIA)", id: 92 },
    { label: "Podgorica Airport (TGD)", id: 93 },
    { label: "Pristina International Airport (PRN)", id: 94 },
    { label: "Tallinn Airport (TLL)", id: 95 },
    { label: "Riga International Airport (RIX)", id: 96 },
    { label: "Vilnius Airport (VNO)", id: 97 },
    { label: "Minsk National Airport (MSQ)", id: 98 },
    { label: "Kyiv Boryspil International Airport (KBP)", id: 99 },
    { label: "Chisinau International Airport (KIV)", id: 100 },

    // Continue adding seaports, land border crossings, and more airports from various continents
    { label: "Port of Shanghai", id: 101 },
    { label: "Port of Singapore", id: 102 },
    { label: "Port of Rotterdam", id: 103 },
    { label: "Port of Hong Kong", id: 104 },
    { label: "Port of Los Angeles", id: 105 },
    { label: "Port of Antwerp", id: 106 },
    { label: "Port of Hamburg", id: 107 },
    { label: "Port of Dubai (Jebel Ali)", id: 108 },
    { label: "Port of Busan", id: 109 },
    { label: "Port of Santos", id: 110 },
    { label: "Port of New York and New Jersey", id: 111 },
    { label: "Port of Vancouver", id: 112 },
    { label: "Port of Miami", id: 113 },
    { label: "Port of Houston", id: 114 },
    { label: "Port of Buenos Aires", id: 115 },
    { label: "Port of Cartagena", id: 116 },
    { label: "Port of Valparaíso", id: 117 },
    { label: "Port of Callao", id: 118 },
    { label: "Port of Barcelona", id: 119 },
    { label: "Port of Marseille", id: 120 },
    { label: "Port of Durban", id: 121 },
    { label: "Port of Cape Town", id: 122 },
    { label: "Port of Lagos", id: 123 },
    { label: "Port of Mombasa", id: 124 },
    { label: "Port of Alexandria", id: 125 },
    { label: "Port of Melbourne", id: 126 },
    { label: "Port of Sydney", id: 127 },
    { label: "Port of Auckland", id: 128 },
    { label: "Port of Brisbane", id: 129 },
    { label: "Port of Fremantle", id: 130 },

    // Sample Land Border Crossings
    { label: "US-Mexico Border (San Ysidro)", id: 131 },
    { label: "US-Canada Border (Buffalo)", id: 132 },
    { label: "China-Hong Kong Border (Lo Wu)", id: 133 },
    { label: "Germany-Austria Border (Salzburg)", id: 134 },
    { label: "France-Spain Border (La Jonquera)", id: 135 },
    { label: "Russia-Finland Border (Vaalimaa)", id: 136 },
    { label: "Argentina-Chile Border (Los Libertadores)", id: 137 },
    { label: "Brazil-Argentina Border (Iguazu Falls)", id: 138 },
    { label: "Italy-Switzerland Border (Chiasso)", id: 139 },
    { label: "UK-France Border (Dover-Calais)", id: 140 },
    { label: "South Africa-Botswana Border (Tlokweng Gate)", id: 141 },
    { label: "Thailand-Malaysia Border (Padang Besar)", id: 142 },

    // Continue adding more ports and border crossings...
    { label: "Munich International Airport (MUC)", id: 143 },
    { label: "Berlin Tegel Airport (TXL)", id: 144 },
    { label: "Hamburg Airport (HAM)", id: 145 },
    { label: "Düsseldorf International Airport (DUS)", id: 146 },
    { label: "Cologne Bonn Airport (CGN)", id: 147 },
    { label: "Hannover Airport (HAJ)", id: 148 },
    { label: "Stuttgart Airport (STR)", id: 149 },
    { label: "Nuremberg Airport (NUE)", id: 150 },
    { label: "Leipzig/Halle Airport (LEJ)", id: 151 },
    { label: "Dresden Airport (DRS)", id: 152 },
    { label: "Zagreb Franjo Tuđman Airport (ZAG)", id: 153 },
    { label: "Split Airport (SPU)", id: 154 },
    { label: "Dubrovnik Airport (DBV)", id: 155 },
    { label: "Rijeka Airport (RJK)", id: 156 },
    { label: "Zadar Airport (ZAD)", id: 157 },
    { label: "Pula Airport (PUY)", id: 158 },
    { label: "Osijek Airport (OSI)", id: 159 },
    { label: "Helsinki-Vantaa Airport (HEL)", id: 160 },
    { label: "Oulu Airport (OUL)", id: 161 },
    { label: "Turku Airport (TKU)", id: 162 },
    { label: "Tampere-Pirkkala Airport (TMP)", id: 163 },
    { label: "Rovaniemi Airport (RVN)", id: 164 },
    { label: "Ivalo Airport (IVL)", id: 165 },
    { label: "Kuopio Airport (KUO)", id: 166 },
    { label: "Vaasa Airport (VAA)", id: 167 },
    { label: "Kittilä Airport (KTT)", id: 168 },
    { label: "Berlin Brandenburg Airport (BER)", id: 169 },
    { label: "Bremen Airport (BRE)", id: 170 },
    { label: "Frankfurt-Hahn Airport (HHN)", id: 171 },
    { label: "Heringsdorf Airport (HDF)", id: 172 },
    { label: "Karlsruhe/Baden-Baden Airport (FKB)", id: 173 },
    { label: "Memmingen Airport (FMM)", id: 174 },
    { label: "Munster Osnabruck International Airport (FMO)", id: 175 },
    { label: "Saarbrucken Airport (SCN)", id: 176 },
    { label: "Sylt Airport (GWT)", id: 177 },
    { label: "Westerland Airport (GWT)", id: 178 },
    { label: "Vilnius International Airport (VNO)", id: 179 },
    { label: "Kaunas Airport (KUN)", id: 180 },
    { label: "Palanga International Airport (PLQ)", id: 181 },
    { label: "Šiauliai International Airport (SQQ)", id: 182 },
    { label: "Panevėžys Air Base (PNV)", id: 183 },
    { label: "Kaunas Air Base (KUN)", id: 184 },
    { label: "Klaipėda Airport (KLJ)", id: 185 },
    { label: "Warsaw Modlin Airport (WMI)", id: 186 },
    { label: "Gdańsk Lech Wałęsa Airport (GDN)", id: 187 },
    { label: "Kraków John Paul II International Airport (KRK)", id: 188 },
    { label: "Katowice Airport (KTW)", id: 189 },
    { label: "Wrocław Copernicus Airport (WRO)", id: 190 },
    { label: "Poznań–Ławica Airport (POZ)", id: 191 },
    { label: "Rzeszów–Jasionka Airport (RZE)", id: 192 },
    { label: "Szczecin–Goleniów Airport (SZZ)", id: 193 },
    { label: "Łódź Władysław Reymont Airport (LCJ)", id: 194 },
    { label: "Bydgoszcz Ignacy Jan Paderewski Airport (BZG)", id: 195 },
    { label: "Lublin Airport (LUZ)", id: 196 },
    { label: "Zielona Góra Airport (IEG)", id: 197 },
    { label: "Riga International Airport (RIX)", id: 198 },
    { label: "Liepāja International Airport (LPX)", id: 199 },
    { label: "Ventspils International Airport (VNT)", id: 200 },
    { label: "Aéroport de la Réunion Roland Garros (RUN)", id: 201 },
    { label: "Pointe-à-Pitre International Airport  (PTP)", id: 202 }
];

export const leadStage: ILeadStageLocalization[] = [
    {
        key: "NEW_LEAD",
        color: "#FFD600"
    },
    {
        key: "PROPOSAL",
        color: "#E27C37"
    },
    {
        key: "FOLLOW-UP",
        color: "#E27C37"
    },
    {
        key: "ACCEPTED",
        color: "#75D2B8"
    },
    {
        key: "QUOTE_SENT",
        color: "#75D2B8"
    },
    {
        key: "TRIP_PLANING",
        color: "#134B5F"
    },
    {
        key: "GUEST_WELCOMING",
        color: "#134B5F"
    },
    {
        key: "ON_GOING_TRAVEL",
        color: "#75D2B8"
    },
    {
        key: "COMPLETED",
        color: "#2BA74E"
    },
    {
        key: "ON_HOLD",
        color: "#FFD600"
    },
    {
        key: "LOST",
        color: "#F43131"
    }
];


export const leadTrackerStages = ['NEW_LEAD', 'PROPOSAL', 'QUOTE_SENT', 'FOLLOW-UP', 'ACCEPTED', 'TRIP_PLANING', 'GUEST_WELCOMING', 'ON_GOING_TRAVEL', 'COMPLETED', 'ON_HOLD', 'LOST'] as any;
export const taskStages = ['COMPLETED', "IN-PROGRESS", 'PENDING'] as any;
