import React from 'react'

const DocumentIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.5 5.5C10.1022 5.5 9.72064 5.34196 9.43934 5.06066C9.15804 4.77936 9 4.39782 9 4V0.5H3.5C3.10218 0.5 2.72064 0.658035 2.43934 0.93934C2.15804 1.22064 2 1.60218 2 2V14C2 14.3978 2.15804 14.7794 2.43934 15.0607C2.72064 15.342 3.10218 15.5 3.5 15.5H12.5C12.8978 15.5 13.2794 15.342 13.5607 15.0607C13.842 14.7794 14 14.3978 14 14V5.5H10.5Z" fill="#251C15" />
            <path d="M10 4.00131C10 4.13392 10.0527 4.2611 10.1464 4.35487C10.2402 4.44863 10.3674 4.50131 10.5 4.50131H13.681C13.6425 4.45131 10.05 0.858812 10 0.820312V4.00131Z" fill="#251C15" />
        </svg>
    )
}

export default DocumentIcon