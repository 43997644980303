import { useRef } from 'react';
import { StandaloneSearchBox } from '@react-google-maps/api';
import { Skeleton, TextField, TextFieldProps } from '@mui/material';
import '../Mui/CustomTextField/CustomTextField.scss';
import '../Mui/MuiBaseStyle.scss'

const GoogleSearchBox = (props: TextFieldProps) => {
  const searchBoxRef = useRef<google.maps.places.SearchBox | null>(null);

  const onPlacesChanged = () => {
    if (searchBoxRef.current) {
      const places = searchBoxRef.current.getPlaces();
      if (places && places.length > 0) {
        const place = places[0];
        if (props.onSelect) {
          props.onSelect(place as any);
        }
      }
    }
  };

  const onLoad = (ref: google.maps.places.SearchBox) => {
    searchBoxRef.current = ref;
  };

  return (
    <div style={{ zIndex: '1000' }}>
      <StandaloneSearchBox
        onLoad={onLoad}
        onPlacesChanged={onPlacesChanged}
      >
        <TextField classes={{ root: 'custom-mui-field custom-text-field ' }} fullWidth={props.fullWidth !== false} required={props.required} select={props.select} defaultValue={props.defaultValue} variant='outlined' margin='normal'
          helperText={props.error ? props.helperText : null} size={props.size} placeholder={props.placeholder} label={props.label} type={props.type} value={props.value} disabled={props.disabled}
          error={props.error} onChange={props.onChange} onFocus={props.onFocus} onBlur={props.onBlur} inputProps={props.inputProps} InputProps={props.InputProps} multiline={props.multiline} rows={props.rows} >
          {props.children}
        </TextField>
      </StandaloneSearchBox>
    </div>

  );
};

export default GoogleSearchBox;
