import { ReactElement, useRef, useState } from "react"

type LeadStageProps = {
    children?: ReactElement[] | ReactElement,
    cardCount?: number,
    label: string,
    borderStyle?: string
}

const LeadStage = ({ children, cardCount, label, borderStyle }: LeadStageProps) => {
    const leadCardOuterRef = useRef(null) as any;
    const [isDown, setIsDown] = useState(false);
    const [startY, setStartY] = useState(0);
    const [scrollTop, setScrollTop] = useState(0);

    const handleMouseDown = (e: any) => {
        setIsDown(true);
        leadCardOuterRef.current.classList.add('active');
        setStartY(e.pageY - leadCardOuterRef.current.offsetTop);
        setScrollTop(leadCardOuterRef.current.scrollTop);
    };

    const handleMouseLeave = () => {
        setIsDown(false);
        leadCardOuterRef.current.classList.remove('active');
    };

    const handleMouseUp = () => {
        setIsDown(false);
        leadCardOuterRef.current.classList.remove('active');
    };

    const handleMouseMove = (e: any) => {
        if (!isDown) return;
        e.preventDefault();
        const y = e.pageY - leadCardOuterRef.current.offsetTop;
        const walk = (y - startY);
        leadCardOuterRef.current.scrollTop = scrollTop - walk;
    };
    return (
        <div className="cb-inner-row">
            <div style={{ borderBottomColor: borderStyle }} className={`cb-inner-row-top }`}>
                <div className="lft">
                    <span className="title fz-16 primary-font ttu">{label}</span>
                </div>
                {(cardCount)
                    ? ((cardCount > 0) && (<div className="rht">
                        <span className="count">{cardCount}</span>
                    </div>)
                    )
                    : (<></>)}
            </div>
            <div
                className="lead-card-outer"
                ref={leadCardOuterRef}
                onMouseDown={handleMouseDown}
                onMouseLeave={handleMouseLeave}
                onMouseUp={handleMouseUp}
                onMouseMove={handleMouseMove}
            >
                {children}
            </div>
        </div>
    )
}

export default LeadStage