import React, { useEffect, useState } from "react";
import Dashboard from "../../../components/Dashboard/Dashboard";
import SecondaryButton from "../../../components/SecondaryButton/SecondaryButton";
import TransparentButton from "../../../components/TransparentButton/TransparentButton";
import LeadStage from "../../../components/LeadStage/LeadStage";
import LeadCard from "../../../components/LeadCard/LeadCard";
import NewLead from "../../../components/NewLead/NewLead";
import ViewLead from "../../../components/ViewLead/ViewLead";
import SearchableDropdown from "../../../components/SerchableDropdown/SearchableDropdown";
import ScrollController from "../../../helpers/LeadScrollController";
import Functions from "./LeadFunctions";
import ViewLeadFunctions from "../../../components/ViewLead/ViewLeadFunctions";
import { getLeadStage } from "../../../helpers/helperfunctions";
import { getUserId } from "../../../helpers/storageConfig";
import { Box, CircularProgress, Skeleton } from "@mui/material";
import { ICreateLeadUser, ILead, ISortedLeads } from "../../../helpers/Interfaces";
import { DropdownItemProps } from "../../../components/Mui/CustomAutocomplete/CustomAutocomplete";
import PluseSvg from "../../../assets/images/buttons/PluseSvg";
import BoardSvg from "../../../assets/images/buttons/BoardSvg";
import UserFilter from "../../../assets/images/dashboard/UserFilter";
import FilterIcon from "../../../assets/images/dashboard/FilterIcon";
import UserFilterRemove from "../../../assets/images/dashboard/UserFilterRemove";
import FilterIconRemove from "../../../assets/images/dashboard/FilterIconRemove";
import "./LeadTracker.scss";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

const LeadTracker = () => {
  const [openNewLead, setOpenNewLead] = useState(false);
  const [viewNewLead, setOpenViewLead] = useState(false);
  const [selectedLead, setSelectedLead] = useState<ILead>({
    user: { name: '', phoneNumber: '', email: '', nationality: '' },
    stage: 'NEW_LEAD',
  });
  const [leads, setLeads] = useState<ISortedLeads>({ sortedLeads: [] });
  const [getLeadLoading, setGetLeadLoading] = useState(false);
  const [organizationUsers, setOrganizationUsers] = useState<ICreateLeadUser[]>([]);
  const [users, setUsers] = useState<DropdownItemProps[]>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [filterUserId, setFilterUserId] = useState<string | undefined>();
  const [filterLead, setFilterLead] = useState({ user: false, other: false });
  const leadStages = useSelector((state: RootState) => state.leadStages)

  const scrollController = new ScrollController(false, null, null);
  const functions = new Functions();
  const viewLeadFunctions = new ViewLeadFunctions();

  useEffect(() => {
    functions.getAllLeads(setLeads, setGetLeadLoading);
    functions.getAllUser(setUsers);
    functions.getAllOrganizationUsers(setOrganizationUsers);
  }, []);

  const toggleNewLead = (newOpen: boolean) => {
    setOpenNewLead(newOpen);
    if (!newOpen) {
      resetSelectedLead();
    }
  };

  const toggleViewLead = (newOpen: boolean, lead?: ILead) => {
    setSelectedLead(lead || getDefaultLead());
    setOpenViewLead(newOpen);
  };

  const resetSelectedLead = () => {
    setSelectedLead(getDefaultLead());
  };

  const getDefaultLead = (): ILead => ({
    user: { name: '', phoneNumber: '', email: '', nationality: '' },
    stage: 'NEW_LEAD',
  });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, lead?: ILead) => {
    setSelectedLead(lead || selectedLead);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDropDown = () => {
    setAnchorEl(null);
    resetSelectedLead();
  };

  const openDropdown = Boolean(anchorEl);

  const defaultAssignees: DropdownItemProps[] = selectedLead.assignees
    ? selectedLead.assignees.map((t) => ({ id: t.id, label: t.name }))
    : [];

  const userFilter = (userId?: string, clear?: boolean) => {
    if (clear ?? false) {
      setFilterUserId(undefined);
      return setFilterLead({ user: false, other: false });
    }

    const id = userId ?? getUserId();
    setFilterUserId(id);
    setFilterLead({ user: !userId, other: !!userId });
  };

  const dropdownUsers = selectedLead.id ? users : users.filter(u => u.id !== getUserId());

  return (
    <Dashboard headerTitle={t("leadTracker")}>
      <div className="outer-content">
        <div className="content-top">
          <div className="lft">
            <div className="lft-inner">
              <div className="view-styles bdr-10">
                <TransparentButton active icon={<BoardSvg />} label={t('board')} onClick={() => { }} />
              </div>
            </div>
          </div>
          <div className="rht">
            <div className="content-right">
              <div className="filter-outer">
                <span onClick={() => userFilter(undefined, filterLead.user)}>
                  {filterLead.user ? <UserFilterRemove /> : <UserFilter />}
                </span>
                <span onClick={(event: any) => (filterLead.other ? userFilter(undefined, true) : handleClick(event))}>
                  {filterLead.other ? <FilterIconRemove /> : <FilterIcon />}
                </span>
              </div>
              <SecondaryButton label={t('addNew')} icon={<PluseSvg />} onClick={() => toggleNewLead(true)} />
            </div>
          </div>
        </div>

        <div
          className="content-bottom"
          aria-hidden
          onMouseDown={scrollController.handleOnMouseDown}
          onMouseLeave={scrollController.handleOnMouseLeave}
          onMouseUp={scrollController.handleOnMouseUp}
          onMouseMove={scrollController.handleOnMouseMove}
        >
          {getLeadLoading ? (
            <div className="c-orange" style={{ height: '70vh' }}>
              <Box sx={{ display: 'flex', width: '100%', gap: 'clamp(0.625rem, 0.78125vw, 0.9375rem)' }}>
                <Box minWidth={'260px'} >
                  <Skeleton variant="rounded" height={'50px'} sx={{ marginBottom: 'clamp(0.625rem, 0.625vw, 0.75rem)' }} />
                  <Skeleton variant="rounded" height={'157px'} sx={{ marginBottom: 'clamp(0.75rem, 0.78125vw, 0.9375rem)' }} />
                  <Skeleton variant="rounded" height={'157px'} sx={{ marginBottom: 'clamp(0.75rem, 0.78125vw, 0.9375rem)' }} />
                  <Skeleton variant="rounded" height={'157px'} />
                </Box>
                <Box minWidth={'260px'} >
                  <Skeleton variant="rounded" height={'50px'} sx={{ marginBottom: 'clamp(0.625rem, 0.625vw, 0.75rem)' }} />
                  <Skeleton variant="rounded" height={'157px'} sx={{ marginBottom: 'clamp(0.75rem, 0.78125vw, 0.9375rem)' }} />
                </Box>
                {Array.from({ length: 9 }, (_, index) => (
                  <Box key={index} minWidth={'260px'} >
                    <Skeleton variant="rounded" height={'50px'} sx={{ marginBottom: 'clamp(0.625rem, 0.625vw, 0.75rem)' }} />
                  </Box>
                ))}
              </Box>
              {/* <CircularProgress color="inherit" size="26px" /> */}
            </div>
          ) : (
            <div id="slider" className="cb-inner">
              {leadStages.map((stage) => {
                const leadStage = getLeadStage(stage);
                const sortedLeads: ILead[] = leads.sortedLeads[stage] ?? [];
                const filteredLeads = filterUserId
                  ? sortedLeads.filter((t) =>
                    t.assignees ? t.assignees.some((a) => a.id === filterUserId) : false
                  )
                  : sortedLeads;

                return (
                  <LeadStage borderStyle={leadStage?.color} key={stage} cardCount={filteredLeads.length} label={t(stage)} >
                    {filteredLeads.map((lead) => (<LeadCard key={lead.id} selectAssignee={(event) => handleClick(event, lead)}
                      onPress={() => toggleViewLead(true, lead)} lead={lead} />
                    ))}
                  </LeadStage>
                );
              })}
            </div>
          )}
        </div>

        <SearchableDropdown selectedItems={defaultAssignees} initialDetails={dropdownUsers} id={openDropdown ? 'simple-popover' : undefined}
          open={openDropdown} anchorEl={anchorEl} handleClose={handleCloseDropDown}
          onSelect={(val) => {
            handleCloseDropDown();
            if (selectedLead.id) {
              viewLeadFunctions.addLeadAssignee(
                selectedLead,
                () => setLeads({ ...leads }),
                val,
                () => {
                  functions.getAllUser(setUsers);
                }
              );
            } else {
              userFilter(val.id.toString());
            }
          }} />

        <NewLead organizationUsers={organizationUsers} leadData={selectedLead} open={openNewLead} close={() => toggleNewLead(false)}
          refresh={(val: boolean) => {
            if (val) {
              functions.getAllLeads(setLeads);
            }
            functions.getAllOrganizationUsers(setOrganizationUsers);
          }} />

        <ViewLead users={users} open={viewNewLead} close={() => toggleViewLead(false)} lead={selectedLead}
          editLead={() => {
            setOpenViewLead(false);
            setOpenNewLead(true);
          }} refresh={() => functions.getAllLeads(setLeads)} />
      </div>
    </Dashboard >
  );
};

export default LeadTracker;
