const MarginsIcon = () => {
    return (<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_7220_11579)">
            <path d="M14 15.5H9C8.86739 15.5 8.74021 15.4473 8.64645 15.3536C8.55268 15.2598 8.5 15.1326 8.5 15C8.5 14.8674 8.55268 14.7402 8.64645 14.6464C8.74021 14.5527 8.86739 14.5 9 14.5H14C14.1326 14.4998 14.2596 14.4471 14.3534 14.3534C14.4471 14.2596 14.4998 14.1326 14.5 14V2C14.4998 1.86744 14.4471 1.74037 14.3534 1.64664C14.2596 1.55291 14.1326 1.50017 14 1.5H2C1.86744 1.50017 1.74037 1.55291 1.64664 1.64664C1.55291 1.74037 1.50017 1.86744 1.5 2V7C1.5 7.13261 1.44732 7.25979 1.35355 7.35355C1.25979 7.44732 1.13261 7.5 1 7.5C0.867392 7.5 0.740215 7.44732 0.646447 7.35355C0.552678 7.25979 0.5 7.13261 0.5 7V2C0.500437 1.60231 0.658612 1.22103 0.939822 0.939822C1.22103 0.658612 1.60231 0.500437 2 0.5H14C14.3977 0.500437 14.779 0.658612 15.0602 0.939822C15.3414 1.22103 15.4996 1.60231 15.5 2V14C15.4996 14.3977 15.3414 14.779 15.0602 15.0602C14.779 15.3414 14.3977 15.4996 14 15.5Z" fill="#251C15" />
            <path d="M6 8.5H2C1.17157 8.5 0.5 9.17157 0.5 10V14C0.5 14.8284 1.17157 15.5 2 15.5H6C6.82843 15.5 7.5 14.8284 7.5 14V10C7.5 9.17157 6.82843 8.5 6 8.5Z" fill="#251C15" />
            <path d="M12.9603 3.3094C12.9217 3.21887 12.8576 3.14146 12.776 3.08655C12.6943 3.03164 12.5984 3.00158 12.5 3H10C9.86741 3 9.74023 3.05268 9.64646 3.14645C9.5527 3.24021 9.50002 3.36739 9.50002 3.5C9.50002 3.63261 9.5527 3.75979 9.64646 3.85355C9.74023 3.94732 9.86741 4 10 4H11.293L8.14652 7.1465C8.10007 7.19291 8.06322 7.24801 8.03808 7.30867C8.01294 7.36933 8 7.43434 8 7.5C8 7.56566 8.01294 7.63067 8.03808 7.69133C8.06322 7.75199 8.10007 7.80709 8.14652 7.8535C8.24108 7.94572 8.36793 7.99734 8.50002 7.99734C8.6321 7.99734 8.75896 7.94572 8.85352 7.8535L12 4.70705V6C12 6.13261 12.0527 6.25979 12.1465 6.35355C12.2402 6.44732 12.3674 6.5 12.5 6.5C12.6326 6.5 12.7598 6.44732 12.8536 6.35355C12.9473 6.25979 13 6.13261 13 6V3.5C12.9999 3.43442 12.9864 3.36956 12.9603 3.3094Z" fill="#251C15" />
        </g>
        <defs>
            <clipPath id="clip0_7220_11579">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
    )
}

export default MarginsIcon