import React from 'react'

const OpacityIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.87891 1.02278C9.12053 1.05728 9.36878 0.88175 9.40169 0.615375C9.43372 0.358562 9.25153 0.124187 8.99428 0.0925938C8.66834 0.0523125 8.336 0 8 0C3.60638 0 0 3.60594 0 8C0 12.3941 3.60638 16 8 16C8.336 16 8.66834 15.9477 8.99425 15.9074C9.2515 15.8758 9.43369 15.6414 9.40166 15.3846C9.36963 15.1278 9.12975 14.942 8.87887 14.9772C8.74472 14.9939 8.60537 14.9935 8.46872 15.0024V0.997531C8.60541 1.0065 8.74472 1.00612 8.87891 1.02278Z" fill="#134B5F" />
            <path d="M15.8991 6.94992C15.8643 6.69267 15.6254 6.5132 15.3727 6.54707C15.1154 6.58095 14.9351 6.8167 14.9689 7.07351C15.0101 7.37839 15.0303 7.69151 15.0303 8.0037C15.0303 8.31176 15.0101 8.61801 14.9708 8.91464C14.9338 9.19457 15.1513 9.44473 15.4368 9.44473C15.6684 9.44473 15.8689 9.27307 15.901 9.03732C15.9449 8.70042 15.999 8.35251 15.999 8.0037C15.999 7.65029 15.9449 7.29601 15.8991 6.94992Z" fill="#134B5F" />
            <path d="M10.6586 1.48808C11.2271 1.72061 11.7645 2.02871 12.2562 2.40315C12.4595 2.55899 12.7561 2.52096 12.9126 2.31433C13.0701 2.10833 13.0298 1.81399 12.8238 1.65699C12.2681 1.23311 11.6583 0.884271 11.0138 0.620146C10.7776 0.524021 10.5002 0.637084 10.4022 0.87649C10.3043 1.1159 10.4187 1.38968 10.6586 1.48808Z" fill="#134B5F" />
            <path d="M15.1088 10.434C14.8726 10.3356 14.5961 10.4482 14.4963 10.6876C14.261 11.2552 13.9507 11.7917 13.5735 12.2806C13.416 12.4857 13.4545 12.78 13.6595 12.938C13.864 13.0962 14.159 13.0577 14.3169 12.8528C14.7435 12.298 15.0951 11.6906 15.3624 11.0465C15.4613 10.8075 15.3478 10.5333 15.1088 10.434Z" fill="#134B5F" />
            <path d="M12.2717 13.5809C11.7809 13.9567 11.2444 14.2657 10.6768 14.4992C10.4379 14.5976 10.3234 14.8718 10.4223 15.1112C10.519 15.3471 10.7897 15.4654 11.0338 15.3662C11.6775 15.1011 12.2854 14.7509 12.8411 14.3252C13.0471 14.1677 13.0855 13.8734 12.9281 13.6679C12.7715 13.4623 12.4776 13.4239 12.2717 13.5809Z" fill="#134B5F" />
            <path d="M14.4913 5.29647C14.592 5.538 14.8694 5.64713 15.1038 5.54825C15.3427 5.44847 15.4553 5.17382 15.3555 4.93485C15.0864 4.29079 14.7339 3.68422 14.3064 3.13172C14.147 2.92572 13.8523 2.8891 13.649 3.0475C13.444 3.20588 13.4064 3.50022 13.5648 3.70485C13.942 4.19282 14.2532 4.72838 14.4913 5.29647Z" fill="#134B5F" />
        </svg>

    )
}

export default OpacityIcon