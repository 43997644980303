import React, { MouseEventHandler } from 'react'

const SplitPageIcon = ({ selected, onClick, removeBorder }: { selected?: boolean, onClick: MouseEventHandler<SVGSVGElement>, removeBorder?: boolean }) => {
    return (<svg style={{ cursor: 'pointer' }} onClick={onClick} width="143" height="120" viewBox="0 0 143 120" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="143" height="120" rx="10" fill="white" />
        <rect x="0.5" y="0.5" width="142" height="119" rx="9.5" fill={selected ? "#E27C37" : "white"} fill-opacity={selected ? "0.05" : "1"} stroke={removeBorder ? 'transparent' : (selected ? "#E27C37" : "#CDCDCE")} stroke-opacity="0.4" />
        <path d="M62.7132 98.496C60.7932 98.124 59.4492 97.668 59.4492 95.916C59.4492 94.14 60.5892 93.42 63.1092 93.42C65.3172 93.42 66.6612 94.164 66.6612 96.276H65.2812C65.2812 94.956 64.2132 94.8 62.9892 94.8C61.4652 94.8 60.9492 95.16 60.9492 95.832C60.9492 96.54 61.7172 96.768 63.3012 97.02C65.5092 97.368 66.7572 97.932 66.7572 99.684C66.7572 101.448 65.5932 102.18 63.0132 102.18C60.7692 102.18 59.3772 101.436 59.3772 99.324H60.7572C60.7572 100.656 61.8852 100.824 63.1452 100.824C64.5612 100.824 65.2572 100.512 65.2572 99.768C65.2572 98.724 63.8052 98.7 62.7132 98.496ZM69.0949 104.4H67.7149V96H69.0949V96.84C69.4309 96.192 70.0309 95.82 71.0749 95.82C72.7069 95.82 73.8109 96.912 73.8109 99C73.8109 101.088 72.5269 102.18 71.0269 102.18C70.0069 102.18 69.4189 101.76 69.0949 101.112V104.4ZM72.3709 98.988C72.3709 97.752 71.8909 97.104 70.7509 97.104C69.6709 97.104 69.0949 97.752 69.0949 98.988C69.0949 100.224 69.6709 100.884 70.7509 100.884C71.7349 100.884 72.3709 100.224 72.3709 98.988ZM76.1496 102H74.7696V93H76.1496V102ZM77.1437 94.176C77.1437 93.564 77.4077 93.276 78.0437 93.276C78.6677 93.276 78.9437 93.564 78.9437 94.176C78.9437 94.776 78.6677 95.076 78.0437 95.076C77.4077 95.076 77.1437 94.776 77.1437 94.176ZM77.3477 102V96H78.7277V102H77.3477ZM81.0058 99.864V97.26H79.5658V96H81.0058L81.4858 93.6H82.3858L82.3738 96H85.3858V97.26H82.3858V99.684C82.3858 100.308 82.4938 100.884 83.2258 100.884C83.7658 100.884 84.1738 100.62 84.1738 99.72H85.4338C85.4338 101.58 84.5818 102.18 83.1778 102.18C81.7618 102.18 81.0058 101.508 81.0058 99.864Z" fill="#251C15" />
        <rect x="16.5" y="17.5" width="110" height="62" rx="1.5" fill="white" stroke="black" />
        <g opacity="0.9">
            <mask id="path-5-inside-1_7504_10976" fill="white">
                <path d="M17 19C17 18.4477 17.4477 18 18 18H73V79H18C17.4477 79 17 78.5523 17 78V19Z" />
            </mask>
            <path d="M17 19C17 18.4477 17.4477 18 18 18H73V79H18C17.4477 79 17 78.5523 17 78V19Z" fill="#E0E6EA" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M74 19.0167V18H73.5H73H72.5H72V19.0167H72.5H73.5H74ZM74 23.0833V21.05H73.5H72.5H72V23.0833H72.5H73.5H74ZM74 27.15V25.1167H73.5H72.5H72V27.15H72.5H73.5H74ZM74 31.2167V29.1833H73.5H72.5H72V31.2167H72.5H73.5H74ZM74 35.2833V33.25H73.5H72.5H72V35.2833H72.5H73.5H74ZM74 39.35V37.3167H73.5H72.5H72V39.35H72.5H73.5H74ZM74 43.4167V41.3833H73.5H72.5H72V43.4167H72.5H73.5H74ZM74 47.4833V45.45H73.5H72.5H72V47.4833H72.5H73.5H74ZM74 51.55V49.5167H73.5H72.5H72V51.55H72.5H73.5H74ZM74 55.6167V53.5833H73.5H72.5H72V55.6167H72.5H73.5H74ZM74 59.6833V57.65H73.5H72.5H72V59.6833H72.5H73.5H74ZM74 63.75V61.7167H73.5H72.5H72V63.75H72.5H73.5H74ZM74 67.8167V65.7833H73.5H72.5H72V67.8167H72.5H73.5H74ZM74 71.8833V69.85H73.5H72.5H72V71.8833H72.5H73.5H74ZM74 75.95V73.9167H73.5H72.5H72V75.95H72.5H73.5H74ZM72 79V77.9833H72.5H73.5H74V79H73.5H73H72.5H72Z" fill="black" mask="url(#path-5-inside-1_7504_10976)" />
        </g>
        <g clip-path="url(#clip0_7504_10976)">
            <path d="M49.5438 58.0017C49.4038 58.0017 49.2604 57.9842 49.1188 57.9467L36.2329 54.4959C35.3496 54.2526 34.8229 53.3376 35.0546 52.4542L36.6804 46.3942C36.7404 46.1717 36.9688 46.0426 37.1904 46.0992C37.4129 46.1584 37.5446 46.3876 37.4854 46.6092L35.8604 52.6676C35.7446 53.1092 36.0096 53.5692 36.4521 53.6917L49.3329 57.1409C49.7754 57.2576 50.2321 56.9942 50.3471 56.5542L50.9979 54.1426C51.0579 53.9201 51.2863 53.7876 51.5088 53.8484C51.7313 53.9084 51.8621 54.1376 51.8029 54.3592L51.1529 56.7676C50.9571 57.5092 50.2821 58.0017 49.5438 58.0017Z" fill="#134B5F" />
            <path d="M53.332 53.0013H39.9987C39.0795 53.0013 38.332 52.2538 38.332 51.3346V41.3346C38.332 40.4155 39.0795 39.668 39.9987 39.668H53.332C54.2512 39.668 54.9987 40.4155 54.9987 41.3346V51.3346C54.9987 52.2538 54.2512 53.0013 53.332 53.0013ZM39.9987 40.5013C39.5395 40.5013 39.1654 40.8755 39.1654 41.3346V51.3346C39.1654 51.7938 39.5395 52.168 39.9987 52.168H53.332C53.7912 52.168 54.1654 51.7938 54.1654 51.3346V41.3346C54.1654 40.8755 53.7912 40.5013 53.332 40.5013H39.9987Z" fill="#134B5F" />
            <path d="M42.4987 45.5013C41.5795 45.5013 40.832 44.7538 40.832 43.8346C40.832 42.9155 41.5795 42.168 42.4987 42.168C43.4179 42.168 44.1654 42.9155 44.1654 43.8346C44.1654 44.7538 43.4179 45.5013 42.4987 45.5013ZM42.4987 43.0013C42.0395 43.0013 41.6654 43.3755 41.6654 43.8346C41.6654 44.2938 42.0395 44.668 42.4987 44.668C42.9579 44.668 43.332 44.2938 43.332 43.8346C43.332 43.3755 42.9579 43.0013 42.4987 43.0013Z" fill="#134B5F" />
            <path d="M38.8075 52.1096C38.7008 52.1096 38.5942 52.0688 38.5125 51.988C38.35 51.8255 38.35 51.5613 38.5125 51.3988L42.4483 47.463C42.92 46.9913 43.7442 46.9913 44.2158 47.463L45.3875 48.6346L48.6308 44.743C48.8667 44.4605 49.2133 44.2963 49.5825 44.293H49.5917C49.9567 44.293 50.3025 44.4513 50.5408 44.7288L54.8992 49.8138C55.0492 49.988 55.0292 50.2513 54.8542 50.4013C54.68 50.5513 54.4175 50.5321 54.2667 50.3563L49.9083 45.2713C49.8275 45.178 49.7158 45.1263 49.5917 45.1263C49.505 45.1188 49.3525 45.1788 49.2717 45.2763L45.7358 49.5188C45.6608 49.6088 45.5517 49.663 45.4342 49.668C45.3158 49.6763 45.2033 49.6296 45.1208 49.5463L43.6267 48.0521C43.4692 47.8955 43.195 47.8955 43.0375 48.0521L39.1017 51.988C39.0208 52.0688 38.9142 52.1096 38.8075 52.1096Z" fill="#134B5F" />
        </g>
        <defs>
            <clipPath id="clip0_7504_10976">
                <rect width="20" height="20" fill="white" transform="translate(35 38)" />
            </clipPath>
        </defs>
    </svg>

    )
}

export default SplitPageIcon


