import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IIconList } from '../../../helpers/Interfaces';
import { fontFamily } from 'html2canvas/dist/types/css/property-descriptors/font-family';
import { textTransform } from 'html2canvas/dist/types/css/property-descriptors/text-transform';

type dropDownMenuProps = {
    anchorEl: any;
    handleClose: any;
    open: boolean;
    menuItems: IIconList[];
}

export const DropDownMenu = (props: dropDownMenuProps) => {
    const menuItemStyles = {
        margin: '0 8px',
        padding: '8px',
        color: '#050505',
        cursor: 'pointer',
        lineHeight: '16px',
        fontSize: '15px',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#fff',
        borderRadius: '8px',
        border: '0',
        maxWidth: '250px',
        minWidth: '80px',
        '&:hover': {
            backgroundColor: '#f0f0f0',
        },
        '&:active': {
            backgroundColor: '#e0e0e0',
        },
        textTransform: 'uppercase',
    };

    const iconStyles: React.CSSProperties = {
        display: 'flex',
        width: '20px',
        height: '20px',
        userSelect: 'none',
        marginRight: '12px',
        lineHeight: '16px',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    };

    const textStyles = {
        display: 'flex',
        lineHeight: '20px',
        flexGrow: 1,
        minWidth: '80px',
        fontFamily: "var(--primary-font)",
    };

    return (<Menu
        anchorEl={props.anchorEl}
        open={props.open}
        onClose={props.handleClose}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        sx={{
            zIndex: 100,
            position: 'fixed',
            boxShadow: '0 12px 28px #0003, 0 2px 4px #0000001a, inset 0 0 0 1px #ffffff80',
            borderRadius: '10px',
            padding: '8px 0',
            minHeight: '40px',
        }}
    >
        {props.menuItems.map((item, index) => (
            <MenuItem key={index} onClick={() => {
                item.action();
                props.handleClose();
            }} sx={menuItemStyles}>
                {item.icon && <i className="icon" style={iconStyles}>{item.icon}</i>}
                <span className="text" style={textStyles}>{item.text}</span>
            </MenuItem>
        ))}
    </Menu>)
}
