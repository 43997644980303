const AccountSvg = () => (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_582_302)">
        <path d="M10 20C15.5143 20 20 15.5143 20 10C20 4.48571 15.5143 0 10 0C4.48571 0 0 4.48571 0 10C0 15.5143 4.48571 20 10 20ZM10 1.42857C14.7286 1.42857 18.5714 5.27143 18.5714 10C18.5714 14.7286 14.7286 18.5714 10 18.5714C5.27143 18.5714 1.42857 14.7286 1.42857 10C1.42857 5.27143 5.27143 1.42857 10 1.42857Z" fill="white" />
        <path d="M5.71426 9.28572H14.2857C14.6805 9.28572 15 8.96586 15 8.57143C15 8.17701 14.6805 7.85715 14.2857 7.85715H7.04904L7.73718 6.82479C7.9559 6.49658 7.86726 6.05293 7.53911 5.83429C7.21026 5.61493 6.7669 5.70386 6.54861 6.03236L5.12004 8.17522C4.9739 8.39422 4.96033 8.67608 5.08447 8.90836C5.2089 9.14065 5.4509 9.28572 5.71426 9.28572Z" fill="white" />
        <path d="M5.71429 12.1429H12.9513L12.2628 13.1752C12.0438 13.5034 12.1324 13.9471 12.4609 14.1657C12.583 14.247 12.7204 14.2857 12.8564 14.2857C13.0873 14.2857 13.314 14.1741 13.4514 13.9676L14.88 11.8248C15.0258 11.6058 15.0397 11.3239 14.9156 11.0916C14.7914 10.8594 14.5494 10.7143 14.2857 10.7143H5.71429C5.31986 10.7143 5 11.0341 5 11.4286C5 11.823 5.31986 12.1429 5.71429 12.1429Z" fill="white" />
    </g>
    <defs>
        <clipPath id="clip0_582_302">
            <rect width="20" height="20" fill="white" />
        </clipPath>
    </defs>
</svg>);

export default AccountSvg