import React from 'react'

const OpenFolder = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.055 8.5425L15.975 13.9425C15.8728 14.4521 15.5973 14.9106 15.1953 15.2401C14.7934 15.5696 14.2897 15.7498 13.77 15.75H3C2.40326 15.75 1.83097 15.5129 1.40901 15.091C0.987053 14.669 0.75 14.0967 0.75 13.5V4.5C0.75 3.90326 0.987053 3.33097 1.40901 2.90901C1.83097 2.48705 2.40326 2.25 3 2.25H6C6.14703 2.25115 6.29047 2.29549 6.4125 2.3775L8.475 3.75H12.75C13.3467 3.75 13.919 3.98705 14.341 4.40901C14.7629 4.83097 15 5.40326 15 6H13.5C13.5 5.80109 13.421 5.61032 13.2803 5.46967C13.1397 5.32902 12.9489 5.25 12.75 5.25H8.25C8.10297 5.24885 7.95953 5.20451 7.8375 5.1225L5.775 3.75H3C2.80109 3.75 2.61032 3.82902 2.46967 3.96967C2.32902 4.11032 2.25 4.30109 2.25 4.5V13.5C2.25 13.6989 2.32902 13.8897 2.46967 14.0303C2.61032 14.171 2.80109 14.25 3 14.25H3.135L4.275 8.5575C4.37722 8.0479 4.65271 7.58941 5.05466 7.25992C5.45662 6.93042 5.96025 6.75024 6.48 6.75H15.585C15.8072 6.74945 16.0268 6.79828 16.2278 6.89297C16.4289 6.98765 16.6064 7.12583 16.7475 7.2975C16.8876 7.46939 16.9878 7.6702 17.041 7.88548C17.0942 8.10076 17.099 8.32515 17.055 8.5425Z" fill="#251C15" />
        </svg>

    )
}

export default OpenFolder