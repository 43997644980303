import { MouseEventHandler } from "react"

const QuoteIcon = (props: { isActive?: boolean, onClick: MouseEventHandler<SVGSVGElement> }) => {
    return (<svg onClick={props.onClick} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.12499 12.0238C3.7125 13.0613 3.06251 14.0863 2.19376 15.0738C1.91876 15.3863 1.88126 15.8363 2.10625 16.1863C2.28127 16.4613 2.56874 16.6113 2.88124 16.6113C2.96875 16.6113 3.05626 16.6051 3.14377 16.5738C4.98123 16.0363 9.27502 14.13 9.39377 8.01753C9.43749 5.6613 7.71252 3.63631 5.46875 3.40506C4.22501 3.28005 2.98752 3.68628 2.06875 4.51129C1.15002 5.34255 0.625 6.53006 0.625 7.76755C0.625 9.83004 2.08752 11.6363 4.12499 12.0238Z" fill="#251C15" />
        <path d="M15.443 3.40506C14.2055 3.28005 12.968 3.68628 12.0493 4.51129C11.1305 5.34255 10.6055 6.53006 10.6055 7.76755C10.6055 9.83004 12.068 11.6363 14.1055 12.0238C13.693 13.0613 13.043 14.0863 12.1742 15.0738C11.8992 15.3863 11.8617 15.8363 12.0868 16.1863C12.2617 16.4613 12.5493 16.6113 12.8618 16.6113C12.9492 16.6113 13.0367 16.6051 13.1242 16.5738C14.9617 16.0363 19.2555 14.13 19.3742 8.01752V7.93005C19.3742 5.61129 17.668 3.63631 15.443 3.40506Z" fill="#251C15" />
    </svg>
    )
}

export default QuoteIcon