import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { useEffect, useState } from 'react'
import './CustomStageDropDown.scss'
import { ILead } from '../../../helpers/Interfaces';
import { getLeadStage, capitalizeFirstLetter } from '../../../helpers/helperfunctions';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';

const CustomStageDropDown = ({ lead, changeStage }: { lead: ILead, changeStage: CallableFunction }) => {
    const [leadStage, setLeadStage] = useState<string>('NEW_LEAD');
    const leadStages = useSelector((state: RootState) => state.leadStages)


    useEffect(() => {
        setLeadStage(lead.stage)
    }, [lead.stage])

    const handleChange = (event: SelectChangeEvent) => {
        setLeadStage(event.target.value as string);
        changeStage(event.target.value);
    };

    return (
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small"
            classes={{ root: "custom-mui-field custom-stage-select-dropdown" }}>
            <Select
                IconComponent={"span"}
                id="demo-select-small"
                value={leadStage}
                sx={{ backgroundColor: getLeadStage(leadStage)?.color }}
                className='ttu'
                onChange={handleChange} >
                {leadStages.map((stage: string) => <MenuItem value={stage}>{capitalizeFirstLetter(t(stage))}</MenuItem>)}
            </Select>
        </FormControl>
    )
}

export default CustomStageDropDown