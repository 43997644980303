const ReportsSvg = () => (<svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_578_288)">
        <path d="M7.49998 3.05176e-05H1.66675C0.750248 3.05176e-05 0 0.95451 0 2.1212V9.54483C0 10.7115 0.750248 11.666 1.66675 11.666H7.49998C8.41623 11.666 9.16672 10.7115 9.16672 9.54483V2.1212C9.16672 0.95451 8.41623 3.05176e-05 7.49998 3.05176e-05ZM7.49998 9.54483H1.66675V2.1212H7.49998V9.54483Z" fill="white" />
        <path d="M7.49998 14.1793H1.66675C0.750248 14.1793 0 14.9296 0 15.8458L0 17.5C0 18.4165 0.750248 19.1667 1.66675 19.1667H7.49998C8.41623 19.1667 9.16672 18.4165 9.16672 17.5V15.8458C9.16672 14.9296 8.41623 14.1793 7.49998 14.1793ZM7.49998 17.5H1.66675V15.8458H7.49998V17.5Z" fill="white" />
        <path d="M10.8333 0.833403C10.8333 0.373144 11.2064 3.05176e-05 11.6666 3.05176e-05H14.1666C14.6269 3.05176e-05 15 0.373144 15 0.833403C15 1.29366 14.6269 1.66678 14.1666 1.66678H11.6666C11.2064 1.66678 10.8333 1.29366 10.8333 0.833403Z" fill="white" />
        <path d="M10.8333 7.50014C10.8333 7.03988 11.2064 6.66676 11.6666 6.66676H15.8334C16.2936 6.66676 16.6667 7.03988 16.6667 7.50014C16.6667 7.96039 16.2936 8.33351 15.8334 8.33351H11.6666C11.2064 8.33351 10.8333 7.96039 10.8333 7.50014Z" fill="white" />
        <path d="M10.8333 4.16664C10.8333 3.70638 11.2064 3.33327 11.6666 3.33327H19.1666C19.6269 3.33327 20 3.70638 20 4.16664C20 4.6269 19.6269 5.00001 19.1666 5.00001H11.6666C11.2064 5.00001 10.8333 4.6269 10.8333 4.16664Z" fill="white" />
        <path d="M10.8333 15.0126C10.8333 14.5524 11.2063 14.1793 11.6665 14.1793H19.1667C19.6269 14.1793 20 14.5524 20 15.0126C20 15.4727 19.6269 15.8458 19.1667 15.8458H11.6665C11.2063 15.8458 10.8333 15.4727 10.8333 15.0126Z" fill="white" />
        <path d="M10.8333 18.3334C10.8333 17.8731 11.2064 17.5 11.6666 17.5H15.8334C16.2936 17.5 16.6667 17.8731 16.6667 18.3334C16.6667 18.7936 16.2936 19.1667 15.8334 19.1667H11.6666C11.2064 19.1667 10.8333 18.7936 10.8333 18.3334Z" fill="white" />
        <path d="M10.8333 10.8328C10.8333 10.3726 11.2063 9.99951 11.6665 9.99951H14.1667C14.6269 9.99951 15 10.3726 15 10.8328C15 11.2929 14.6269 11.666 14.1667 11.666H11.6665C11.2063 11.666 10.8333 11.2929 10.8333 10.8328Z" fill="white" />
    </g>
    <defs>
        <clipPath id="clip0_578_288">
            <rect width="20" height="20" fill="white" />
        </clipPath>
    </defs>
</svg>);

export default ReportsSvg