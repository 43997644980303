import phoneIcon from "../../assets/images/lead-card/phone.svg";
import emailIcon from "../../assets/images/lead-card/email.svg";

import starIcon from "../../assets/images/lead-card/star-icon.svg";
import facebookIcon from "../../assets/images/lead-card/facebookIcon.svg";
import pcIcon from "../../assets/images/lead-card/pc-icon.svg";
import callIcon from "../../assets/images/lead-card/callIcon.svg";
import whatsappIcon from "../../assets/images/lead-card/whatsappIcon.svg";
import backIcon from "../../assets/images/lead-card/backIcon.svg";
import emailIcon2 from "../../assets/images/lead-card/emailIcon.svg";
import tradIcon from "../../assets/images/lead-card/tradIcon.svg";

import globeIcon from "../../assets/images/lead-card/globe.svg";
import currencyIcon from "../../assets/images/lead-card/currency.svg";
import calendarIcon from "../../assets/images/lead-card/calendar.svg";
import arrivalIcon from "../../assets/images/lead-card/arrival.svg";
import departureIcon from "../../assets/images/lead-card/departure.svg";
import familyIcon from "../../assets/images/lead-card/family.svg";
import userIcon from "../../assets/images/lead-card/user-icon.svg"
import { MouseEventHandler, useState } from "react";
import { IAssignee, ILead } from "../../helpers/Interfaces";
import { formatDate, formatNumber, getPortShortName, getTravelGroup, stringAvatar } from "../../helpers/helperfunctions";
import { Avatar, AvatarGroup } from "@mui/material";
import ViewLeadFunctions from "../ViewLead/ViewLeadFunctions";
import { t } from "i18next";

type LeadCardProps = {
    lead: ILead,
    onPress: CallableFunction,
    selectAssignee: MouseEventHandler<HTMLButtonElement>
}

const LeadCard = ({ lead, onPress, selectAssignee }: LeadCardProps) => {
    const [expanded, setExpanded] = useState(false);


    const toggleExpand = (value: boolean) => () => {
        setExpanded(value);
    };

    const toggleViewLead = () => () => {
        onPress()
    }

    const assignees: IAssignee[] = lead.assignees
        ? lead.assignees.map(t => ({ id: t.id, name: t.name }))
        : [];

    const getPointOfReferral = (value: string) => {
        switch (value) {
            case t('website'):
                return (<img src={pcIcon} alt="PC" />);
            case t('facebook'):
                return (<img src={facebookIcon} alt="PC" />);
            case t('whatsapp'):
                return (<img src={whatsappIcon} alt="PC" />);
            case t('phoneCall'):
                return (<img src={callIcon} alt="PC" />);
            case t('referringPartner'):
                return (<img src={backIcon} alt="PC" />);
            case t('email'):
                return (<img src={emailIcon2} alt="PC" />);
            case t('tradeShow'):
                return (<img src={tradIcon} alt="PC" />);
            default:
                return (<></>);
        }
    }

    return (
        <div className="lead-card bdr-10">
            <div onClick={toggleViewLead()} className="top">
                <span className="name fz-18 primary-font">{lead.user.name}</span>
                <ul className="normal-list lead-icon-list">
                    <li className="lead-icon-item">
                        <span className="icon">
                            <img src={phoneIcon} alt="Phone" />
                        </span>
                        <span className="txt">{lead.user.phoneNumber}</span>
                    </li>
                    <li className="lead-icon-item">
                        <span className="icon">
                            <img src={emailIcon} alt="Email" />
                        </span>
                        <span className="txt">{lead.user.email}</span>
                    </li>
                </ul>
                <hr className="seperator" />
            </div>
            {expanded &&
                (<div className="middle">
                    <ul className="normal-list lead-icon-list">
                        <li className="lead-icon-item"><span className="icon"><img src={globeIcon} alt="Phone" /></span><span className="txt">{lead.user.nationality}</span></li>
                        <li className="lead-icon-item"><span className="icon"><img src={currencyIcon} alt="Phone" /></span><span className="txt">{`£${formatNumber(lead.budgetRangeFrom)} - £${formatNumber(lead.budgetRangeTo)}`}</span></li>
                        <li className="lead-icon-item"><span className="icon"><img src={calendarIcon} alt="Phone" /></span><span className="txt">{formatDate(lead.startDate, 'MMM. D, YYYY')} - {formatDate(lead.endDate, 'MMM. D, YYYY')}</span></li>
                        <li className="lead-icon-item"><span className="icon"><img src={arrivalIcon} alt="Phone" /></span><span className="txt">{getPortShortName(lead.portArrival)}</span></li>
                        <li className="lead-icon-item"><span className="icon"><img src={departureIcon} alt="Phone" /></span><span className="txt">{lead.departFromSame ? getPortShortName(lead.portArrival) : getPortShortName(lead.portDeparture)}</span></li>
                        {(lead.travelGroupAdults && lead.travelGroupChildren && lead.travelGroupInfants) ? (<li className="lead-icon-item"><span className="icon"><img src={familyIcon} alt="Phone" /></span><span className="txt">{getTravelGroup(lead)}</span></li>) : (<></>)}
                    </ul>
                    <hr className="seperator" />
                </div>)}
            <div className="bottom">
                <div className="lft">
                    <ul className="normal-list nav cricle-btn-nav">
                        <li className="circle-item">
                            <button className="circle-btn">
                                {lead.newUser
                                    ? (<img src={starIcon} alt="Star" />)
                                    : (<></>)}
                            </button>
                        </li>
                        <li className="circle-item">
                            <button className="circle-btn">
                                {getPointOfReferral(lead.pointReferral ?? '')}
                            </button>
                        </li>
                        <li className="circle-item">
                            <button onClick={selectAssignee} >
                                {assignees.length > 0
                                    ? (<AvatarGroup max={1}>
                                        {assignees.map(avatar => (<Avatar className="avatar-icon" {...stringAvatar(avatar.name, '10px', 21)} />))}
                                    </AvatarGroup>)
                                    : (<img src={userIcon} alt="User" />)}
                            </button>
                        </li>
                    </ul>
                </div>
                <div className="rht">
                    {!expanded &&
                        (<span className="date fz-10 opacity-4">{lead.createDate ? formatDate(lead.createDate ?? lead.startDate, 'DD/MM/YYYY') : ''}</span>)}
                    {!expanded &&
                        (<button onClick={toggleExpand(true)} className="expand-label expand-inactive c-white primary-font ttu">expand</button>)}
                    {expanded &&
                        (<button onClick={toggleExpand(false)} className="expand-label c-white primary-font ttu">collapse</button>)}
                </div>

            </div>
        </div>
    )
}

export default LeadCard