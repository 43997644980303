import React from 'react'

const TopRightRadius = () => {
    return (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_291_2635)">
                <mask id="mask0_291_2635" maskUnits="userSpaceOnUse" x="0" y="0" width="21" height="20">
                    <path d="M0.5 0H20.5V20H0.5V0Z" fill="white" />
                </mask>
                <g mask="url(#mask0_291_2635)">
                    <path d="M16.3333 15.8333H18V17.5H16.3333V15.8333ZM16.3333 14.1667H18V12.5H16.3333V14.1667ZM3 10.8333H4.66667V9.16667H3V10.8333ZM3 14.1667H4.66667V12.5H3V14.1667ZM3 7.5H4.66667V5.83333H3V7.5ZM3 4.16667H4.66667V2.5H3V4.16667ZM6.33333 4.16667H8V2.5H6.33333V4.16667ZM13 17.5H14.6667V15.8333H13V17.5ZM9.66667 17.5H11.3333V15.8333H9.66667V17.5ZM13 17.5H14.6667V15.8333H13V17.5ZM6.33333 17.5H8V15.8333H6.33333V17.5ZM3 17.5H4.66667V15.8333H3V17.5ZM18 6.66667C18 4.36667 16.1333 2.5 13.8333 2.5H9.66667V4.16667H13.8333C15.2083 4.16667 16.3333 5.29167 16.3333 6.66667V10.8333H18V6.66667Z" fill="#134B5F" />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_291_2635">
                    <rect width="20" height="20" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default TopRightRadius