import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUser } from "../../helpers/Interfaces";
import { getUser } from "../../helpers/storageConfig";

const initialState: IUser = getUser();

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<IUser>) => {
            return action.payload;
        },
        removeUser: () => {
            return {};
        }
    }
});

export const { setUser, removeUser } = userSlice.actions;
export default userSlice.reducer;