import React from 'react'

const PageSettignsIcon = () => {
    return (<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_7220_11536)">
            <rect y="3.49219" width="6.69767" height="9.05426" rx="0.5" fill="#251C15" />
            <rect x="9.30273" y="3.49219" width="6.69767" height="9.05426" rx="0.5" fill="#251C15" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.00094 2.5C8.34344 2.5 8.62109 2.77765 8.62109 3.12016L8.62109 3.83678C8.62109 4.17928 8.34344 4.45693 8.00094 4.45693C7.65844 4.45693 7.38078 4.17928 7.38078 3.83678L7.38078 3.12016C7.38078 2.77765 7.65844 2.5 8.00094 2.5ZM8.00094 5.09087C8.34344 5.09087 8.62109 5.36852 8.62109 5.71103L8.62109 7.14427C8.62109 7.48677 8.34344 7.76443 8.00094 7.76443C7.65844 7.76443 7.38078 7.48677 7.38078 7.14427L7.38078 5.71103C7.38078 5.36852 7.65844 5.09087 8.00094 5.09087ZM8.00094 8.39836C8.34344 8.39836 8.62109 8.67602 8.62109 9.01852L8.62109 10.4518C8.62109 10.7943 8.34344 11.0719 8.00094 11.0719C7.65844 11.0719 7.38078 10.7943 7.38078 10.4518L7.38078 9.01852C7.38078 8.67602 7.65844 8.39836 8.00094 8.39836ZM8.00094 11.7059C8.34344 11.7059 8.62109 11.9835 8.62109 12.326L8.62109 13.0426C8.62109 13.3851 8.34344 13.6628 8.00094 13.6628C7.65844 13.6628 7.38078 13.3851 7.38078 13.0426L7.38078 12.326C7.38078 11.9835 7.65844 11.7059 8.00094 11.7059Z" fill="#251C15" />
        </g>
        <defs>
            <clipPath id="clip0_7220_11536">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
    )
}

export default PageSettignsIcon