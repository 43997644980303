function FilterIconRemove() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_6928_12317)">
                <path d="M18.925 1.50591e-06H1.09448C0.7798 -0.000593357 0.491491 0.175089 0.347733 0.454871C0.201992 0.738422 0.227571 1.07987 0.413762 1.33883L6.94612 10.5411C6.9483 10.5443 6.95068 10.5473 6.95286 10.5504C7.19021 10.8709 7.3187 11.2591 7.3193 11.6579V19.1605C7.31791 19.3825 7.40516 19.5961 7.5616 19.7537C7.71825 19.9112 7.93121 20 8.15329 20C8.26612 19.9998 8.37795 19.9774 8.48225 19.934L12.1529 18.5345C12.4817 18.4339 12.7 18.1236 12.7 17.75V11.6579C12.7006 11.2591 12.8291 10.8709 13.0662 10.5504C13.0684 10.5473 13.0708 10.5443 13.073 10.5411L19.6055 1.33864C19.7917 1.07987 19.8173 0.73862 19.6716 0.45507C19.528 0.175089 19.2395 -0.000593357 18.925 1.50591e-06Z" fill="#134B5F" />
            </g>
            <defs>
                <clipPath id="clip0_6928_12317">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default FilterIconRemove