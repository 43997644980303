import axios from "axios";
import { getBaseURL } from "../helpers/helperfunctions";
import AuthService from "./AuthService";
import { getFromLocalStorage, getTokens, saveTokens } from "../helpers/storageConfig";
import FirebaseFunctions from "../helpers/firebaseFunctions";

export default class ApiService {

    async getAllOrganizationUsers() {
        const ax = await this.ax();
        return await ax.get("/user/organizationUsers");
    }
    async getAllOrganizations() {
        const ax = await this.ax();
        return await ax.get("/organization/getAll");
    }

    async createLead(data: any) {
        const ax = await this.ax();
        return await ax.post("/lead/create", data);
    }

    async updateLead(data: any) {
        const ax = await this.ax();
        return await ax.put("/lead/update", data);
    }

    async createClient(data: any) {
        const ax = await this.ax();
        return await ax.post("/user/createOrganizationUser", data);
    }

    async updateClient(data: any) {
        const ax = await this.ax();
        return await ax.post("/user/updateOrganizationUser", data);
    }

    async deleteLead(data: any) {
        const ax = await this.ax();
        return await ax.post("/lead/delete", data);
    }

    async getAllLeads() {
        const ax = await this.ax();
        return await ax.get("/lead/getAll");
    }

    async addLeadTask(data: any) {
        const ax = await this.ax();
        return await ax.post('lead/addTask', data)
    }

    async updateLeadTask(data: any) {
        const ax = await this.ax();
        return await ax.post("/lead/updateTask", data);
    }

    async deleteLeadTask(data: any) {
        const ax = await this.ax();
        return await ax.post("/lead/deleteTask", data);
    }

    async updateLeadStage(data: any) {
        const ax = await this.ax();
        return await ax.post("/lead/changeStage", data);
    }

    async getLeadById(data: any) {
        const ax = await this.ax();
        return await ax.post("/lead/get-by-id", data);
    }

    async getAllLogs(data: any) {
        const ax = await this.ax();
        return await ax.post("/log/getAll", data);
    }

    async getLeadViewItineraries(data: any) {
        const ax = await this.ax();
        return await ax.post("/lead/get-lead-itineraries", data);
    }

    async getAllItineraries() {
        const ax = await this.ax();
        return await ax.post("/lead/get-all-itineraries");
    }

    async getAddItineraries(data: any) {
        const ax = await this.ax();
        return await ax.post("/lead/add-itinerary", data);
    }

    async submitItineraries(data: any) {
        const ax = await this.ax();
        return await ax.post("/lead/submit-itinerary", data);
    }

    async getCalenderAllTasks() {
        const ax = await this.ax();
        return await ax.get("/calender/getAll");
    }

    async getAllUsers() {
        const ax = await this.ax();
        return await ax.get("/user/getAll");
    }

    async handleTaskAssignees(data: any) {
        const ax = await this.ax();
        return await ax.post("/lead/manage-task-assignee", data);
    }

    async handleLeadAssignees(data: any) {
        const ax = await this.ax();
        return await ax.post("/lead/manage-lead-assignee", data);
    }

    private async ax() {
        let tenantId = getFromLocalStorage('tenantId');

        const apiClient = axios.create({
            baseURL: getBaseURL()
        });

        apiClient.interceptors.request.use(async (config) => {
            const token = getTokens();

            config.headers.Authorization = `Bearer ${token}`;
            config.headers['x-tenant-id'] = tenantId;
            return config;
        }, (error) => {
            return Promise.reject(error);
        });

        apiClient.interceptors.response.use((response) => {
            return response;
        }, async (error) => {

            const originalRequest = error.config;
            if (error.response && error.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;

                const refreshToken = getFromLocalStorage('refreshToken');


                const authService = new AuthService();
                const firebaseFunctions = new FirebaseFunctions();

                const response = await authService.refreshToken({ 'uid': refreshToken });
                const data = response.data;
                const token = data.tokens.token;
                const customToken = data.tokens.customToken;
                saveTokens(token);
                firebaseFunctions.authenticateUser(customToken);

                axios.defaults.headers.Authorization = `Bearer ${token}`;
                originalRequest.headers['Authorization'] = `Bearer ${token}`;
                originalRequest.headers['x-tenant-id'] = tenantId;
                return apiClient(originalRequest);
            }
            return Promise.reject(error);
        });
        return apiClient;
    }
}