const UserSvg = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="10" cy="10" r="10" fill="white" />
            <g opacity="0.6">
                <path d="M8.9458 9.85156H10.9679C12.6343 9.85156 13.983 11.2024 13.983 12.8667V13.7264C13.983 14.4293 13.4135 15.001 12.7084 15.001H7.2032C6.50024 14.9989 5.93066 14.4293 5.93066 13.7264V12.8667C5.93066 11.2003 7.28155 9.85156 8.9458 9.85156Z" fill="#251C15" />
                <path d="M9.9574 9.2898C11.142 9.2898 12.1023 8.32949 12.1023 7.1449C12.1023 5.9603 11.142 5 9.9574 5C8.7728 5 7.8125 5.9603 7.8125 7.1449C7.8125 8.32949 8.7728 9.2898 9.9574 9.2898Z" fill="#251C15" />
            </g>
        </svg>
    )
}

export default UserSvg