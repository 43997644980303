import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $generateHtmlFromNodes } from '@lexical/html';
import { $getRoot } from 'lexical';

const SaveEditorState = ({ onUpdate }: { onUpdate: (value: {json: string, html: string}) => void }) => {
    const [editor] = useLexicalComposerContext();

    editor.registerUpdateListener(({ editorState }) => {
        const editorStateJSON = JSON.stringify(editorState);
        editorState.read(() => {
            const root = $getRoot();
            const htmlString = $generateHtmlFromNodes(editor);
            onUpdate({json: editorStateJSON, html: htmlString});
          });
    });

    return null;
}

export default SaveEditorState;
