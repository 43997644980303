const DashboardSvg = () => (<svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.875 18.5938H2.34375C1.05141 18.5938 0 17.5423 0 16.25V3.75C0 2.45766 1.05141 1.40625 2.34375 1.40625H6.875C8.16734 1.40625 9.21875 2.45766 9.21875 3.75V16.25C9.21875 17.5423 8.16734 18.5938 6.875 18.5938ZM2.34375 2.96875C1.91297 2.96875 1.5625 3.31922 1.5625 3.75V16.25C1.5625 16.6808 1.91297 17.0312 2.34375 17.0312H6.875C7.30578 17.0312 7.65625 16.6808 7.65625 16.25V3.75C7.65625 3.31922 7.30578 2.96875 6.875 2.96875H2.34375Z" fill="white" />
    <path d="M17.6562 9.21875H13.125C11.8327 9.21875 10.7812 8.16734 10.7812 6.875V3.75C10.7812 2.45766 11.8327 1.40625 13.125 1.40625H17.6562C18.9486 1.40625 20 2.45766 20 3.75V6.875C20 8.16734 18.9486 9.21875 17.6562 9.21875ZM13.125 2.96875C12.6942 2.96875 12.3438 3.31922 12.3438 3.75V6.875C12.3438 7.30578 12.6942 7.65625 13.125 7.65625H17.6562C18.087 7.65625 18.4375 7.30578 18.4375 6.875V3.75C18.4375 3.31922 18.087 2.96875 17.6562 2.96875H13.125Z" fill="white" />
    <path d="M17.6562 18.5938H13.125C11.8327 18.5938 10.7812 17.5423 10.7812 16.25V13.125C10.7812 11.8327 11.8327 10.7812 13.125 10.7812H17.6562C18.9486 10.7812 20 11.8327 20 13.125V16.25C20 17.5423 18.9486 18.5938 17.6562 18.5938ZM13.125 12.3438C12.6942 12.3438 12.3438 12.6942 12.3438 13.125V16.25C12.3438 16.6808 12.6942 17.0312 13.125 17.0312H17.6562C18.087 17.0312 18.4375 16.6808 18.4375 16.25V13.125C18.4375 12.6942 18.087 12.3438 17.6562 12.3438H13.125Z" fill="white" />
    <rect x="1.33325" y="2.81348" width="6.67206" height="14.463" fill="white" />
    <rect x="12.0044" y="2.64771" width="6.81714" height="5.2009" fill="white" />
    <rect x="12.0044" y="12.2621" width="6.81714" height="5.2009" fill="white" />
</svg>);

export default DashboardSvg;
