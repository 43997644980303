import { MouseEventHandler } from "react"

const CodeIcon = (props: { isActive?: boolean, onClick: MouseEventHandler<SVGSVGElement> }) => {
    return (<svg onClick={props.onClick} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.07797 10.5903L4.41131 13.9236C4.48818 14.0032 4.58013 14.0667 4.6818 14.1104C4.78347 14.154 4.89282 14.177 5.00347 14.178C5.11412 14.179 5.22385 14.1579 5.32627 14.116C5.42868 14.0741 5.52173 14.0122 5.59997 13.934C5.67821 13.8557 5.74009 13.7627 5.78199 13.6603C5.82389 13.5578 5.84498 13.4481 5.84402 13.3375C5.84305 13.2268 5.82007 13.1175 5.77639 13.0158C5.73272 12.9141 5.66923 12.8222 5.58964 12.7453L2.84547 10.0011L5.58964 7.25696C5.66923 7.18009 5.73272 7.08813 5.77639 6.98646C5.82007 6.88479 5.84305 6.77544 5.84402 6.66479C5.84498 6.55414 5.82389 6.44441 5.78199 6.342C5.74009 6.23958 5.67821 6.14654 5.59997 6.0683C5.52173 5.99005 5.42868 5.92817 5.32627 5.88627C5.22385 5.84437 5.11412 5.82329 5.00347 5.82425C4.89282 5.82521 4.78347 5.8482 4.6818 5.89187C4.58013 5.93555 4.48818 5.99903 4.41131 6.07863L1.07797 9.41196C0.921747 9.56823 0.833984 9.78016 0.833984 10.0011C0.833984 10.2221 0.921747 10.434 1.07797 10.5903Z" fill="#251C15" />
        <path d="M15.589 6.07863C15.5121 5.99903 15.4202 5.93555 15.3185 5.89187C15.2168 5.8482 15.1075 5.82521 14.9968 5.82425C14.8862 5.82329 14.7764 5.84437 14.674 5.88627C14.5716 5.92817 14.4786 5.99005 14.4003 6.0683C14.3221 6.14654 14.2602 6.23958 14.2183 6.342C14.1764 6.44441 14.1553 6.55414 14.1563 6.66479C14.1572 6.77544 14.1802 6.88479 14.2239 6.98646C14.2676 7.08813 14.3311 7.18009 14.4107 7.25696L17.1548 10.0011L14.4107 12.7453C14.3311 12.8222 14.2676 12.9141 14.2239 13.0158C14.1802 13.1175 14.1572 13.2268 14.1563 13.3375C14.1553 13.4481 14.1764 13.5578 14.2183 13.6603C14.2602 13.7627 14.3221 13.8557 14.4003 13.934C14.4786 14.0122 14.5716 14.0741 14.674 14.116C14.7764 14.1579 14.8862 14.179 14.9968 14.178C15.1075 14.177 15.2168 14.154 15.3185 14.1104C15.4202 14.0667 15.5121 14.0032 15.589 13.9236L18.9223 10.5903C19.0786 10.434 19.1663 10.2221 19.1663 10.0011C19.1663 9.78016 19.0786 9.56823 18.9223 9.41196L15.589 6.07863Z" fill="#251C15" />
        <path d="M10.8654 3.93971L7.53206 15.6064C7.47138 15.8188 7.49751 16.0466 7.6047 16.2397C7.7119 16.4328 7.89139 16.5755 8.10373 16.6364C8.17839 16.657 8.25544 16.6676 8.3329 16.668C8.51399 16.6679 8.69011 16.6088 8.83461 16.4996C8.97911 16.3905 9.08411 16.2372 9.13373 16.063L12.4671 4.39637C12.5018 4.28983 12.5147 4.17738 12.505 4.06574C12.4953 3.9541 12.4631 3.84556 12.4105 3.74663C12.3579 3.6477 12.2858 3.56039 12.1987 3.48995C12.1115 3.4195 12.011 3.36735 11.9033 3.33663C11.7955 3.3059 11.6827 3.29722 11.5715 3.31112C11.4603 3.32501 11.353 3.36119 11.2561 3.4175C11.1592 3.4738 11.0747 3.54906 11.0075 3.63879C10.9404 3.72852 10.8921 3.83086 10.8654 3.93971Z" fill="#251C15" />
    </svg>
    )
}


export default CodeIcon