import React from 'react'

const BottomRightRadius = () => {
    return (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_291_2651" maskUnits="userSpaceOnUse" x="0" y="0" width="21" height="20" mask-type="luminance">
                <path d="M20.5 0L20.5 20L0.499999 20L0.5 -8.74228e-07L20.5 0Z" fill="white" />
            </mask>
            <g mask="url(#mask0_291_2651)">
                <path d="M4.66667 15.8333L4.66667 17.5L3 17.5L3 15.8333L4.66667 15.8333ZM6.33333 15.8333L6.33333 17.5L8 17.5L8 15.8333L6.33333 15.8333ZM9.66667 2.5L9.66667 4.16667L11.3333 4.16667L11.3333 2.5L9.66667 2.5ZM6.33333 2.5L6.33333 4.16667L8 4.16667L8 2.5L6.33333 2.5ZM13 2.5L13 4.16667L14.6667 4.16667L14.6667 2.5L13 2.5ZM16.3333 2.5L16.3333 4.16667L18 4.16667L18 2.5L16.3333 2.5ZM16.3333 5.83333L16.3333 7.5L18 7.5L18 5.83333L16.3333 5.83333ZM3 12.5L3 14.1667L4.66667 14.1667L4.66667 12.5L3 12.5ZM3 9.16667L3 10.8333L4.66667 10.8333L4.66667 9.16667L3 9.16667ZM3 12.5L3 14.1667L4.66667 14.1667L4.66667 12.5L3 12.5ZM3 5.83333L3 7.5L4.66667 7.5L4.66667 5.83333L3 5.83333ZM3 2.5L3 4.16667L4.66667 4.16667L4.66667 2.5L3 2.5ZM13.8333 17.5C16.1333 17.5 18 15.6333 18 13.3333L18 9.16667L16.3333 9.16667L16.3333 13.3333C16.3333 14.7083 15.2083 15.8333 13.8333 15.8333L9.66667 15.8333L9.66667 17.5L13.8333 17.5Z" fill="#134B5F" />
            </g>
        </svg>
    )
}

export default BottomRightRadius;
