import { IUser } from "./Interfaces";
import { isEmptyOrNullObj } from "./helperfunctions";
import * as CryptoJS from 'crypto-js';

const encryptKey = "ExplorMeWebApp";

// user authentication functions : starts
export const getUser = (): IUser => {
    return decryptUser();
}

export const saveUser = (user: IUser) => {
    let encryptedUser = encryptUser(user)
    localStorage.setItem("user", JSON.stringify(encryptedUser))
}

export const encryptUser = (user: IUser) => {
    return CryptoJS.AES.encrypt(JSON.stringify(user), encryptKey).toString();
}

export const decryptUser = () => {
    let user = JSON.parse(localStorage.getItem("user") ?? "{}");

    let decryptedUser: IUser = {};
    if (!isEmptyOrNullObj(user)) {
        let userBytes = CryptoJS.AES.decrypt(user, encryptKey);
        decryptedUser = JSON.parse(userBytes.toString(CryptoJS.enc.Utf8));
    }

    return decryptedUser;
}

export const getTokens = () => {
    let tokens = decryptTokens('tokens');
    return tokens;
}

export const getUserId = () => {
    let encryptedId = localStorage.getItem('refreshToken');

    if (encryptedId) {
        let id = CryptoJS.AES.decrypt(encryptedId, encryptKey);
        id = JSON.parse(id.toString(CryptoJS.enc.Utf8));
        return id.toString();
    }
}

export const getFromLocalStorage = (key: string) => {
    const data = localStorage.getItem(key);
    return data;
}

export const saveTokens = (token: string) => {
    let _token = encryptTokens(token);
    localStorage.setItem("tokens", JSON.stringify(_token));
}

export const storeLocalStorage = (key: string, data: string) => {
    localStorage.setItem(key, data);
}

export const clearStorage = () => {
    sessionStorage.clear();
    localStorage.clear();
}

function decryptTokens(value: string) {
    let tokens = JSON.parse(localStorage.getItem(value) ?? "{}");

    let token = "";

    if (!isEmptyOrNullObj(tokens)) {
        let jwtBytes = CryptoJS.AES.decrypt(tokens, encryptKey);
        token = JSON.parse(jwtBytes.toString(CryptoJS.enc.Utf8));
    }

    return token
}
function encryptTokens(token: string) {
    let jwtCipherText = CryptoJS.AES.encrypt(JSON.stringify(token), encryptKey).toString();
    return jwtCipherText;
}
