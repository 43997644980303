import { ReactElement, useState, useRef, useEffect } from 'react';
import OpenFolder from '../../../assets/images/builder/OpenFolder';
import './MediaInput.scss';
import { toBase64 } from '../../../helpers/helperfunctions';
import FirebaseFunctions from '../../../helpers/firebaseFunctions';
import { CircularProgress } from '@mui/material';

const MediaInput = (
    { icon, onUpload, sx, imageId }: { sx?: React.CSSProperties, icon?: ReactElement, onUpload: CallableFunction, imageId: string }
) => {
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [url, setUrl] = useState()
    const firebaseFunctions = new FirebaseFunctions();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        onUpload(url);
    }, [url])

    const onButtonClick = () => {
        if (!loading)
            fileInputRef.current?.click(); // Trigger file input click
    };

    const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLoading(true);
        const file = event.target.files?.[0];

        toBase64(file).then((value: any) => {
            firebaseFunctions.addImageFirebase(`${imageId}.jpg`, value).then((link: any) => {
                setUrl(link);
            }).finally(() => {
                setLoading(false);
            })
        })
    };

    const onChange = (val: any) => {
        setUrl(val.target.value);
    };

    return (
        <div style={sx} className="dropdown-container">
            <div className="dropdown-header">
                {icon && (<span className="dropdown-icon">{icon}</span>)}
                <input
                    value={url}
                    type="text"
                    className="text-input"
                    placeholder='Select Media or Add URL'
                    onChange={onChange}
                />
                <div className="vertical-breaker"></div>
                <span className="dropdown-arrow" onClick={onButtonClick}>
                    {loading
                        ? (<CircularProgress color='inherit' size={14} />)
                        : (<OpenFolder />)}
                </span>
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={onFileChange}
                />
            </div>
        </div>
    );
};

export default MediaInput;
