export default class LeadScrollController {
    isDown: boolean;
    startX: any;
    scrollLef: any;

    constructor(isDown: boolean, startX: any, scrollLef: any) {
        this.isDown = isDown;
        this.startX = startX;
        this.scrollLef = scrollLef;
    }

    handleOnMouseDown = (e: any) => {
        const slider = document.getElementById('slider');
        const stroller = document.querySelector('.content-bottom');
        this.isDown = true;
        stroller?.classList.add('content-bottom-grab');
        this.startX = e.pageX - (slider?.offsetLeft ?? 0);
        this.scrollLef = stroller?.scrollLeft;
    }

    handleOnMouseLeave = (e: any) => {
        const stroller = document.querySelector('.content-bottom');
        this.isDown = false;
        stroller?.classList.remove('content-bottom-grab')
    }

    handleOnMouseUp = (e: any) => {
        const stroller = document.querySelector('.content-bottom');
        this.isDown = false;
        stroller?.classList.remove('content-bottom-grab')

    }

    handleOnMouseMove = (e: any) => {
        e.preventDefault();
        const slider = document.getElementById('slider');
        const stroller = document.querySelector('.content-bottom');
        if (!this.isDown) return;
        const x = e.pageX - (slider?.offsetLeft ?? 0);
        const walk = (x - this.startX);
        if (stroller) {
            stroller.scrollLeft = (this.scrollLef - walk);
        }
    }

}