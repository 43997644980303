import React from 'react'
import { ILog } from '../../helpers/Interfaces';
import { formatDate } from '../../helpers/helperfunctions';
import { Circle } from '@mui/icons-material';
import { Box, Skeleton } from '@mui/material';

type LeadLogsProps = {
    divBRef: React.MutableRefObject<HTMLDivElement | null>,
    logs: ILog[]
}

const LogData = (props: { header1?: string, header2?: string, description: string, time?: string }) => {
    return (
        <div className="state">
            <Circle fontSize='small' />
            <span className="fz-14 log-content">
                <b> {props.header1 ?? props.header1}</b> {props.description} <b>{props.header2 ?? props.header2}</b>
            </span>
            {props.time ? (<div className="date-time flex-0-a fz-10 primary-font">{props.time}</div>) : (<></>)}
        </div>
    );
}

const LeadLogs = ({ divBRef, logs }: LeadLogsProps) => {
    return (
        <div ref={divBRef} className="status-sec bdr-10">
            {(logs.length == 0) ? (
                <Box>
                    {Array.from({ length: 6 }, (_, index) => (
                        <Box
                            key={index}
                            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
                            <Skeleton width={'6px'} height={'6px'} variant='circular' />
                            <Skeleton width={'72%'} />
                            <Skeleton width={'22%'} />
                        </Box>
                    ))}
                </Box>
            ) : <></>}
            {logs.map((log) => {
                return (<LogData header1={log.header} header2={log.footer} description={log.body ?? ''} time={formatDate(log.dateTime, 'HH:mm | DD/MM/YYYY')} />)
            })}
        </div>
    )
}

export default LeadLogs
