
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { isProduction } from "../helpers/helperfunctions";
import { getAuth } from 'firebase/auth'

let firebaseConfig = {
    apiKey: "AIzaSyBUry7MEJ1_4lQPYRYUCJE-whV347OqGPQ",
    authDomain: "explor-dev.firebaseapp.com",
    projectId: "explor-dev",
    storageBucket: "explor-dev.appspot.com",
    messagingSenderId: "485874492831",
    appId: "1:485874492831:web:44609930f6a7ac973cc9aa"
};

if (isProduction()) {
    firebaseConfig = {
        apiKey: "AIzaSyBxF1qibGaOYCMeYEjuoTNiM_5M8F9gO8A",
        authDomain: "explor-app.firebaseapp.com",
        projectId: "explor-app",
        storageBucket: "explor-app.appspot.com",
        messagingSenderId: "993437769704",
        appId: "1:993437769704:web:95cb14451159ddc7b8ce5d",
    };
}

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const firestore = getFirestore(app);
const auth = getAuth(app);

export { firestore, storage, auth };