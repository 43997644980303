import React from 'react'

const ClearFormattingIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5761 11.9081C13.9015 11.5827 14.4292 11.5827 14.7546 11.9081L15.832 12.9855L16.9094 11.9081C17.2348 11.5827 17.7625 11.5827 18.0879 11.9081C18.4133 12.2335 18.4133 12.7612 18.0879 13.0866L17.0105 14.164L18.0879 15.2415C18.4133 15.5669 18.4133 16.0945 18.0879 16.42C17.7625 16.7454 17.2348 16.7454 16.9094 16.42L15.832 15.3425L14.7546 16.42C14.4292 16.7454 13.9015 16.7454 13.5761 16.42C13.2507 16.0945 13.2507 15.5669 13.5761 15.2415L14.6535 14.164L13.5761 13.0866C13.2507 12.7612 13.2507 12.2335 13.5761 11.9081Z" fill="#E27C37" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5 4.16536C5 3.70513 5.3731 3.33203 5.83333 3.33203H15C15.4603 3.33203 15.8333 3.70513 15.8333 4.16536V4.9987C15.8333 5.45893 15.4603 5.83203 15 5.83203C14.5398 5.83203 14.1667 5.45893 14.1667 4.9987H6.66667C6.66667 5.45893 6.29357 5.83203 5.83333 5.83203C5.3731 5.83203 5 5.45893 5 4.9987V4.16536Z" fill="#E27C37" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5 15.8333C5 15.3731 5.3731 15 5.83333 15H9.16667C9.62692 15 10 15.3731 10 15.8333C10 16.2936 9.62692 16.6667 9.16667 16.6667H5.83333C5.3731 16.6667 5 16.2936 5 15.8333Z" fill="#E27C37" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.0618 3.36431C11.5044 3.49074 11.7606 3.95198 11.6342 4.39451L8.30083 16.0612C8.1744 16.5037 7.71316 16.76 7.27063 16.6335C6.8281 16.5071 6.57186 16.0458 6.69829 15.6033L10.0317 3.93664C10.1581 3.49412 10.6193 3.23787 11.0618 3.36431Z" fill="#E27C37" />
        </svg>

    )
}

export default ClearFormattingIcon