import React from 'react'

const UserIcon = () => {
    return (<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 6.5625C8.69137 6.5625 10.0625 5.19137 10.0625 3.5C10.0625 1.80863 8.69137 0.4375 7 0.4375C5.30863 0.4375 3.9375 1.80863 3.9375 3.5C3.9375 5.19137 5.30863 6.5625 7 6.5625Z" fill="#E27C37"/>
        <path d="M9.17437 7.4375H4.82562C3.89424 7.43866 3.00134 7.80916 2.34275 8.46775C1.68416 9.12634 1.31366 10.0192 1.3125 10.9506V13.125C1.3125 13.241 1.35859 13.3523 1.44064 13.4344C1.52269 13.5164 1.63397 13.5625 1.75 13.5625H12.25C12.366 13.5625 12.4773 13.5164 12.5594 13.4344C12.6414 13.3523 12.6875 13.241 12.6875 13.125V10.9506C12.6863 10.0192 12.3158 9.12634 11.6573 8.46775C10.9987 7.80916 10.1058 7.43866 9.17437 7.4375Z" fill="#E27C37"/>
        </svg>        
        )
}

export default UserIcon