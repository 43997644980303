import React, { useEffect, useState } from 'react'
import { ICreateLeadData, IItinerary, IStepperItem } from '../../helpers/Interfaces';
import { t } from 'i18next';
import { Skeleton } from '@mui/material';
import Stepper from '../Stepper/Stepper';
import Itinerary from '../../assets/images/lead-card/ItineraryFilter.svg'
import Dropdown from '../Builder/DropDown/DropDown';
import ItineraryCard from '../ItineraryCard/ItineraryCard';
import LeadTrackerFunctions from "../../pages/Dashboard/LeadTracker/LeadFunctions";
import Evidence from '../../assets/images/newLead/evidence.gif'

type GeneralItineraryProps = {
    divARef: React.MutableRefObject<HTMLDivElement | null>
    divBRef: React.MutableRefObject<HTMLDivElement | null>
    stepperSteps: IStepperItem[],
    lead: ICreateLeadData,
    setLead: CallableFunction,
    selectedItinerary?: IItinerary,
    setSelectedItinerary: CallableFunction
}

const GeneralItinerary = ({ divARef, divBRef, stepperSteps, lead, setLead, setSelectedItinerary, selectedItinerary }: GeneralItineraryProps) => {
    const trackerFunctions = new LeadTrackerFunctions();
    const [itineraries, setItineraries] = useState<IItinerary[]>([]);
    const [itineraryLoading, setItineraryLoading] = useState<boolean>(false);

    useEffect(() => {
        trackerFunctions.getAllItineraries(setItineraries, setItineraryLoading)
    }, [])
    
    return (
        <div className="middle">
            <Stepper activeStep={5} divARef={divARef} divBRef={divBRef} steps={stepperSteps} />
            <div className="rht flex-0-a">
                <div className="lead-rht-inner">
                    <div ref={divBRef} className="scroll-div itinerary-outer">
                        <div className="itinerary-inner">
                            <span className="primary-font">SELECT A SAVED ITINERARY & START CUSTOMIZING</span>
                            <Dropdown icon={<img src={Itinerary} />} searchable options={[]} onSelect={() => { }} />
                            <div className="itinerary-scroll">
                                {itineraryLoading
                                    ? (<>
                                        <Skeleton width={'392px'} height={'102px'} variant='rounded' />
                                    </>)
                                    : (itineraries.length > 0
                                        ? (itineraries?.map(item => <ItineraryCard selected={selectedItinerary?.id === item.id} itinerary={item} onClick={(item: IItinerary) => setSelectedItinerary(item)} />))
                                        : (<div className="primary-font">No Itineraries found</div>))}
                            </div>
                        </div>
                        <div className="itinerary-map">
                            {(selectedItinerary)
                                ? (<embed className='pdf-viewer' src={`${selectedItinerary.pdfUrl}#toolbar=0`} type="application/pdf" />)
                                : (<div className='empty-pdf-view'>
                                    <img src={Evidence} alt="" />
                                    <div className="header primary-font">Nothing to Preview</div>
                                    <div className="description primary-font">Select a saved itinerary to preview it here, or you can create one from scratch</div>
                                </div>)}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GeneralItinerary