import { MouseEventHandler } from "react"

const OutdentIcon = (props: { isActive?: boolean, onClick: MouseEventHandler<SVGSVGElement> }) => {
    return (<svg onClick={props.onClick} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.332 15.8346C18.332 16.2949 17.9589 16.668 17.4987 16.668H2.4987C2.03845 16.668 1.66536 16.2949 1.66536 15.8346C1.66536 15.3744 2.03845 15.0013 2.4987 15.0013H17.4987C17.9589 15.0013 18.332 15.3744 18.332 15.8346ZM18.088 11.912C18.4134 12.2375 18.4134 12.7651 18.088 13.0906C17.7625 13.416 17.2349 13.416 16.9094 13.0906L14.4094 10.5906C14.084 10.2651 14.084 9.73747 14.4094 9.41205L16.9094 6.91205C17.2349 6.58663 17.7625 6.58663 18.088 6.91205C18.4134 7.23747 18.4134 7.76513 18.088 8.09055L16.1772 10.0013L18.088 11.912ZM13.332 11.668C13.332 12.1282 12.9589 12.5013 12.4987 12.5013H2.4987C2.03845 12.5013 1.66536 12.1282 1.66536 11.668C1.66536 11.2077 2.03845 10.8346 2.4987 10.8346H12.4987C12.9589 10.8346 13.332 11.2077 13.332 11.668ZM13.332 8.33463C13.332 8.79489 12.9589 9.16797 12.4987 9.16797H2.4987C2.03845 9.16797 1.66536 8.79489 1.66536 8.33463C1.66536 7.87438 2.03845 7.5013 2.4987 7.5013H12.4987C12.9589 7.5013 13.332 7.87438 13.332 8.33463ZM2.4987 3.33463H17.4987C17.9589 3.33463 18.332 3.70772 18.332 4.16797C18.332 4.62822 17.9589 5.0013 17.4987 5.0013H2.4987C2.03845 5.0013 1.66536 4.62822 1.66536 4.16797C1.66536 3.70772 2.03845 3.33463 2.4987 3.33463Z" fill={`${props.isActive ? '#e27c37' : '#251C15'}`} />
    </svg>
    )
}


export default OutdentIcon