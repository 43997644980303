import { ReactElement } from "react"
import './TransparentButton.scss'

type TransparentButtonProps = {
    onClick: () => void,
    label?: string,
    icon: ReactElement,
    active?: boolean,
    separate?: boolean
}

const TransparentButton = ({ label, icon, onClick, active, separate }: TransparentButtonProps) => {
    return (
        <button onClick={onClick} className={`transparent-btn ${separate && 'btn-1'} ${active && 'active'}`} type="button">
            {icon
                && (<span className="icon">
                    {icon}
                </span>)}
            {label ? (<span className="txt fz-16 ttu primary-font">{label}</span>):<></>}
        </button>
    )
}

export default TransparentButton