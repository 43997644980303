import React, { MouseEventHandler } from 'react'

const FullPageIcon = ({ selected, onClick, removeBorder }: { selected?: boolean, onClick: MouseEventHandler<SVGSVGElement> , removeBorder?: boolean}) => {
    return (<svg style={{cursor: 'pointer'}} onClick={onClick} width="143" height="120" viewBox="0 0 143 120" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="142" height="119" rx="9.5" fill={selected ? "#E27C37" : "white"} fill-opacity={selected ?"0.05": "1" } stroke={removeBorder? 'transparent' : (selected ? "#E27C37" : "#CDCDCE")} stroke-opacity="0.4" />
        <path d="M58.6098 102V93.6H62.7498C65.0898 93.6 65.9298 94.416 65.9298 96.012C65.9298 96.732 65.6898 97.308 65.1978 97.716C65.9058 98.124 66.2898 98.748 66.2898 99.564C66.2898 101.172 65.4618 102 62.8938 102H58.6098ZM60.0498 97.14H62.7378C63.7698 97.14 64.4298 96.912 64.4298 96.012C64.4298 95.268 63.7818 94.92 62.7618 94.92H60.0498V97.14ZM60.0498 100.68H62.9058C64.1298 100.68 64.7898 100.404 64.7898 99.564C64.7898 98.832 63.9618 98.46 62.8938 98.46H60.0498V100.68ZM68.5093 102H67.1293V93H68.5093V102ZM74.1114 98.172C74.1114 97.08 73.1634 96.984 72.5034 96.984C71.8194 96.984 70.9434 97.056 70.9434 98.076H69.6834C69.6834 96.456 70.7634 95.82 72.5034 95.82C74.5074 95.82 75.4914 96.456 75.4914 98.376V102H74.1114V101.184C73.8714 101.772 73.3074 102.18 71.9874 102.18C70.4754 102.18 69.3474 101.784 69.3474 100.296C69.3474 99.096 70.2954 98.592 71.9874 98.412L74.1114 98.196V98.172ZM74.1114 99.192L72.1914 99.384C71.4114 99.468 70.7874 99.684 70.7874 100.272C70.7874 100.728 71.1474 101.004 72.1794 101.004C73.5234 101.004 74.0514 100.428 74.1114 99.984V99.192ZM82.6706 102H81.2906V98.772C81.2906 97.752 80.9786 97.104 79.6946 97.104C78.5546 97.104 77.9546 97.752 77.9546 98.988V102H76.5746V96H77.9546V96.84C78.3506 96.192 79.0346 95.82 80.0786 95.82C81.8186 95.82 82.6706 96.612 82.6706 98.652V102ZM85.1265 102H83.7465V93H85.1265V102ZM86.8905 99L89.9865 102H88.2225L85.1265 99L88.2225 96H89.9865L86.8905 99Z" fill="#251C15" />
        <rect x="18.5" y="17.5" width="110" height="62" rx="1.5" fill="white" stroke="black" />
    </svg>
    )
}

export default FullPageIcon