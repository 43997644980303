
import "./Login.scss";
import logoDark from "../../assets/images/logo-dark.svg"

import { useEffect, useState } from "react";
import { ILoginData, IUser } from "../../helpers/Interfaces";
import Functions from './loginFunctions'
import { CircularProgress } from "@mui/material";
import { setUser } from '../../store/slices/userSlice';
import { AppDispatch } from '../../store/store';
import { useDispatch } from "react-redux";
import { storeLocalStorage, saveTokens, saveUser } from "../../helpers/storageConfig";
import { useNavigate } from "react-router-dom";
import { DropdownItemProps } from "../../components/Mui/CustomAutocomplete/CustomAutocomplete";
import LoginForm from "./loginForm";
import CustomCarousel from "../../components/CustomCarousel/CustomCarousel";
import { useTranslation } from "react-i18next";
import '../../i18n/config';
import { setLeadStages } from "../../store/slices/leadStagesSlice";
import { leadTrackerStages } from "../../helpers/constants";
import FirebaseFunctions from "../../helpers/firebaseFunctions";

const Login = () => {
  const { t, i18n } = useTranslation();
  const functions = new Functions();
  const firebaseFunctions = new FirebaseFunctions();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [loginForm, setLoginForm] = useState<ILoginData>({ email: '', password: '', organization: undefined });
  const [tenants, setTenants] = useState<DropdownItemProps[]>();
  const [response, setResponse] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    handleResponse(response);
  }, [response])

  useEffect(() => {
    i18n.changeLanguage(navigator.language)
  }, [])

  const handleResponse = (response: any) => {
    if (response) {

      if (response.tenants.length === 1) return processLogin();
      const form = loginForm;
      form.organization = response.loginTenantId;
      setLoginForm({ ...form });
      setTenants(response.tenants);
    }
  }

  const handleLogin = async (e?: React.FormEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault();
    }

    const data = {
      email: loginForm.email,
      password: loginForm.password,
      tenantId: loginForm.organization
    }

    functions.userLogin(data, setLoading, (data: any) => {
      firebaseFunctions.authenticateUser(data.firebaseCustomToken).then(() => {
        setResponse(data);
      });
    });
  }
  const handleSelect = (value: any) => () => {
    const form = loginForm;
    form.organization = value;
    setLoginForm({ ...form });
    handleLogin();
  };

  const processLogin = async (e?: React.FormEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault();
    }
    const user: IUser = {
      email: response.email,
      displayName: response.name,
      photoUrl: response.photoUrl,
      tenantId: response.loginTenantId,
      language: response.language
    }

    i18n.changeLanguage(response.language)

    saveTokens(response.token);
    storeLocalStorage('refreshToken', response.uid);
    storeLocalStorage('firebaseCustomToken', response.firebaseCustomToken);
    storeLocalStorage('tenantId', response.loginTenantId);
    saveUser(user);
    dispatch(setUser(user));
    dispatch(setLeadStages(response.stages ?? leadTrackerStages));
    storeLocalStorage('leadStages', (response.stages ? response.stages as string[] : leadTrackerStages).join(', '))
    navigate('/dashboard/lead-tracker');
  }

  return (
    <div className="login-outer">
      <div className="lft carousel-outer ">
        <CustomCarousel />
      </div>
      <div className="rht">
        <div className="top">
          <img src={logoDark} alt="logo" className="logo" />
          <h3 className="fz-32 primary-font fw-400 title">{t('loginHeader')}</h3>
          <p className="fz-18">{t('loginDescription')}</p>
          {tenants
            ? (
              <form onSubmit={processLogin}
                className="login-form">
                <h4 className="fz-18 primary-font fw-400 title">Select your organization</h4>

                <div className="form-group">
                  <input
                    required
                    type="text"
                    className="form-control"
                    placeholder="Organization"
                    value={tenants.find(t => t.id === loginForm.organization)?.label}
                    onChange={() => { }}
                    onFocus={() => setShowDropdown(true)}
                    onBlur={() => setTimeout(() => setShowDropdown(false), 200)}
                  />
                  {showDropdown ? <ul className="dropdown-menu show">
                    {tenants.map((item, index) => (
                      <li key={index} className="dropdown-item" onClick={handleSelect(item.id)}>
                        {item.label}
                      </li>
                    ))}
                  </ul> : <></>}
                </div>
                <button disabled={loading} type="submit" className="submit fz-16 primary-font fw-600 ttu c-white">{loading
                  ? (<CircularProgress color="inherit" size={28} />)
                  : ("Continue")}</button>
              </form>
            )
            : (<LoginForm handleLogin={handleLogin} loginForm={loginForm} setLoginForm={setLoginForm} loading={loading} />)}

        </div>
        <div className="bottom">
          <p className="mb-0 fz-14">By signing in and using Explor.App, you agree to our <span className="underline">Terms and Conditions</span> and <span className="underline">Privacy Policy</span>. Please <span className="underline">Contact Support</span> for any assistance.</p>
        </div>
      </div>
    </div>
  )
}

export default Login

