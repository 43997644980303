import React from 'react'

const BottomLeftRadius = () => {
  return (
<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_291_2668" maskUnits="userSpaceOnUse" x="0" y="0" width="21" height="20">
        <path d="M20.5 20L0.5 20L0.500002 -1.74846e-06L20.5 0L20.5 20Z" fill="white" />
    </mask>
    <g mask="url(#mask0_291_2668)">
        <path d="M4.66667 4.16667L3 4.16667L3 2.5L4.66667 2.5L4.66667 4.16667ZM4.66667 5.83333L3 5.83333L3 7.5L4.66667 7.5L4.66667 5.83333ZM18 9.16667L16.3333 9.16667L16.3333 10.8333L18 10.8333L18 9.16667ZM18 5.83333L16.3333 5.83333L16.3333 7.5L18 7.5L18 5.83333ZM18 12.5L16.3333 12.5L16.3333 14.1667L18 14.1667L18 12.5ZM18 15.8333L16.3333 15.8333L16.3333 17.5L18 17.5L18 15.8333ZM14.6667 15.8333L13 15.8333L13 17.5L14.6667 17.5L14.6667 15.8333ZM8 2.5L6.33334 2.5L6.33334 4.16667L8 4.16667L8 2.5ZM11.3333 2.5L9.66667 2.5L9.66667 4.16667L11.3333 4.16667L11.3333 2.5ZM8 2.5L6.33334 2.5L6.33334 4.16667L8 4.16667L8 2.5ZM14.6667 2.5L13 2.5L13 4.16667L14.6667 4.16667L14.6667 2.5ZM18 2.5L16.3333 2.5L16.3333 4.16667L18 4.16667L18 2.5ZM3 13.3333C3 15.6333 4.86667 17.5 7.16667 17.5L11.3333 17.5L11.3333 15.8333L7.16667 15.8333C5.79167 15.8333 4.66667 14.7083 4.66667 13.3333L4.66667 9.16667L3 9.16667L3 13.3333Z" fill="#134B5F" />
    </g>
</svg>
  )
}

export default BottomLeftRadius