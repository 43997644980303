import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './en/translation.json';
import frTranslation from './fr/translation.json';

i18next.use(initReactI18next).init({
    fallbackLng: 'en',
    debug: false,
    resources: {
        en: {
            translation: enTranslation,
        },
        fr: {
            translation: frTranslation
        }
    },
});
