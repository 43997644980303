import React from 'react'
import CustomMultiSelect from '../Mui/CustomMultiSelect/CustomMultiSelect';
import { CustomSelect } from '../Mui/CustomSelect/CustomSelect';
import { ICreateLeadData, IStepperItem } from '../../helpers/Interfaces';
import Stepper from '../Stepper/Stepper';
import { getDefaultValueInDropdown, getMultiSelectDefaultValues, getMultiSelectedItemsString } from '../../helpers/helperfunctions';
import { climatePreferences, culturalInterests, levelOfActivity, preferredLandscapes, preferredPace, travelStyle, typeOfTrip } from '../../helpers/constants';
import CustomTextField from '../Mui/CustomTextField/CustomTextField';
import { t } from 'i18next';

type GeneralPreferencesProps = {
    divARef: React.MutableRefObject<HTMLDivElement | null>
    divBRef: React.MutableRefObject<HTMLDivElement | null>
    stepperSteps: IStepperItem[],
    lead: ICreateLeadData,
    setLead: CallableFunction
}

const GeneralPreferences = ({ divARef, divBRef, stepperSteps, lead, setLead }: GeneralPreferencesProps) => {
    return (
        <div className="middle">
            <Stepper activeStep={4} divARef={divARef} divBRef={divBRef} steps={stepperSteps} />
            <div className="rht flex-0-a">
                <div className="lead-rht-inner">
                    <div ref={divBRef} className="scroll-div">
                        <h4 className="header primary-font fz-16 ttu fw-400">{t('generalPreferences')}</h4>
                        <div className="form-outer items-3 mb-40">
                            <div className="form-group fw-fg">
                                <CustomMultiSelect helperText={t('fieldRequired')} label={t('typeOfTrip')} options={typeOfTrip}
                                    defaultValue={getMultiSelectDefaultValues(typeOfTrip, lead.tripTypes)} onSelect={(value) => {
                                        if (!value) return;
                                        let newLead = lead;
                                        newLead.tripTypes = getMultiSelectedItemsString(value);
                                        setLead({ ...newLead });
                                    }} />
                            </div>
                            <div className="form-group">
                                <CustomSelect
                                    helperText={t('fieldRequired')}
                                    label={t('travelStyle')}
                                    defaultValue={getDefaultValueInDropdown(travelStyle, lead.travelStyle)}
                                    options={travelStyle}
                                    onSelect={(value) => {
                                        if (!value) return;
                                        let newLead = lead;
                                        newLead.travelStyle = value?.label;
                                        setLead({ ...newLead });
                                    }}
                                />
                            </div>
                            <div className="form-group">
                                <CustomSelect
                                    helperText={t('fieldRequired')}
                                    label={t('levelOfActivity')}
                                    defaultValue={getDefaultValueInDropdown(levelOfActivity, lead.levelOfActivity)}
                                    options={levelOfActivity}
                                    onSelect={(value) => {
                                        if (!value) return;
                                        let newLead = lead;
                                        newLead.levelOfActivity = value.label;
                                        setLead({ ...newLead });
                                    }}
                                />
                            </div>
                            <div className="form-group">
                                <CustomSelect
                                    helperText={t('fieldRequired')}
                                    label={t('preferredPace')}
                                    defaultValue={getDefaultValueInDropdown(preferredPace, lead.preferredSpace)}
                                    options={preferredPace}
                                    onSelect={(value) => {
                                        if (!value) return;
                                        let newLead = lead;
                                        newLead.preferredSpace = value.label;
                                        setLead({ ...newLead });
                                    }}
                                />
                            </div>
                            <div className="form-group fw-fg">
                                <CustomTextField defaultValue={lead.languages ? lead.languages[0] : undefined} label={t('preferredLanguage')} size="small" helperText={t('fieldRequired')}
                                    onChange={(val) => {
                                        let newLead = lead;
                                        newLead.languages = [val.target.value]
                                        setLead({ ...newLead });
                                    }} />
                            </div>
                        </div>
                        <h4 className="header primary-font fz-16 ttu fw-400">Destination Preferences</h4>

                        <div className="form-outer items-1">
                            <div className="form-group">
                                <CustomTextField defaultValue={lead.specialLocations ? lead.specialLocations[0] : undefined} label={t('specificLocations')} size="small" helperText={t('fieldRequired')}
                                    onChange={(val) => {
                                        let newLead = lead;
                                        newLead.specialLocations = [val.target.value]
                                        setLead({ ...newLead });
                                    }} />
                            </div>
                            <div className="form-group">
                                <CustomMultiSelect helperText={t('fieldRequired')} label={t('landscapePreferences')} options={preferredLandscapes}
                                    defaultValue={getMultiSelectDefaultValues(preferredLandscapes, lead.specialLandscapes)} onSelect={(value) => {
                                        if (!value) return;
                                        let newLead = lead;
                                        newLead.specialLandscapes = getMultiSelectedItemsString(value);
                                        setLead({ ...newLead });
                                    }} />
                            </div>
                            <div className="form-group">
                                <CustomMultiSelect helperText={t('fieldRequired')} label={t('climatePreferences')} options={climatePreferences}
                                    defaultValue={getMultiSelectDefaultValues(climatePreferences, lead.climatePreferences)} onSelect={(value) => {
                                        if (!value) return;
                                        let newLead = lead;
                                        newLead.climatePreferences = getMultiSelectedItemsString(value);
                                        setLead({ ...newLead });
                                    }} />
                            </div>
                            <div className="form-group">
                                <CustomMultiSelect helperText={t('fieldRequired')} label={t('culturalInterests')} options={culturalInterests}
                                    defaultValue={getMultiSelectDefaultValues(culturalInterests, lead.cultureStructure)} onSelect={(value) => {
                                        if (!value) return;
                                        let newLead = lead;
                                        newLead.cultureStructure = getMultiSelectedItemsString(value);
                                        setLead({ ...newLead });
                                    }} />
                            </div>
                            <div className="form-group">
                                <CustomTextField defaultValue={lead.specificActivities ? lead.specificActivities[0] : undefined} label={t('anySpecificActivities')} size="small" helperText={t('fieldRequired')}
                                    onChange={(val) => {
                                        let newLead = lead;
                                        newLead.specificActivities = [val.target.value]
                                        setLead({ ...newLead });
                                    }} />
                                {/* <CustomMultiSelect helperText={t('fieldRequired')} label="Any Specific Activities?" options={nationality}
                                    defaultValue={getMultiSelectDefaultValues(nationality, lead.specificActivities)} onSelect={(value) => {
                                        if (!value) return;
                                        let newLead = lead;
                                        newLead.specificActivities = getMultiSelectedItemsString(value);
                                        setLead({ ...newLead });
                                    }} /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GeneralPreferences