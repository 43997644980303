import { MouseEventHandler } from "react"

const Checked = (props: { isActive: boolean, onClick: MouseEventHandler<SVGSVGElement> }) => {
    return (<svg onClick={props.onClick} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.83333 17.5H14.1667C15.0507 17.5 15.8986 17.1488 16.5237 16.5237C17.1488 15.8986 17.5 15.0507 17.5 14.1667V10.125C17.5 9.90399 17.4122 9.69202 17.2559 9.53574C17.0996 9.37946 16.8877 9.29167 16.6667 9.29167C16.4457 9.29167 16.2337 9.37946 16.0774 9.53574C15.9211 9.69202 15.8333 9.90399 15.8333 10.125V14.1667C15.8333 14.6087 15.6577 15.0326 15.3452 15.3452C15.0326 15.6577 14.6087 15.8333 14.1667 15.8333H5.83333C5.39131 15.8333 4.96738 15.6577 4.65482 15.3452C4.34226 15.0326 4.16667 14.6087 4.16667 14.1667V5.83333C4.16667 5.39131 4.34226 4.96738 4.65482 4.65482C4.96738 4.34226 5.39131 4.16667 5.83333 4.16667H10C10.221 4.16667 10.433 4.07887 10.5893 3.92259C10.7455 3.76631 10.8333 3.55435 10.8333 3.33333C10.8333 3.11232 10.7455 2.90036 10.5893 2.74408C10.433 2.5878 10.221 2.5 10 2.5H5.83333C4.94928 2.5 4.10143 2.85119 3.47631 3.47631C2.85119 4.10143 2.5 4.94928 2.5 5.83333V14.1667C2.5 15.0507 2.85119 15.8986 3.47631 16.5237C4.10143 17.1488 4.94928 17.5 5.83333 17.5Z" fill={`${props.isActive ? '#e27c37' : '#251C15'}`} />
        <path d="M16.9065 3.5732L9.9982 10.4899L7.25653 7.73987C7.09961 7.58295 6.88679 7.49479 6.66487 7.49479C6.44295 7.49479 6.23012 7.58295 6.0732 7.73987C5.91628 7.89679 5.82812 8.10962 5.82812 8.33153C5.82812 8.44142 5.84977 8.55022 5.89182 8.65174C5.93387 8.75326 5.9955 8.8455 6.0732 8.9232L9.40653 12.2565C9.484 12.3346 9.57617 12.3966 9.67772 12.4389C9.77927 12.4813 9.88819 12.503 9.9982 12.503C10.1082 12.503 10.2171 12.4813 10.3187 12.4389C10.4202 12.3966 10.5124 12.3346 10.5899 12.2565L18.0899 4.75653C18.2468 4.59962 18.3349 4.38679 18.3349 4.16487C18.3349 3.94295 18.2468 3.73012 18.0899 3.5732C17.9329 3.41628 17.7201 3.32812 17.4982 3.32812C17.2763 3.32813 17.0635 3.41628 16.9065 3.5732Z" fill={`${props.isActive ? '#e27c37' : '#251C15'}`} />
    </svg>
    )
}


export default Checked