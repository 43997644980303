import { ReactElement } from 'react'
import './SecondaryButton.scss'
import { CircularProgress } from '@mui/material'

type SecondaryButtonProps = {
    onClick: () => void,
    label: string,
    icon?: ReactElement,
    loading?: boolean
}

const SecondaryButton = ({ onClick, label, icon, loading }: SecondaryButtonProps) => {
    return (
        <button disabled={loading ?? false} onClick={onClick} className="button border-btn" type="button">
            {icon
                ? (<span className="icon">
                    {icon}
                </span>)
                : (<></>)}
            <span className="txt fz-16 ttu primary-font">{loading ? (<CircularProgress size={16} color='inherit' />) : (label)}</span>
        </button>
    )
}

export default SecondaryButton