import { useState } from 'react'
import RedoSvg from '../../../../assets/images/builder/redoSvg.svg'
import UndoSvg from '../../../../assets/images/builder/undoSvg.svg'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import ToolbarPlugins from '../plugins/ToolbarPlugin';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import TableChartIcon from '@mui/icons-material/TableChart';
import PhotoIcon from '@mui/icons-material/Photo';
import { DropDownMenu } from '../../../../components/Builder/DropDownMenu/DropDownMenu';

const MenuControls = () => {
    const [editor] = useLexicalComposerContext();

    const toolbar = new ToolbarPlugins({ editor });
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleImage = () => {
        handleClose();
    };

    const handleInlineImage = () => {
        handleClose();
    };

    const handleTable = () => {
        handleClose();
    };

    const handleColumn = () => {
        handleClose();
    };

    const menuItems = [{ icon: <PhotoIcon />, action: handleImage, text: 'Image' },
    { icon: <PhotoIcon />, action: handleInlineImage, text: 'Inline Image' },
    { icon: <TableChartIcon />, action: handleTable, text: 'Table' },
    { icon: <ViewWeekIcon />, action: handleColumn, text: 'Column Layout' }
    ]

    return (
        <div className='editor-controls'>
            <div className="add-new">
                {/* <BorderlessButton label={('addNew')} icon={<PlusWithBorder />} onClick={handleClick} /> */}
            </div>
            <DropDownMenu anchorEl={anchorEl} handleClose={handleClose} open={open} menuItems={menuItems} />
            <div className="controls-outer">
                <img onClick={toolbar.undo} src={UndoSvg} alt="" />
                <img onClick={toolbar.redo} src={RedoSvg} alt="" />
            </div>
        </div>
    )
}

export default MenuControls