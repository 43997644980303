import React from 'react'

const OrientationIcon = () => {
    return (<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.334 6.66797H2.6673C2.3138 6.66838 1.9749 6.80899 1.72495 7.05896C1.47499 7.30892 1.33439 7.64782 1.33398 8.00131V13.3347C1.33439 13.6882 1.475 14.0271 1.72496 14.277C1.97492 14.527 2.31383 14.6676 2.66733 14.668H13.334C13.6875 14.6676 14.0264 14.527 14.2764 14.277C14.5263 14.0271 14.6669 13.6882 14.6673 13.3347V8.00131C14.6669 7.64781 14.5263 7.30891 14.2764 7.05894C14.0264 6.80898 13.6875 6.66837 13.334 6.66797Z" fill="#251C15" fill-opacity="0.6" />
        <path d="M2.00064 5.99869C2.17745 5.99869 2.34702 5.92845 2.47204 5.80343C2.59706 5.67841 2.6673 5.50884 2.6673 5.33203V2.66534H8.00064V5.332C8.00064 5.50881 8.07088 5.67838 8.1959 5.80341C8.32093 5.92843 8.4905 5.99867 8.66731 5.99867C8.84412 5.99867 9.0137 5.92843 9.13872 5.80341C9.26375 5.67838 9.33398 5.50881 9.33398 5.332V2.66534C9.33357 2.31185 9.19296 1.97295 8.943 1.723C8.69304 1.47304 8.35414 1.33244 8.00064 1.33203H2.6673C2.31381 1.33244 1.97491 1.47305 1.72496 1.72301C1.475 1.97296 1.3344 2.31185 1.33398 2.66534V5.332C1.33398 5.41955 1.35122 5.50624 1.38472 5.58713C1.41822 5.66801 1.46733 5.74151 1.52923 5.80342C1.59114 5.86533 1.66463 5.91443 1.74552 5.94794C1.8264 5.98144 1.91309 5.99869 2.00064 5.99869Z" fill="#251C15" fill-opacity="0.6" />
    </svg>
    )
}

export default OrientationIcon