const ItinerariesSvg = () => (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_574_272)">
        <path d="M9.97927 17.931H4.82755C2.92617 17.931 1.37927 16.3841 1.37927 14.4828V3.44827C1.37927 1.5469 2.92617 0 4.82755 0H13.1034C15.0048 0 16.5517 1.5469 16.5517 3.44827V9.69655C16.5517 10.0772 16.2427 10.3862 15.862 10.3862C15.4813 10.3862 15.1724 10.0772 15.1724 9.69655V3.44827C15.1724 2.30759 14.2441 1.37931 13.1034 1.37931H4.82755C3.68686 1.37931 2.75858 2.30759 2.75858 3.44827V14.4828C2.75858 15.6234 3.68686 16.5517 4.82755 16.5517H9.97927C10.36 16.5517 10.6689 16.86 10.6689 17.2414C10.6689 17.6228 10.36 17.931 9.97927 17.931Z" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M14.8641 19.9276C14.9607 19.9759 15.0669 20 15.1724 20C15.2779 20 15.3834 19.9759 15.4807 19.9276C15.6089 19.8635 18.6207 18.3207 18.6207 15.1724C18.6207 13.271 17.0738 11.7242 15.1724 11.7242C13.271 11.7242 11.7241 13.271 11.7241 15.1724C11.7241 18.3214 14.7358 19.8635 14.8641 19.9276ZM16.9661 15.1724C16.9661 16.1631 16.1631 16.9662 15.1724 16.9662C14.1817 16.9662 13.3787 16.1631 13.3787 15.1724C13.3787 14.1818 14.1817 13.3787 15.1724 13.3787C16.1631 13.3787 16.9661 14.1818 16.9661 15.1724Z" fill="white" />
        <path d="M10.0621 13.7931H9.65517C9.27448 13.7931 8.96552 13.4848 8.96552 13.1034C8.96552 12.7221 9.27448 12.4138 9.65517 12.4138H10.0621C10.4428 12.4138 10.7517 12.7221 10.7517 13.1034C10.7517 13.4848 10.4428 13.7931 10.0621 13.7931Z" fill="white" />
        <path d="M5.71723 14.6552H5.70344C5.51378 14.651 5.33447 14.5696 5.20689 14.4296L4.31723 13.4517C4.06068 13.1703 4.08137 12.7338 4.36344 12.4772C4.64482 12.2214 5.08137 12.2421 5.33792 12.5234L5.73723 12.9627L7.09516 11.5848C7.36275 11.3131 7.7993 11.311 8.07102 11.5779C8.34206 11.8455 8.34551 12.282 8.07792 12.5531L6.20895 14.4496C6.0793 14.5807 5.90206 14.6552 5.71792 14.6552H5.71723Z" fill="white" />
        <path d="M13.1034 9.65516H9.65517C9.27448 9.65516 8.96552 9.34619 8.96552 8.9655C8.96552 8.58481 9.27448 8.27585 9.65517 8.27585H13.1034C13.4841 8.27585 13.7931 8.58481 13.7931 8.9655C13.7931 9.34619 13.4841 9.65516 13.1034 9.65516Z" fill="white" />
        <path d="M5.71723 10.5173H5.70344C5.51447 10.5131 5.33447 10.4317 5.20689 10.2917L4.31723 9.3145C4.06068 9.03312 4.08137 8.59657 4.36344 8.34002C4.64482 8.08278 5.08137 8.10485 5.33792 8.38623L5.73723 8.82485L7.09516 7.44692C7.36275 7.17588 7.7993 7.17313 8.07102 7.44002C8.34206 7.70761 8.34551 8.14416 8.07792 8.41588L6.20895 10.3124C6.0793 10.4435 5.90206 10.518 5.71792 10.518L5.71723 10.5173Z" fill="white" />
        <path d="M13.1034 5.51725H9.65517C9.27448 5.51725 8.96552 5.20828 8.96552 4.82759C8.96552 4.4469 9.27448 4.13794 9.65517 4.13794H13.1034C13.4841 4.13794 13.7931 4.4469 13.7931 4.82759C13.7931 5.20828 13.4841 5.51725 13.1034 5.51725Z" fill="white" />
        <path d="M5.71723 6.37933H5.70344C5.51447 6.37519 5.33447 6.29381 5.20689 6.15381L4.31723 5.17657C4.06068 4.89519 4.08137 4.45864 4.36344 4.20209C4.64482 3.94485 5.08137 3.9676 5.33792 4.24829L5.73723 4.68691L7.09516 3.30898C7.36275 3.03864 7.7993 3.0345 8.07102 3.30209C8.34206 3.56967 8.34551 4.00623 8.07792 4.27795L6.20895 6.1745C6.0793 6.30622 5.90206 6.38002 5.71792 6.38002L5.71723 6.37933Z" fill="white" />
    </g>
    <defs>
        <clipPath id="clip0_574_272">
            <rect width="20" height="20" fill="white" />
        </clipPath>
    </defs>
</svg>);

export default ItinerariesSvg