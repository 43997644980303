import { MouseEventHandler } from "react"

const CrossCharacterIcon = (props: { isActive: boolean, onClick: MouseEventHandler<SVGSVGElement> }) => {
  return (<svg onClick={props.onClick} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_5186_13182)">
      <path d="M19.3327 10C19.3327 9.73478 19.2273 9.48043 19.0398 9.29289C18.8523 9.10536 18.5979 9 18.3327 9H1.66602C1.4008 9 1.14645 9.10536 0.958909 9.29289C0.771373 9.48043 0.666016 9.73478 0.666016 10C0.666016 10.2652 0.771373 10.5196 0.958909 10.7071C1.14645 10.8946 1.4008 11 1.66602 11H18.3327C18.5979 11 18.8523 10.8946 19.0398 10.7071C19.2273 10.5196 19.3327 10.2652 19.3327 10Z" fill={`${props.isActive ? '#e27c37' : '#251C15'}`} />
      <path d="M9.99935 2.66852C10.7517 2.65621 11.4858 2.90094 12.0802 3.36229C12.6746 3.82363 13.0939 4.47398 13.2687 5.20585C13.2888 5.3405 13.3362 5.46961 13.408 5.58529C13.4798 5.70096 13.5744 5.80077 13.6861 5.87858C13.7979 5.95639 13.9243 6.01057 14.0577 6.0378C14.191 6.06503 14.3286 6.06474 14.4619 6.03695C14.5951 6.00915 14.7213 5.95444 14.8327 5.87616C14.9441 5.79788 15.0383 5.69768 15.1096 5.5817C15.1809 5.46572 15.2278 5.33641 15.2473 5.20168C15.2669 5.06695 15.2587 4.92965 15.2233 4.79819C14.9551 3.61676 14.2905 2.56275 13.3401 1.81144C12.3897 1.06013 11.2108 0.656801 9.99935 0.66852C8.60753 0.64837 7.26451 1.18094 6.26474 2.14946C5.26497 3.11799 4.69005 4.44343 4.66602 5.83519C4.6669 6.71308 4.89878 7.57528 5.33835 8.33519H7.96102C7.56359 8.04985 7.23915 7.6747 7.01412 7.24027C6.78909 6.80585 6.66982 6.32442 6.66602 5.83519C6.68967 4.97376 7.05376 4.15681 7.67853 3.56327C8.3033 2.96974 9.13784 2.648 9.99935 2.66852Z" fill={`${props.isActive ? '#e27c37' : '#251C15'}`} />
      <path d="M10.0005 17.3346C9.24817 17.3469 8.51413 17.1022 7.91969 16.6409C7.32525 16.1795 6.90599 15.5292 6.7312 14.7973C6.71107 14.6627 6.66366 14.5335 6.59188 14.4179C6.5201 14.3022 6.42545 14.2024 6.31374 14.1246C6.20203 14.0468 6.07561 13.9926 5.94222 13.9654C5.80883 13.9381 5.67129 13.9384 5.53802 13.9662C5.40475 13.994 5.27856 14.0487 5.16717 14.127C5.05579 14.2053 4.96157 14.3055 4.89028 14.4215C4.81898 14.5374 4.77212 14.6668 4.75256 14.8015C4.733 14.9362 4.74116 15.0735 4.77653 15.205C5.04477 16.3864 5.70934 17.4404 6.65974 18.1917C7.61014 18.943 8.78909 19.3464 10.0005 19.3346C11.3924 19.3548 12.7354 18.8222 13.7351 17.8537C14.7349 16.8852 15.3098 15.5597 15.3339 14.168C15.333 13.2901 15.1011 12.4279 14.6615 11.668H12.0389C12.4363 11.9533 12.7607 12.3285 12.9858 12.7629C13.2108 13.1973 13.3301 13.6787 13.3339 14.168C13.3102 15.0294 12.9461 15.8463 12.3213 16.4399C11.6966 17.0334 10.862 17.3552 10.0005 17.3346Z" fill={`${props.isActive ? '#e27c37' : '#251C15'}`} />
    </g>
    <defs>
      <clipPath id="clip0_5186_13182">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
  )
}

export default CrossCharacterIcon