import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { useCallback } from 'react';
import AccordionDownIcon from '../../../../assets/images/AccordionDownIcon';
import DocumentIcon from '../../../../assets/images/builder/DocumentIcon';
import PageSettingsIcon from '../../../../assets/images/builder/PageSettingsIcon';
import MarginsIcon from '../../../../assets/images/builder/MarginsIcon';
import FullPageIcon from '../../../../assets/images/builder/PageSettings/FullPageIcon';
import SplitPageIcon from '../../../../assets/images/builder/PageSettings/SplitPageIcon';
import OverlayPageIcon from '../../../../assets/images/builder/PageSettings/OverlayPageIcon';
import { IBuilderView, IItinerary } from '../../../../helpers/Interfaces';
import MarginLeft from '../../../../assets/images/builder/PaddingLeft';
import MarginTop from '../../../../assets/images/builder/PaddingTop';
import MarginRight from '../../../../assets/images/builder/PaddingRight';
import MarginBottom from '../../../../assets/images/builder/PaddingBottom';
import Dropdown from '../../../../components/Builder/DropDown/DropDown';
import { fontFamilies, fontSizes, toolbarPaddings } from '../plugins/ToolbarPlugin';
import ResolutionIcon from '../../../../assets/images/builder/ResolutionIcon'
import OrientationIcon from '../../../../assets/images/builder/OrientationIcon'
import TextInput from '../../../../components/Builder/TextInput/TextInput';
import BackgroundColorPickIcon from '../../../../assets/images/builder/BackgroundColorPickIcon';
import LeftImage from "../../../../assets/images/image-left.svg"
import RightImage from "../../../../assets/images/image-right.svg"
import MediaInput from '../../../../components/Builder/MediaInput/MediaInput';
import Image from '../../../../assets/images/builder/Image';
import OpacityIcon from '../../../../assets/images/builder/OpacityIcon';
import AddImage from '../../../../assets/images/builder/AddImage';
import { changeColorOpacity, getOpacityFromColor } from '../../../../helpers/helperfunctions';
import AlignCenterIcon from '../../../../assets/images/builder/AlignCenterIcon';
import AlignJustifyIcon from '../../../../assets/images/builder/AlignJustifyIcon';
import AlignLeftIcon from '../../../../assets/images/builder/AlignLeftIcon';
import AlignRightIcon from '../../../../assets/images/builder/AlignRightIcon';
import BoldIcon from '../../../../assets/images/builder/BoldIcon';
import ColorPickIcon from '../../../../assets/images/builder/ColorPickIcon';
import CrossCharacterIcon from '../../../../assets/images/builder/CrossCharacterIcon';
import FontIcon from '../../../../assets/images/builder/FontIcon';
import FontSizeIcon from '../../../../assets/images/builder/FontSizeIcon';
import ItalicIcon from '../../../../assets/images/builder/ItalicIcon';
import UnderlineIcon from '../../../../assets/images/builder/UnderlineIcon';
import CapitalizeTIcon from '../../../../assets/images/builder/CapitalizeTIcon';
import { title } from 'process';
import BackgroundIcon from '../../../../assets/images/builder/BackgroundIcon';
import ContentIcon from '../../../../assets/images/builder/ContentIcon';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import Positioning from '../../../../assets/images/builder/Positioning';
import BorderRadiusIcon from '../../../../assets/images/builder/BorderRadiusIcon';
import BottomRightRadius from '../../../../assets/images/builder/BottomRightRadius';
import BottomLeftRadius from '../../../../assets/images/builder/BottomLeftRadius';
import TopLeftRadius from '../../../../assets/images/builder/TopLeftRadius';
import TopRightRadius from '../../../../assets/images/builder/TopRightRadius';


const Layout = ({ layout, setLayout, accordion, setAccordion, itineraryId, itinerary }: { itinerary: IItinerary, layout: IBuilderView, setLayout: CallableFunction, accordion: any, setAccordion: CallableFunction, itineraryId: string }) => {

  const changePadding = useCallback(({ left, right, top, bottom }: { left?: string, right?: string, top?: string, bottom?: string }) => {

    setLayout((prevLayout: any) => ({
      ...prevLayout,
      editor: {
        ...prevLayout.editor,
        leftPadding: left ?? prevLayout.editor.leftPadding,
        rightPadding: right ?? prevLayout.editor.rightPadding,
        topPadding: top ?? prevLayout.editor.topPadding,
        bottomPadding: bottom ?? prevLayout.editor.bottomPadding,
      }
    }));
  }, [setLayout]);

  const changeSplitPadding = useCallback(({ left, right, top, bottom }: { left?: number, right?: string, top?: number, bottom?: string }) => {
    const [height, width] = (itinerary?.resolution ?? '420x594').split('x').map(Number);

    setLayout((prevLayout: any) => ({
      ...prevLayout,
      image: {
        ...prevLayout.image,
        leftSplitPadding: ((width / 200) * (left ?? 0)) ?? prevLayout?.image.leftSplitPadding,
        rightSplitPadding: right ?? prevLayout?.image.rightSplitPadding,
        topSplitPadding: ((height / 100) * (top ?? 0)) ?? prevLayout?.image.topSplitPadding,
        bottomSplitPadding: bottom ?? prevLayout?.image.bottomSplitPadding,
      }
    }));
  }, [setLayout]);

  return (
    <>
      <div className="tab-main-container ">
        <Accordion expanded={accordion.document} onChange={() => setAccordion({ ...accordion, document: !accordion.document })} classes={{ root: 'accordion-container' }}>
          <AccordionSummary
            expandIcon={<AccordionDownIcon />}
            aria-controls="panel1-content"
            id="panel1-header">
            <DocumentIcon />
            Document Settings
          </AccordionSummary>
          <AccordionDetails>
            <div className="control-row">
              <div className="document-card">
                <span className="icon"><OrientationIcon /></span>
                <span className='title'>Horizontal</span>
              </div>
              <div className="document-card">
                <span className="icon"><ResolutionIcon /></span>
                <span className='title'>{itinerary?.resolution} px</span>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={accordion.page} onChange={() => setAccordion({ ...accordion, page: !accordion.page })} classes={{ root: 'accordion-container' }}>
          <AccordionSummary
            expandIcon={<AccordionDownIcon />}
            aria-controls="panel1-content"
            id="panel1-header">
            <PageSettingsIcon />
            Page Settings
          </AccordionSummary>
          <AccordionDetails>
            <div className="control-row">
              <FullPageIcon selected={layout?.view?.blank} onClick={() => setLayout({ ...layout, view: { blank: true, split: false, overlay: false } })} />
              <SplitPageIcon selected={layout?.view?.split} onClick={() => setLayout({ ...layout, view: { blank: false, split: true, overlay: false } })} />
              <OverlayPageIcon selected={layout?.view?.overlay} onClick={() => setLayout({ ...layout, view: { blank: false, split: false, overlay: true } })} />
            </div>
            {layout?.view?.split
              ? (<div className='image-align-outer'>
                <button onClick={() => setLayout({ ...layout, canvas: { ...layout.canvas, split: false } })} className={`image-alignment ${!layout?.canvas?.split ? 'active' : ''}`}>
                  <img src={LeftImage} rel='LeftImage' />
                  <span>Image On Left</span>
                </button>
                <button onClick={() => setLayout({ ...layout, canvas: { ...layout.canvas, split: true } })} className={`image-alignment  ${layout?.canvas?.split ? 'active' : ''}`}>
                  <img src={RightImage} rel='LeftImage' />
                  <span>Image On Right</span>
                </button>
              </div>)
              : (<></>)}
          </AccordionDetails>
        </Accordion >
        <Accordion expanded={accordion.background} onChange={() => setAccordion({ ...accordion, background: !accordion.background })} classes={{ root: 'accordion-container' }}>
          <AccordionSummary
            expandIcon={<AccordionDownIcon />}
            aria-controls="panel1-content"
            id="panel1-header">
            {(layout?.view?.split)
              ? (<>
                <ContentIcon />
                Content Area
              </>)
              : (layout?.view?.overlay)
                ? (<>
                  <InsertPhotoIcon />
                  Background Image</>)
                : (<>
                  <BackgroundIcon />
                  Background</>)
            }
          </AccordionSummary>
          <AccordionDetails>
            <div className="control-row">
              <TextInput defaultValue={layout?.editor?.overlayColor} value={layout?.editor?.overlayColor} type='color' sx={{ maxWidth: '100%' }} onChange={(val: any) => setLayout({ ...layout, editor: { ...layout.editor, overlayColor: val.target.value } })} icon={<BackgroundColorPickIcon />} />
              <TextInput defaultValue={getOpacityFromColor(layout?.editor?.overlayColor)} onChange={(val: any) => setLayout({ ...layout, editor: { ...layout.editor, overlayColor: changeColorOpacity(layout?.editor?.overlayColor ?? '', val.target.value) } })} icon={<OpacityIcon />} type='range' sx={{ maxWidth: '40%' }} max={100} min={0} />
            </div>

            {(!layout?.view?.split)
              ? (<>
                <hr />
                <div className="control-row">
                  <MediaInput
                    imageId={`${itineraryId}-lexical-background-${layout.id}`}
                    sx={{ width: '100%' }}
                    onUpload={(val: any) => {
                      if (val) {
                        setLayout({ ...layout, image: { ...layout?.image, imageUrl: val } })
                      }
                    }}
                    icon={<Image />} />
                  <TextInput defaultValue={(layout?.image?.imageOpacity ?? 100)} onChange={(val: any) => setLayout({ ...layout, image: { ...layout?.image, imageOpacity: 1 - ((100 - val.target.value) / 100) } })} icon={<OpacityIcon />} placeholder='100%' type='range' sx={{ maxWidth: '40%' }} max={100} min={0} />
                </div>
                <div className="centered-container">
                  {(layout?.image?.imageUrl)
                    ? (<img src={layout?.image?.imageUrl} />)
                    : (<AddImage />)}
                </div>
              </>)
              : (<></>)}
            {(!layout?.view?.overlay)
              ? (<> <div className="inner-title">
                <MarginsIcon />
                Margin
              </div>
                <div className="control-row control-row-margin">
                  <Dropdown hideSuffix sx={{ width: '100%' }} defaultValue={layout?.editor?.topPadding} onSelect={(val: any) => changePadding({ top: val.value })} icon={<MarginTop />} options={toolbarPaddings()} />
                  <Dropdown hideSuffix sx={{ width: '100%' }} defaultValue={layout?.editor?.rightPadding} onSelect={(val: any) => changePadding({ right: val.value })} icon={<MarginRight />} options={toolbarPaddings()} />
                  <Dropdown hideSuffix sx={{ width: '100%' }} defaultValue={layout?.editor?.bottomPadding} onSelect={(val: any) => changePadding({ bottom: val.value })} icon={<MarginBottom />} options={toolbarPaddings()} />
                  <Dropdown hideSuffix sx={{ width: '100%' }} defaultValue={layout?.editor?.leftPadding} onSelect={(val: any) => changePadding({ left: val.value })} icon={<MarginLeft />} options={toolbarPaddings()} />
                </div></>) : (<></>)}

          </AccordionDetails>
        </Accordion>
        {(layout?.view?.split)
          ? (<Accordion expanded={accordion.imageView} onChange={() => setAccordion({ ...accordion, imageView: !accordion.imageView })} classes={{ root: 'accordion-container' }}>
            <AccordionSummary
              expandIcon={<AccordionDownIcon />}
              aria-controls="panel1-content"
              id="panel1-header">
              <InsertPhotoIcon />
              Image Area
            </AccordionSummary>
            <AccordionDetails>
              <div className="control-row">
                <TextInput type='color' defaultValue={layout?.image?.backgroundColor} sx={{ maxWidth: '100%' }} onChange={(val: any) => setLayout({ ...layout, image: { ...layout?.image, backgroundColor: val.target.value } })} icon={<BackgroundColorPickIcon />} />
                <TextInput defaultValue={getOpacityFromColor(layout?.image?.backgroundColor)} onChange={(val: any) => setLayout({ ...layout, image: { ...layout?.image, backgroundColor: changeColorOpacity(layout?.image?.backgroundColor ?? '', val.target.value) } })} icon={<OpacityIcon />} placeholder='100%' type='range' sx={{ maxWidth: '40%' }} max={100} min={0} />
              </div>
              <hr />
              <div className="control-row">
                <MediaInput
                  imageId={`${itineraryId}-lexical-background-${layout.id}`}
                  sx={{ width: '100%' }}
                  onUpload={(val: any) => {
                    if (val) {
                      setLayout({ ...layout, image: { ...layout?.image, imageUrl: val } })
                    }
                  }}
                  icon={<Image />} />
                <TextInput defaultValue={((layout?.image?.imageOpacity ?? 100))} onChange={(val: any) => setLayout({ ...layout, image: { ...layout?.image, imageOpacity: 1 - ((100 - val.target.value) / 100) } })} icon={<OpacityIcon />} placeholder='100%' type='range' sx={{ maxWidth: '40%' }} max={100} min={0} />
              </div>
              <div className="centered-container">
                {(layout?.image?.imageUrl)
                  ? (<img src={layout?.image?.imageUrl} />)
                  : (<AddImage />)}

              </div>
              <div className="inner-title">
                <MarginsIcon />
                Margins
              </div>
              <div className="control-row control-row-margin">
                <TextInput defaultValue={layout?.image?.topSplitPadding ?? '0'} onChange={(val: any) => changeSplitPadding({ top: val.target.value })} icon={<MarginTop />} placeholder='100%' type='range' sx={{ maxWidth: '100%' }} max={100} min={0} />
                <TextInput defaultValue={layout?.image?.leftSplitPadding ?? '0'} onChange={(val: any) => changeSplitPadding({ left: val.target.value })} icon={<MarginLeft />} placeholder='100%' type='range' sx={{ maxWidth: '100%' }} max={100} min={0} />
                {/* <Dropdown hideSuffix sx={{ width: '100%' }} defaultValue={layout?.image?.topSplitPadding ?? '0'} onSelect={(val: any) => changeSplitPadding({ top: val.value })} icon={<MarginTop />} options={toolbarPaddings()} /> */}
                {/* <Dropdown hideSuffix sx={{ width: '100%' }} defaultValue={layout?.image?.rightSplitPadding ?? '0'} onSelect={(val: any) => changeSplitPadding({ right: val.value })} icon={<MarginRight />} options={toolbarPaddings()} />
                <Dropdown hideSuffix sx={{ width: '100%' }} defaultValue={layout?.image?.bottomSplitPadding ?? '0'} onSelect={(val: any) => changeSplitPadding({ bottom: val.value })} icon={<MarginBottom />} options={toolbarPaddings()} />
                <Dropdown hideSuffix sx={{ width: '100%' }} defaultValue={layout?.image?.leftSplitPadding ?? '0'} onSelect={(val: any) => changeSplitPadding({ left: val.value })} icon={<MarginLeft />} options={toolbarPaddings()} />  */}
              </div>
              <div className="inner-title">
                <CapitalizeTIcon />
                Page Title & SubTitle
              </div>
              <TextInput defaultValue={layout.image?.title?.text} onChange={(val: any) => setLayout({ ...layout, image: { ...layout.image, title: { ...layout.image?.title, text: val.target.value } } })} icon={<CapitalizeTIcon color="#134B5F" />} placeholder='Add Title Content' sx={{ marginBottom: "16px" }} />
              <div className="control-row" style={{ marginBottom: "16px" }}>
                <Dropdown sx={{ width: '100%' }} searchable icon={<FontIcon />} options={fontFamilies()} defaultValue={layout.image?.title?.fontFamily} onSelect={(val) => setLayout({ ...layout, image: { ...layout.image, title: { ...layout.image?.title, fontFamily: val.value } } })} />
                <Dropdown sx={{ maxWidth: '140px' }} icon={<FontSizeIcon />} options={fontSizes()} defaultValue={layout.image?.title?.fontSize} onSelect={(val) => setLayout({ ...layout, image: { ...layout.image, title: { ...layout.image?.title, fontSize: val.value } } })} />
              </div>
              <div className="control-row">
                <div className='control-custom'>
                  <BoldIcon isActive={layout.image?.title?.bold ?? false} onClick={() => setLayout({ ...layout, image: { ...layout.image, title: { ...layout.image?.title, bold: !(layout.image?.title?.bold) } } })} />
                  <ItalicIcon isActive={layout.image?.title?.italic ?? false} onClick={() => setLayout({ ...layout, image: { ...layout.image, title: { ...layout.image?.title, italic: !(layout.image?.title?.italic) } } })} />
                  <UnderlineIcon isActive={layout.image?.title?.underLine ?? false} onClick={() => setLayout({ ...layout, image: { ...layout.image, title: { ...layout.image?.title, underLine: !(layout.image?.title?.underLine) } } })} />
                  <CrossCharacterIcon isActive={layout.image?.title?.strickThrough ?? false} onClick={() => setLayout({ ...layout, image: { ...layout.image, title: { ...layout.image?.title, strickThrough: !(layout.image?.title?.strickThrough) } } })} />
                </div>
                <div className='control-custom'>
                  <AlignLeftIcon isActive={layout.image?.title?.align === 'left'} onClick={() => setLayout({ ...layout, image: { ...layout.image, title: { ...layout.image?.title, align: 'left' } } })} />
                  <AlignCenterIcon isActive={layout.image?.title?.align === 'center'} onClick={() => setLayout({ ...layout, image: { ...layout.image, title: { ...layout.image?.title, align: 'center' } } })} />
                  <AlignRightIcon isActive={layout.image?.title?.align === 'right'} onClick={() => setLayout({ ...layout, image: { ...layout.image, title: { ...layout.image?.title, align: 'right' } } })} />
                  <AlignJustifyIcon isActive={layout.image?.title?.align === 'justify'} onClick={() => setLayout({ ...layout, image: { ...layout.image, title: { ...layout.image?.title, align: 'justify' } } })} />
                </div>
                <TextInput defaultValue={layout.image?.title?.color} type='color' sx={{ width: '30%' }} onChange={(val: any) => setLayout({ ...layout, image: { ...layout.image, title: { ...layout.image?.title, color: val.target.value } } })} icon={<ColorPickIcon />} />
              </div>
              <hr />
              <TextInput defaultValue={layout.image?.subTitle?.text} onChange={(val: any) => setLayout({ ...layout, image: { ...layout.image, subTitle: { ...layout.image?.subTitle, text: val.target.value } } })} icon={<FontSizeIcon />} placeholder='Add Subtitle Content' sx={{ marginBottom: "16px" }} />
              <div className="control-row" style={{ marginBottom: "16px" }}>
                <Dropdown sx={{ width: '100%' }} searchable icon={<FontIcon />} options={fontFamilies()} defaultValue={layout.image?.subTitle?.fontFamily} onSelect={(val) => setLayout({ ...layout, image: { ...layout.image, subTitle: { ...layout.image?.subTitle, fontFamily: val.value } } })} />
                <Dropdown sx={{ maxWidth: '140px' }} icon={<FontSizeIcon />} options={fontSizes()} defaultValue={layout.image?.subTitle?.fontSize} onSelect={(val) => setLayout({ ...layout, image: { ...layout.image, subTitle: { ...layout.image?.subTitle, fontSize: val.value } } })} />
              </div>
              <div className="control-row">
                <div className='control-custom'>
                  <BoldIcon isActive={layout.image?.subTitle?.bold ?? false} onClick={() => setLayout({ ...layout, image: { ...layout.image, subTitle: { ...layout.image?.subTitle, bold: !(layout.image?.subTitle?.bold) } } })} />
                  <ItalicIcon isActive={layout.image?.subTitle?.italic ?? false} onClick={() => setLayout({ ...layout, image: { ...layout.image, subTitle: { ...layout.image?.subTitle, italic: !(layout.image?.subTitle?.italic) } } })} />
                  <UnderlineIcon isActive={layout.image?.subTitle?.underLine ?? false} onClick={() => setLayout({ ...layout, image: { ...layout.image, subTitle: { ...layout.image?.subTitle, underLine: !(layout.image?.subTitle?.underLine) } } })} />
                  <CrossCharacterIcon isActive={layout.image?.subTitle?.strickThrough ?? false} onClick={() => setLayout({ ...layout, image: { ...layout.image, subTitle: { ...layout.image?.subTitle, strickThrough: !(layout.image?.subTitle?.strickThrough) } } })} />
                </div>
                <div className='control-custom'>
                  <AlignLeftIcon isActive={layout.image?.subTitle?.align === 'left'} onClick={() => setLayout({ ...layout, image: { ...layout.image, subTitle: { ...layout.image?.subTitle, align: 'left' } } })} />
                  <AlignCenterIcon isActive={layout.image?.subTitle?.align === 'center'} onClick={() => setLayout({ ...layout, image: { ...layout.image, subTitle: { ...layout.image?.subTitle, align: 'center' } } })} />
                  <AlignRightIcon isActive={layout.image?.subTitle?.align === 'right'} onClick={() => setLayout({ ...layout, image: { ...layout.image, subTitle: { ...layout.image?.subTitle, align: 'right' } } })} />
                  <AlignJustifyIcon isActive={layout.image?.subTitle?.align === 'justify'} onClick={() => setLayout({ ...layout, image: { ...layout.image, subTitle: { ...layout.image?.subTitle, align: 'justify' } } })} />
                </div>
                <TextInput defaultValue={layout.image?.subTitle?.color} type='color' sx={{ width: '30%' }} onChange={(val: any) => setLayout({ ...layout, image: { ...layout.image, subTitle: { ...layout.image?.subTitle, color: val.target.value } } })} icon={<ColorPickIcon />} />
              </div>
            </AccordionDetails>
          </Accordion>)
          : (<></>)}
        {layout?.view?.overlay
          ? (<Accordion expanded={accordion.overlayContent} onChange={() => setAccordion({ ...accordion, overlayContent: !accordion.overlayContent })} classes={{ root: 'accordion-container' }}>
            <AccordionSummary
              expandIcon={<AccordionDownIcon />}
              aria-controls="panel1-content"
              id="panel1-header">
              <ContentIcon />
              Content Area
            </AccordionSummary>
            <AccordionDetails>
              <div className="control-row">
                <TextInput defaultValue={layout?.editor?.backgroundColor} value={layout?.editor?.backgroundColor} type='color' sx={{ maxWidth: '100%' }} onChange={(val: any) => setLayout({ ...layout, editor: { ...layout.editor, backgroundColor: val.target.value } })} icon={<BackgroundColorPickIcon />} />
                <TextInput defaultValue={getOpacityFromColor(layout?.image?.backgroundColor)} onChange={(val: any) => setLayout({ ...layout, editor: { ...layout.editor, backgroundColor: changeColorOpacity(layout?.editor?.backgroundColor ?? '', val.target.value) } })} icon={<OpacityIcon />} type='range' sx={{ maxWidth: '40%' }} max={100} min={0} />
              </div>
              <div className="inner-title">
                <MarginsIcon />
                Margin
              </div>
              <div className="control-row control-row-margin">
                <Dropdown hideSuffix sx={{ width: '100%' }} defaultValue={layout?.editor?.topPadding} onSelect={(val: any) => changePadding({ top: val.value })} icon={<MarginTop />} options={toolbarPaddings()} />
                <Dropdown hideSuffix sx={{ width: '100%' }} defaultValue={layout?.editor?.rightPadding} onSelect={(val: any) => changePadding({ right: val.value })} icon={<MarginRight />} options={toolbarPaddings()} />
                <Dropdown hideSuffix sx={{ width: '100%' }} defaultValue={layout?.editor?.bottomPadding} onSelect={(val: any) => changePadding({ bottom: val.value })} icon={<MarginBottom />} options={toolbarPaddings()} />
                <Dropdown hideSuffix sx={{ width: '100%' }} defaultValue={layout?.editor?.leftPadding} onSelect={(val: any) => changePadding({ left: val.value })} icon={<MarginLeft />} options={toolbarPaddings()} />
              </div>
              <div className="inner-title">
                <Positioning />
                Positioning
              </div>
              <div className="control-row control-row-margin">
                <TextInput defaultValue={layout?.editor?.positionTop} onChange={(val: any) => {
                  const [height, width] = (itinerary?.resolution ?? '420x594').split('x').map(Number);
                  setLayout({ ...layout, editor: { ...layout.editor, positionTop: ((height / 100) * val.target.value) } })
                }} icon={<MarginTop />} type='range' sx={{ maxWidth: '50%' }} max={100} min={0} />
                <TextInput defaultValue={layout?.editor?.positionLeft} onChange={(val: any) => {
                  const [height, width] = (itinerary?.resolution ?? '420x594').split('x').map(Number);
                  setLayout({ ...layout, editor: { ...layout.editor, positionLeft: ((width / 100) * val.target.value) } })
                }} icon={<MarginLeft />} type='range' sx={{ maxWidth: '50%' }} max={100} min={0} />
              </div>
              <div className="inner-title">
                <BorderRadiusIcon />
                Radius
              </div>
              <div className="control-row control-row-margin">
                <TextInput defaultValue={layout?.editor?.radiusTLeft} onChange={(val: any) => setLayout({ ...layout, editor: { ...layout.editor, radiusTLeft: val.target.value } })} icon={<TopRightRadius />} type='number' sx={{ maxWidth: '50%' }} max={100} min={0} />
                <TextInput defaultValue={layout?.editor?.radiusTRight} onChange={(val: any) => setLayout({ ...layout, editor: { ...layout.editor, radiusTRight: val.target.value } })} icon={<BottomRightRadius />} type='number' sx={{ maxWidth: '50%' }} max={100} min={0} />
                <TextInput defaultValue={layout?.editor?.radiusBRight} onChange={(val: any) => setLayout({ ...layout, editor: { ...layout.editor, radiusBRight: val.target.value } })} icon={<BottomLeftRadius />} type='number' sx={{ maxWidth: '50%' }} max={100} min={0} />
                <TextInput defaultValue={layout?.editor?.radiusBLeft} onChange={(val: any) => setLayout({ ...layout, editor: { ...layout.editor, radiusBLeft: val.target.value } })} icon={<TopLeftRadius />} type='number' sx={{ maxWidth: '50%' }} max={100} min={0} />
              </div>
            </AccordionDetails>
          </Accordion>)
          : (<></>)}
      </div>
      <p style={{
        alignSelf: 'flex-end',
        textTransform: 'capitalize',
        textAlign: 'center',
        color: '#B4B4B4',
        fontFamily: 'var(--primary-font)',
        fontSize: '14px',
        marginTop: '16px'
      }}>
        HAVE IDEAS ON HOW WE CAN IMPROVE?
        <button style={{
          textDecoration: 'underline',
          color: '#7BDBC0',
          background: 'none',
          border: 'none',
          cursor: 'pointer'
        }}>
          SHARE YOUR FEEDBACK
        </button>
      </p>
    </>
  );
};

export default Layout;
