const SubScript = (props: any) => {
  return (<svg onClick={props.onClick} style={{ cursor: 'pointer' }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_5186_13239)">
      <path d="M19.0903 17.2736H16.3631V17.0464C16.3631 16.42 16.873 15.91 17.4993 15.91C18.8784 15.91 19.9994 14.7891 19.9994 13.41C19.9994 12.0309 18.8784 10.9102 17.4993 10.9102H15.4539C14.9522 10.9102 14.5449 11.3165 14.5449 11.8192C14.5449 12.3219 14.9522 12.7282 15.4539 12.7282H17.4993C17.8757 12.7282 18.1812 13.0346 18.1812 13.41C18.1812 13.7855 17.8757 14.0918 17.4993 14.0918C15.8704 14.0918 14.5449 15.4173 14.5449 17.0464V18.1826C14.5449 18.6853 14.9522 19.0918 15.4539 19.0918H19.0903C19.592 19.0918 19.9994 18.6853 19.9994 18.1826C19.9994 17.6799 19.592 17.2736 19.0903 17.2736Z" fill="#251C15" />
      <path d="M11.4711 1.10561C11.0765 0.794674 10.5057 0.862755 10.1947 1.25825L5.90933 6.71185L1.62409 1.25825C1.31398 0.862755 0.743041 0.793676 0.347708 1.10561C-0.0477917 1.41555 -0.115872 1.98733 0.194901 2.38183L4.75296 8.18365L0.194901 13.9853C-0.11504 14.3806 -0.0467929 14.9516 0.347708 15.2615C0.513997 15.3934 0.712246 15.4561 0.909496 15.4561C1.17849 15.4561 1.44499 15.3371 1.62492 15.1089L5.90933 9.65529L10.1947 15.1089C10.3738 15.3371 10.6402 15.4561 10.9093 15.4561C11.1056 15.4561 11.3038 15.3934 11.4701 15.2615C11.8656 14.9516 11.9339 14.3798 11.6229 13.9853L7.0657 8.18365L11.6238 2.38183C11.9339 1.98733 11.8656 1.41555 11.4711 1.10561Z" fill="#251C15" />
    </g>
    <defs>
      <clipPath id="clip0_5186_13239">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>)
}

export default SubScript