import React from 'react'

const DecreaseIndentIcon = () => {
    return (<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_5186_13154)">
            <path d="M15.5008 2.33203C15.3183 2.332 15.1392 2.38193 14.983 2.47642C14.8269 2.5709 14.6995 2.70633 14.6148 2.86803L10.9481 9.86803C10.8683 10.0205 10.8292 10.1909 10.8345 10.3629C10.8398 10.5349 10.8894 10.7026 10.9785 10.8498C11.0675 10.997 11.1931 11.1187 11.343 11.2032C11.4929 11.2877 11.6621 11.3321 11.8341 11.332H13.1675C13.4068 11.3321 13.6383 11.2464 13.8198 11.0904C14.0013 10.9343 14.1209 10.7184 14.1567 10.4817C14.1925 10.245 14.1423 10.0034 14.0151 9.80061C13.8879 9.59785 13.6922 9.44744 13.4635 9.3767L13.8361 8.66537H17.1655L17.5381 9.3767C17.3095 9.44744 17.1138 9.59785 16.9866 9.80061C16.8594 10.0034 16.8091 10.245 16.8449 10.4817C16.8808 10.7184 17.0003 10.9343 17.1818 11.0904C17.3633 11.2464 17.5948 11.3321 17.8341 11.332H19.1675C19.3396 11.3321 19.5087 11.2877 19.6586 11.2032C19.8085 11.1187 19.9341 10.997 20.0232 10.8498C20.1123 10.7026 20.1619 10.5349 20.1672 10.3629C20.1725 10.1909 20.1333 10.0205 20.0535 9.86803L16.3868 2.86803C16.3021 2.70633 16.1748 2.5709 16.0186 2.47642C15.8624 2.38193 15.6834 2.332 15.5008 2.33203ZM14.5008 6.9987L15.5008 4.9987L16.5008 6.9987H14.5008Z" fill="#134B5F" />
            <path d="M4.16748 3.33203C4.16748 3.06681 4.06213 2.81246 3.87459 2.62492C3.68705 2.43739 3.4327 2.33203 3.16748 2.33203H1.83415C1.66208 2.332 1.49292 2.37637 1.34302 2.46085C1.19311 2.54533 1.06755 2.66706 0.978457 2.81427C0.889368 2.96148 0.83977 3.12919 0.834461 3.30117C0.829151 3.47316 0.86831 3.64361 0.948149 3.79603L4.61482 10.796C4.69947 10.9578 4.82679 11.0933 4.98298 11.1878C5.13916 11.2824 5.31825 11.3323 5.50082 11.3323C5.68339 11.3323 5.86247 11.2824 6.01866 11.1878C6.17484 11.0933 6.30216 10.9578 6.38682 10.796L10.0535 3.79603C10.1333 3.64361 10.1725 3.47316 10.1672 3.30117C10.1619 3.12919 10.1123 2.96148 10.0232 2.81427C9.93408 2.66706 9.80852 2.54533 9.65862 2.46085C9.50871 2.37637 9.33955 2.332 9.16748 2.33203H7.83415C7.59479 2.33192 7.36333 2.41767 7.18181 2.5737C7.0003 2.72973 6.88077 2.94569 6.84494 3.18236C6.80912 3.41902 6.85937 3.66068 6.98657 3.86345C7.11377 4.06621 7.30948 4.21663 7.53815 4.28736L5.50082 8.33203L3.46348 4.28736C3.66745 4.22416 3.84583 4.09732 3.97251 3.92541C4.09919 3.75351 4.16751 3.54557 4.16748 3.33203Z" fill="#134B5F" />
            <path d="M7.67121 11.6256C7.72058 11.6533 7.77627 11.6678 7.83288 11.6676C7.89239 11.6676 7.95083 11.6517 8.00212 11.6215C8.05341 11.5913 8.09568 11.548 8.12454 11.4959L13.1245 2.49593C13.1474 2.45758 13.1622 2.41505 13.1683 2.37085C13.1744 2.32665 13.1715 2.28168 13.1599 2.23861C13.1482 2.19553 13.1281 2.15524 13.1006 2.1201C13.0731 2.08496 13.0388 2.0557 12.9998 2.03406C12.9608 2.01241 12.9178 1.99881 12.8735 1.99407C12.8291 1.98933 12.7842 1.99355 12.7415 2.00647C12.6988 2.01939 12.6592 2.04075 12.6249 2.06928C12.5906 2.09782 12.5623 2.13295 12.5419 2.17259L7.54188 11.1726C7.49901 11.2498 7.48856 11.3409 7.51281 11.4259C7.53706 11.5108 7.59403 11.5826 7.67121 11.6256Z" fill="#134B5F" />
            <path d="M19.4993 14.3349H12.4993V13.0016C12.4994 12.938 12.4812 12.8758 12.4471 12.8222C12.4129 12.7686 12.3642 12.7259 12.3065 12.6991C12.2489 12.6723 12.1848 12.6625 12.1218 12.6709C12.0588 12.6793 11.9995 12.7056 11.951 12.7466L9.2903 15.0406C9.25097 15.0788 9.21971 15.1246 9.19836 15.1752C9.17701 15.2257 9.16602 15.28 9.16602 15.3349C9.16602 15.3898 9.17701 15.4441 9.19836 15.4947C9.21971 15.5452 9.25097 15.591 9.2903 15.6292L11.951 17.9229C11.9995 17.9639 12.0588 17.9901 12.1217 17.9986C12.1847 18.007 12.2488 17.9973 12.3064 17.9705C12.364 17.9437 12.4128 17.9011 12.4469 17.8475C12.4811 17.794 12.4993 17.7318 12.4993 17.6682V16.3349H19.4993C19.6761 16.3349 19.8457 16.2647 19.9707 16.1396C20.0957 16.0146 20.166 15.8451 20.166 15.6682V15.0016C20.166 14.8248 20.0957 14.6552 19.9707 14.5302C19.8457 14.4051 19.6761 14.3349 19.4993 14.3349Z" fill="#134B5F" />
        </g>
        <defs>
            <clipPath id="clip0_5186_13154">
                <rect width="20" height="20" fill="white" transform="translate(0.5)" />
            </clipPath>
        </defs>
    </svg>
    )
}

export default DecreaseIndentIcon