import { MouseEventHandler } from "react"

const AlignCenterIcon = (props: { isActive?: boolean, onClick: MouseEventHandler<SVGSVGElement> }) => {
    return (<svg onClick={props.onClick} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.4993 1.66797H2.49935C2.27834 1.66797 2.06637 1.75577 1.91009 1.91205C1.75381 2.06833 1.66602 2.28029 1.66602 2.5013C1.66602 2.72232 1.75381 2.93428 1.91009 3.09056C2.06637 3.24684 2.27834 3.33464 2.49935 3.33464H17.4993C17.7204 3.33464 17.9323 3.24684 18.0886 3.09056C18.2449 2.93428 18.3327 2.72232 18.3327 2.5013C18.3327 2.28029 18.2449 2.06833 18.0886 1.91205C17.9323 1.75577 17.7204 1.66797 17.4993 1.66797Z" fill={`${props.isActive ? '#e27c37' : '#251C15'}`} />
        <path d="M14.1667 8.33464C14.3877 8.33464 14.5996 8.24684 14.7559 8.09056C14.9122 7.93428 15 7.72232 15 7.5013C15 7.28029 14.9122 7.06833 14.7559 6.91205C14.5996 6.75577 14.3877 6.66797 14.1667 6.66797H5.83333C5.61232 6.66797 5.40036 6.75577 5.24408 6.91205C5.0878 7.06833 5 7.28029 5 7.5013C5 7.72232 5.0878 7.93428 5.24408 8.09056C5.40036 8.24684 5.61232 8.33464 5.83333 8.33464H14.1667Z" fill={`${props.isActive ? '#e27c37' : '#251C15'}`} />
        <path d="M17.4993 11.668H2.49935C2.27834 11.668 2.06637 11.7558 1.91009 11.912C1.75381 12.0683 1.66602 12.2803 1.66602 12.5013C1.66602 12.7223 1.75381 12.9343 1.91009 13.0906C2.06637 13.2468 2.27834 13.3346 2.49935 13.3346H17.4993C17.7204 13.3346 17.9323 13.2468 18.0886 13.0906C18.2449 12.9343 18.3327 12.7223 18.3327 12.5013C18.3327 12.2803 18.2449 12.0683 18.0886 11.912C17.9323 11.7558 17.7204 11.668 17.4993 11.668Z" fill={`${props.isActive ? '#e27c37' : '#251C15'}`} />
        <path d="M14.1667 18.3346C14.3877 18.3346 14.5996 18.2468 14.7559 18.0906C14.9122 17.9343 15 17.7223 15 17.5013C15 17.2803 14.9122 17.0683 14.7559 16.912C14.5996 16.7558 14.3877 16.668 14.1667 16.668H5.83333C5.61232 16.668 5.40036 16.7558 5.24408 16.912C5.0878 17.0683 5 17.2803 5 17.5013C5 17.7223 5.0878 17.9343 5.24408 18.0906C5.40036 18.2468 5.61232 18.3346 5.83333 18.3346H14.1667Z" fill={`${props.isActive ? '#e27c37' : '#251C15'}`} />
    </svg>
    )
}

export default AlignCenterIcon