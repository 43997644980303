import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: boolean = false;

export const userSlice = createSlice({
    name: 'sideBar',
    initialState,
    reducers: {
        changeSideBar: (state, action: PayloadAction<boolean>) => {
            return action.payload;
        }
    }
});

export const { changeSideBar } = userSlice.actions;
export default userSlice.reducer;