import { ReactElement } from 'react'
import './TextInput.scss'

const TextInput = ({ placeholder, onChange, icon, type, sx, value, max, min, defaultValue }: { defaultValue?: any, max?: number, min?: number, value?: any, sx?: React.CSSProperties, placeholder?: string, onChange: any, icon?: ReactElement, type?: React.HTMLInputTypeAttribute }) => {

    return (
        <div style={sx} className="text-input-container">
            {icon ? (<span className='icon'>{icon}</span>) : (<></>)}
            <input
                defaultValue={defaultValue}
                max={max}
                min={min}
                value={value}
                type={type ?? "text"}
                className="text-input"
                placeholder={placeholder}
                onChange={onChange}
            />
        </div>
    )
}

export default TextInput