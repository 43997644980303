import React from 'react'

const ContentIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.207 6.4635C11.4415 5.984 11.848 5.6235 12.3535 5.4495C12.565 5.3765 12.783 5.3485 13 5.3475V2.5C13 1.673 12.327 1 11.5 1H3.5C2.673 1 2 1.673 2 2.5V12.5C2 13.327 2.673 14 3.5 14H8.4745L8.4205 13.843C8.203 13.2085 8.2455 12.5305 8.539 11.931L11.207 6.4635ZM4 4H10C10.2765 4 10.5 4.224 10.5 4.5C10.5 4.776 10.2765 5 10 5H4C3.7235 5 3.5 4.776 3.5 4.5C3.5 4.224 3.7235 4 4 4ZM4 6H9.5C9.7765 6 10 6.224 10 6.5C10 6.776 9.7765 7 9.5 7H4C3.7235 7 3.5 6.776 3.5 6.5C3.5 6.224 3.7235 6 4 6ZM7.5 11H4C3.7235 11 3.5 10.776 3.5 10.5C3.5 10.224 3.7235 10 4 10H7.5C7.7765 10 8 10.224 8 10.5C8 10.776 7.7765 11 7.5 11ZM4 9C3.7235 9 3.5 8.776 3.5 8.5C3.5 8.224 3.7235 8 4 8H8.5C8.7765 8 9 8.224 9 8.5C9 8.776 8.7765 9 8.5 9H4ZM14.802 8.2175L12.133 13.687C11.9565 14.0475 11.651 14.3175 11.2725 14.4475L10.327 14.7725C10.2735 14.791 10.218 14.8 10.1645 14.8C9.957 14.8 9.763 14.67 9.692 14.4625L9.3665 13.517C9.236 13.137 9.2615 12.73 9.4375 12.3705L12.1065 6.902C12.223 6.662 12.427 6.482 12.6795 6.395C12.9315 6.308 13.203 6.324 13.443 6.442L14.342 6.8805C14.582 6.9975 14.762 7.2015 14.849 7.454C14.936 7.7065 14.9195 7.9775 14.802 8.2175Z" fill="#251C15" />
        </svg>

    )
}

export default ContentIcon