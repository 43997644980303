import { Modal } from '@mui/material'
import MagicIcon from '../../../assets/images/builder/magicWand.gif'
import './MagicWand.scss'

type MagicWandProps = {
  open: boolean,
}

const MagicWand = (props: MagicWandProps) => {
  return (
    <Modal
      open={props.open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="magic-wand-container">
        <img className='wand-icon' src={MagicIcon} alt="Magic Icon" />

        <div className="magic-wand-content">
          <div className="header">We’re Working Our Magic</div>
          <div className="body">Generating a new itinerary based on the criteria and the preferences you selected.</div>
        </div>
      </div>
    </Modal>
  )
}

export default MagicWand