import React from 'react'

const CapitalizeIcon = () => {
    return (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_328_447)">
                <path d="M9.46047 15.853C9.21121 15.8509 9.04984 15.7342 8.96199 15.4903C8.78652 15.0034 8.61093 14.5165 8.44176 14.0274C8.40867 13.9318 8.36441 13.9001 8.26164 13.9004C6.81988 13.9042 5.37808 13.904 3.93633 13.9007C3.83492 13.9005 3.78761 13.9285 3.75367 14.0273C3.58863 14.5078 3.41605 14.9857 3.2434 15.4635C3.13941 15.7513 2.98925 15.8535 2.68539 15.8534C2.21429 15.8533 1.74324 15.8549 1.27215 15.8531C0.858005 15.8515 0.637536 15.5423 0.775427 15.1516C1.12508 14.1612 4.27457 5.30127 4.43172 4.86275C4.61039 4.36412 4.92675 4.13912 5.45297 4.13768C5.88597 4.13647 6.31902 4.13631 6.75203 4.13775C7.26312 4.13947 7.58226 4.36549 7.76015 4.8424C7.86718 5.12936 11.1355 14.3385 11.4195 15.145C11.5605 15.5458 11.3427 15.8507 10.9166 15.8533C10.6691 15.8547 9.6984 15.8551 9.46047 15.853ZM6.09551 7.51127C5.6173 8.84721 5.15422 10.1409 4.68672 11.447H7.51133C7.04109 10.1398 6.57593 8.84686 6.09551 7.51127Z" fill="#134B5F" />
                <path d="M20.26 7.63185C20.26 7.25474 20.0676 7.06005 19.6913 7.0572C19.4341 7.05548 19.1772 7.05634 18.9202 7.05634C18.526 7.05634 18.3655 7.18993 18.2961 7.59275C18.2431 7.5665 18.2014 7.54591 18.16 7.52482C16.9624 6.91646 15.7488 6.88419 14.5604 7.51197C13.2457 8.20654 12.5463 9.32591 12.4601 10.8138C12.4381 11.1932 12.4572 11.5749 12.4515 11.9554C12.4461 12.3443 12.4958 12.7277 12.6206 13.0928C13.0879 14.4625 14.0143 15.3572 15.4205 15.7318C16.1928 15.9373 16.9558 15.8802 17.7001 15.5939C17.8962 15.5185 18.0846 15.424 18.277 15.3384C18.3407 15.7335 18.6142 15.8702 18.9034 15.8597C19.1649 15.8505 19.4272 15.8508 19.6887 15.8579C20.0327 15.8671 20.266 15.6256 20.2648 15.2836C20.256 12.7331 20.26 10.1823 20.26 7.63185ZM17.8185 11.9383C17.8185 12.7482 17.1622 13.4048 16.352 13.4048C15.5421 13.4048 14.8855 12.7482 14.8855 11.9383V10.9665C14.8855 10.1563 15.5421 9.49974 16.352 9.49974C16.7571 9.49974 17.1237 9.66388 17.3892 9.92939C17.6544 10.1949 17.8186 10.5614 17.8186 10.9665V11.9383H17.8185Z" fill="#134B5F" />
            </g>
            <defs>
                <clipPath id="clip0_328_447">
                    <rect width="20" height="20" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default CapitalizeIcon