import { Navigate, BrowserRouter as Router, useRoutes } from "react-router-dom";
import LeadTracker from "./pages/Dashboard/LeadTracker/LeadTracker";
import logoImg from "./assets/images/logo.svg";
import "./App.scss";
import "./Master.scss";
import NotImplemented from "./pages/NotImplemented/NotImplemented";
import Login from "./pages/Login/Login";
import { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { RootState } from "./store/store";
import Calender from "./pages/Dashboard/Calender/Calender";
import Builder from "./pages/Dashboard/Builder/Builder";
import { useTranslation } from "react-i18next";
import { t, use } from "i18next";
import { LoadScript } from "@react-google-maps/api";
import CircularProgress from "@mui/material/CircularProgress";
import { googleApiKey } from "./helpers/helperfunctions";
import FirebaseFunctions from "./helpers/firebaseFunctions";
import { getFromLocalStorage } from "./helpers/storageConfig";
import { auth } from "./services/firebaseService";

const AppRoutes = ({ loggedIn, loading }: { loggedIn: boolean, loading: boolean }) => {
  return useRoutes((loading)
    ? ([
      { path: "/", element: (<Loading />) },
    ])
    : ([
      { path: "/", element: loggedIn ? (<Navigate to={"/dashboard/lead-tracker"} />) : (<Login />) },
      { path: "/login", element: loggedIn ? (<Navigate to={"/dashboard/lead-tracker"} />) : (<Login />) },
      { path: "/dashboard/lead-tracker", element: loggedIn ? (<LeadTracker />) : (<Navigate to={"/"} />) },
      { path: "/dashboard/calendar", element: loggedIn ? (<Calender />) : (<Navigate to={"/"} />) },
      { path: "/dashboard/builder", element: loggedIn ? (<Builder />) : (<Navigate to={"/"} />) },
      { path: "*", element: loggedIn ? (<NotImplemented />) : <Navigate to={"/"} /> },
    ]));
}

function App() {
  const firebaseFunctions = new FirebaseFunctions();
  const { i18n } = useTranslation();
  const [loggedIn, setLoggedIn] = useState<boolean>(true);
  const user = useSelector((state: RootState) => state.user);
  const [loading, setLoading] = useState<boolean>(false);

  const libraries: ("places")[] = ['places'];

  useEffect(() => {
    validateLogin();
  }, [user])

  const validateLogin = async () => {
    const customToken = getFromLocalStorage('firebaseCustomToken');
    i18n.changeLanguage(user.language);
    if (user.displayName) {
      // if (user.displayName && customToken) {
      // if (!auth.currentUser) {
      //   setLoading(true);
      //   firebaseFunctions.authenticateUser(customToken).then(() => {
      //     return setLoggedIn(true);
      //   }).catch(() => {
      //     return setLoggedIn(false);
      //   }).finally(() => {
      //     setLoading(false);
      //   });
      // }
      return setLoggedIn(true);
    }
    return setLoggedIn(false);
  }

  return (
    <>
      <section className="small-screen-message">
        <div className="txt">
          <img src={logoImg} className="logo" alt="logo" />
          <h4>{t('screenWidthHeader')}</h4>
          <p>{t('screenWidthDescription')}</p>
        </div>
      </section>
      <main className="main">
        <LoadScript googleMapsApiKey={googleApiKey()} libraries={libraries} loadingElement={<Loading />}>
          <Router>
            <AppRoutes loading={loading} loggedIn={loggedIn} />
          </Router>
        </LoadScript>

      </main>
    </>
  );
}

export default App;

const Loading = () => {
  return (
    <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress color='inherit' size={'20px'} />
    </div>
  )
}