const NumberLine = (props: any) => {
  return (<svg onClick={props.onClick} style={{ cursor: 'pointer' }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_5186_13232)">
      <path d="M1.95652 7.39062H0.652174C0.292173 7.39062 0 7.6828 0 8.0428C0 8.4028 0.292173 8.69497 0.652174 8.69497H1.95652C2.07658 8.69497 2.17386 8.79242 2.17386 8.91231V9.12981C2.17386 9.2497 2.07658 9.34715 1.95652 9.34715H1.52169C0.682585 9.34715 0 10.0297 0 10.8688V11.9558C0 12.3158 0.292173 12.608 0.652174 12.608H2.82603C3.18604 12.608 3.47821 12.3158 3.47821 11.9558C3.47821 11.5958 3.18604 11.3037 2.82603 11.3037H1.30435V10.8688C1.30435 10.7489 1.40179 10.6515 1.52169 10.6515H1.95652C2.79562 10.6515 3.47821 9.96891 3.47821 9.12981V8.91231C3.47821 8.07321 2.79562 7.39062 1.95652 7.39062Z" fill="#251C15" />
      <path d="M1.95652 14.3477H0.652174C0.292173 14.3477 0 14.6398 0 14.9998C0 15.3598 0.292173 15.652 0.652174 15.652H1.95652C2.07658 15.652 2.17386 15.7493 2.17386 15.8693V16.0867C2.17386 16.2067 2.07658 16.3042 1.95652 16.3042H1.08701C0.727008 16.3042 0.434836 16.5964 0.434836 16.9564C0.434836 17.3164 0.727008 17.6085 1.08701 17.6085H1.95652C2.07658 17.6085 2.17386 17.7058 2.17386 17.8259V18.0432C2.17386 18.1633 2.07658 18.2607 1.95652 18.2607H0.652174C0.292173 18.2607 0 18.5529 0 18.9129C0 19.2729 0.292173 19.565 0.652174 19.565H1.95652C2.79562 19.565 3.47821 18.8825 3.47821 18.0432V17.8259C3.47821 17.5023 3.37471 17.2033 3.2018 16.9564C3.37471 16.7094 3.47821 16.4102 3.47821 16.0867V15.8693C3.47821 15.0302 2.79562 14.3477 1.95652 14.3477Z" fill="#251C15" />
      <path d="M0.652174 1.73794H1.30435V4.99881C1.30435 5.35881 1.59652 5.65099 1.95652 5.65099C2.31652 5.65099 2.6087 5.35881 2.6087 4.99881V1.08577C2.6087 0.725766 2.31652 0.433594 1.95652 0.433594H0.652174C0.292173 0.433594 0 0.725766 0 1.08577C0 1.44577 0.292173 1.73794 0.652174 1.73794Z" fill="#251C15" />
      <path d="M6.08631 3.91496H19.1298C19.6106 3.91496 19.9995 3.52614 19.9995 3.04529C19.9995 2.56444 19.6106 2.17578 19.1298 2.17578H6.08631C5.60546 2.17578 5.2168 2.56444 5.2168 3.04529C5.2168 3.52614 5.60546 3.91496 6.08631 3.91496Z" fill="#251C15" />
      <path d="M19.1298 9.12891H6.08631C5.60546 9.12891 5.2168 9.51757 5.2168 9.99842C5.2168 10.4793 5.60546 10.8679 6.08631 10.8679H19.1298C19.6106 10.8679 19.9995 10.4793 19.9995 9.99842C19.9995 9.51757 19.6106 9.12891 19.1298 9.12891Z" fill="#251C15" />
      <path d="M19.1298 16.0859H6.08631C5.60546 16.0859 5.2168 16.4748 5.2168 16.9556C5.2168 17.4365 5.60546 17.8251 6.08631 17.8251H19.1298C19.6106 17.8251 19.9995 17.4365 19.9995 16.9556C19.9995 16.4748 19.6106 16.0859 19.1298 16.0859Z" fill="#251C15" />
    </g>
    <defs>
      <clipPath id="clip0_5186_13232">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>)
}

export default NumberLine