function FilterIcon() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_5186_8814)">
                <path d="M17.3114 0.0111794H2.68867C1.32478 0.0111794 0.218018 1.11794 0.218018 2.48183C0.218018 3.07434 0.430427 3.64449 0.810527 4.09167L6.59029 10.8329C6.8027 11.09 6.92568 11.4142 6.92568 11.7384V18.0436C6.92568 18.7255 7.27224 19.3516 7.85357 19.7093C8.16659 19.8994 8.52433 20 8.88208 20C9.18392 20 9.47459 19.9329 9.75407 19.7876L11.99 18.6696C12.6607 18.3343 13.0744 17.6635 13.0744 16.9145V11.7272C13.0744 11.3918 13.1973 11.0676 13.4097 10.8217L19.1895 4.08049C19.5696 3.63331 19.782 3.06316 19.782 2.47065C19.782 1.10676 18.6753 0 17.3114 0V0.0111794ZM17.9151 2.99609L12.1353 9.73728C11.6546 10.2963 11.3974 11.0006 11.3974 11.7384V16.9257C11.3974 17.0375 11.3416 17.1269 11.2409 17.1716L9.00505 18.2895C8.88208 18.3566 8.78146 18.3119 8.73674 18.2784C8.69203 18.2448 8.60259 18.1778 8.60259 18.0436V11.7384C8.60259 11.0006 8.34546 10.2963 7.86475 9.73728L2.08498 2.99609C1.96201 2.85075 1.89493 2.67188 1.89493 2.48183C1.89493 2.04584 2.25267 1.68809 2.68867 1.68809H17.3114C17.7474 1.68809 18.1051 2.04584 18.1051 2.48183C18.1051 2.67188 18.038 2.85075 17.9151 2.99609Z" fill="#134B5F" />
            </g>
            <defs>
                <clipPath id="clip0_5186_8814">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default FilterIcon