import React from 'react'

const ResolutionIcon = () => {
    return (<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_7220_11527)">
            <path d="M14.098 3.18637H10.8929V0H4.93164V11.947H14.098V3.18637Z" fill="#251C15" fill-opacity="0.6" />
            <path d="M13.951 2.247L11.8301 0.144531V2.247H13.951Z" fill="#251C15" fill-opacity="0.6" />
            <path d="M14.2943 12.3368L13.6314 12.9997L14.2054 13.5737H2.43462V11.947H3.58003V11.0095H2.43462V10.072H3.58003V9.13447H2.43462V8.19697H3.58003V7.25947H2.43462V6.32197H3.58003V5.38447H2.43462V1.79456L3.00863 2.36853L3.67153 1.70563L1.96588 0L0.260219 1.70563L0.923125 2.36853L1.49713 1.79453V13.5737H0V14.5112H1.49713V16H2.43462V14.5112H14.2054L13.6314 15.0852L14.2943 15.7481L16 14.0424L14.2943 12.3368Z" fill="#251C15" fill-opacity="0.6" />
        </g>
        <defs>
            <clipPath id="clip0_7220_11527">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
    )
}

export default ResolutionIcon