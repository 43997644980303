const AddUserSvg = (props: { size?: number }) => {
    return (
        <svg width={`${props.size ?? '20'}`} height={`${props.size ?? '20'}`} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle opacity="0.6" cx="10" cy="10" r="10" fill="#CDCDCE" />
            <g opacity="0.6" clip-path="url(#clip0_6690_7816)">
                <path d="M7.3375 9.83594H9.57578C11.4203 9.83594 12.9133 11.3312 12.9133 13.1734V14.125C12.9133 14.9031 12.2828 15.5359 11.5023 15.5359H5.40859C4.63047 15.5336 4 14.9031 4 14.125V13.1734C4 11.3289 5.49531 9.83594 7.3375 9.83594Z" fill="#251C15" />
                <path d="M8.45772 9.21328C9.76896 9.21328 10.8319 8.15031 10.8319 6.83906C10.8319 5.52782 9.76896 4.46484 8.45772 4.46484C7.14647 4.46484 6.0835 5.52782 6.0835 6.83906C6.0835 8.15031 7.14647 9.21328 8.45772 9.21328Z" fill="#251C15" />
                <path d="M15.3252 7.94375H14.5564V7.175C14.5564 6.80234 14.2541 6.5 13.8814 6.5C13.5088 6.5 13.2064 6.80234 13.2064 7.175V7.94375H12.4377C12.065 7.94375 11.7627 8.24609 11.7627 8.61875C11.7627 8.99141 12.065 9.29375 12.4377 9.29375H13.2064V10.0625C13.2064 10.4352 13.5088 10.7375 13.8814 10.7375C14.2541 10.7375 14.5564 10.4352 14.5564 10.0625V9.29375H15.3252C15.6979 9.29375 16.0002 8.99141 16.0002 8.61875C16.0002 8.24609 15.6979 7.94375 15.3252 7.94375Z" fill="#251C15" />
            </g>
            <defs>
                <clipPath id="clip0_6690_7816">
                    <rect width="12" height="12" fill="white" transform="translate(4 4)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default AddUserSvg