import CustomDrawer from "../Mui/CustomDrawer/CustomDrawer";
import goBack from "../../assets/images/go-back.svg";
import CustomerInformation from "./CustomerInformation";
import ArrivalDeparture from "./ArrivalDeparture";
import SpecialRequirements from "./SpecialRequirements";
import GeneralPreferences from "./GeneralPreferences";
import GeneralItinerary from "./GenerateItinerary";
import Functions from "./NewLeadFunctions";
import LeadTrackerFunctions from "../../pages/Dashboard/LeadTracker/LeadFunctions";
import { CircularProgress } from "@mui/material";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";
import { setItineraryId } from "../../store/slices/itinerarySlice";
import { useEffect, useRef, useState } from "react";
import { ICreateLeadData, ICreateLeadUser, IItinerary, ILead, ILeadValidation, IStepperItem } from "../../helpers/Interfaces";
import "./NewLead.scss";

type NewLeadProps = {
  readonly open: boolean;
  readonly close: () => void;
  readonly refresh: CallableFunction;
  readonly leadData?: ILead;
  readonly organizationUsers: ICreateLeadUser[]
};

function NewLead({ open, close, refresh, leadData, organizationUsers }: NewLeadProps) {

  let leadId: string | undefined = undefined;
  const trackerFunctions = new LeadTrackerFunctions();
  const functions = new Functions();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const divARef = useRef<HTMLDivElement | null>(null);
  const divBRef = useRef<HTMLDivElement | null>(null);

  const [leadFormError, setLeadFormError] = useState<ILeadValidation>({ name: false, phoneNumber: false, email: false, nationality: false, portArrival: false, portDeparture: false });
  const [saveClientLoading, setSaveClientLoading] = useState(false);
  const [saveItineraryLoading, setSaveItineraryLoading] = useState(false);
  const [saveLeadLoading, setSaveLeadLoading] = useState(false);
  const [selectedItinerary, setSelectedItinerary] = useState<IItinerary>();
  const [step, setStep] = useState(0);
  const [leadFormData, setLeadFormData] = useState<ICreateLeadData>({
    stage: 'NEW_LEAD'
  });
  const [leadFormUserData, setLeadFormUserData] = useState<ICreateLeadUser>({
    name: '', phoneNumber: '', email: '', nationality: ''
  });

  const stepperSteps: IStepperItem[] = [
    { title: t('identification'), description: t('customerReferral') },
    { title: t('customerReferral'), description: t('travelInformation') },
    { title: t('considerations'), description: t('specialRequirements') },
    { title: t('preferences'), description: t('travelPreferences') }
  ];

  useEffect(() => {
    if (leadData) {
      const user: ICreateLeadUser = leadData.user;
      user.id = leadData.userId;
      setLeadFormUserData(user);

      const lead: any = { ...leadData };
      delete lead.user;
      setLeadFormData(lead);

    }
  }, [leadData])

  const handleBack = () => () => {
    if (step === 0) {
      handleClose();
      return;
    }
    if (step === 4) {
      setSelectedItinerary(undefined);
    }
    setStep((t) => (t - 1));
  }

  const handleForward = () => {
    if (step === 0) {
      const newLead = leadFormData;
      if (!leadFormUserData.id) {
        newLead!.newUser = true;
      }
      setLeadFormData({ ...newLead })
    }

    if (step === 3) {
      return handleGenerateLead().then(() => {
        refresh(true);
        handleClose();

      })
    }

    if (step === 4) {
      return handleGenerateLead().then(() => {
        refresh(true);
        handleClose();
      })
    }

    if (!functions.customInformationValidate(step, leadFormUserData, leadFormError, setLeadFormError)) {
      return;
    }

    if (!functions.createItineraryValidate(step, leadFormData, leadFormError, setLeadFormError)) {
      return;
    }

    setStep((t) => (t + 1));
  }

  const handleGenerateLead = () => {
    const handleLead = new Promise((resolve, rejects) => {
      if (leadData?.id) {
        const user = leadFormUserData;
        user.id = leadData.userId;
        trackerFunctions.updateLead(leadFormData, setSaveLeadLoading).then(() => {
          resolve(leadData.id);
        }).catch(() => {
          rejects();
        });
      } else {
        trackerFunctions.createLead(leadFormData, leadFormUserData, setSaveLeadLoading).then((response: any) => {
          resolve(response.data.data);
        }).catch(() => {
          rejects();
        });
      }
    });
    return handleLead;
  }

  const handleCreateClient = () => {
    const newLead = { stage: 'NEW_LEAD' } as ICreateLeadData;
    newLead.newUser = true;
    trackerFunctions.createLead(newLead, leadFormUserData, setSaveLeadLoading).then(() => {
      refresh(true)
      handleClose();
    })
    // trackerFunctions.createClient(leadFormUserData, setSaveClientLoading).then(() => {
    //   handleClose();
    //   refresh(false)
    // })
  }

  const handleCreateItinerary = () => {
    setSaveItineraryLoading(true);
    handleGenerateLead().then((id) => {
      trackerFunctions.handleGenerateItinerary(leadFormData, leadFormUserData, id as string, (selectedItinerary ? selectedItinerary : undefined))
        .then((response: any) => {
          refresh();
          handleClose();
          const itineraryId = response.data.data;
          dispatch(setItineraryId(itineraryId));
          navigate("/dashboard/builder");
        }).finally(() => {
          setSaveItineraryLoading(false);
        })
    });
  }

  const getLoading = () => {
    if (saveClientLoading || saveLeadLoading || saveItineraryLoading) return true;
    return false;
  }

  const handleClose = () => {
    setStep(0);
    setLeadFormData({ stage: 'NEW_LEAD' });
    setLeadFormUserData({ name: '', phoneNumber: '', email: '', nationality: '' });
    setLeadFormError({ name: false, phoneNumber: false, email: false, nationality: false, portArrival: false, portDeparture: false })
    close();
    setSelectedItinerary(undefined)
  }

  const getStep = () => {
    if (step === 0) {
      return (<CustomerInformation edit={leadData?.id !== undefined} organizationUsers={organizationUsers} leadUser={leadFormUserData} setLeadsUser={setLeadFormUserData}
        leadValidation={leadFormError} divARef={divARef} divBRef={divBRef} stepperSteps={stepperSteps}
        lead={leadFormData} setLeads={setLeadFormData} />)
    } else if (step === 1) {
      return (<ArrivalDeparture leadValidation={leadFormError} lead={leadFormData} setLead={setLeadFormData} divBRef={divBRef} stepperSteps={stepperSteps} divARef={divARef} />)
    } else if (step === 2) {
      return (<SpecialRequirements setLead={setLeadFormData} lead={leadFormData} divBRef={divBRef} stepperSteps={stepperSteps} divARef={divARef} />)
    } else if (step === 3) {
      return (<GeneralPreferences lead={leadFormData} setLead={setLeadFormData} divBRef={divBRef} stepperSteps={stepperSteps} divARef={divARef} />)
    } else if (step === 4) {
      return (<GeneralItinerary selectedItinerary={selectedItinerary} setSelectedItinerary={setSelectedItinerary} lead={leadFormData} setLead={setLeadFormData} divBRef={divBRef} stepperSteps={stepperSteps} divARef={divARef} />)
    }
  }

  const getHeader = () => {
    if (step === 0) {
      return (leadData?.id ? 'Update Lead' : 'Add new Lead')
    } else if (step === 1) {
      return (leadData?.id ? 'Update Itinerary' : 'Create an Itinerary')
    } else if (step === 2) {
      return (leadData?.id ? 'Update Special Considerations' : 'Any Special Considerations')
    } else if (step === 3) {
      return (leadData?.id ? 'Update Tour Preferences' : 'Capturing Tour Preferences')
    } else if (step === 4) {
      return ('Select a Itinerary or Generate a new Itinerary')
    }
  }

  const getPrimaryButton = (step: number, update: string | undefined) => {
    if (step === 0) {
      return (update ? t('updateItinerary') : t('createItinerary'));
    } else if (step === 1) {
      return (update ? t('updateConsiderations') : t('addConsiderations'));
    } else if (step === 2) {
      return (update ? t('updateReferences') : t('addReferences'));
    } else if (step === 3) {
      return (update ? t('updateLead') : t('createLead'));
    } else if (step === 4) {
      return (update ? t('updateLead') : t('createLead'));
    }
  }

  return (
    <CustomDrawer backDropDisable open={open} close={handleClose}>
      <div className="exp-modal-inner">
        <div className="top-middle">
          <div className="top">
            <div className="gb-head">
              {/* <img aria-hidden src={goBack} className={`${step === 0 ? 'go-back-arrow' : ''}`} alt="go back" onClick={handleBack()} /> */}
              <button disabled={getLoading()} onClick={handleBack()}>
                <img aria-hidden src={goBack} alt="go back" />
              </button>
              <span className="fz-32 primary-font">{getHeader()}</span>
            </div>
          </div>
          {getStep()}
        </div>
        <div className="bottom">
          <div className="lft">
            <button disabled={getLoading()} onClick={() => handleClose()} className="button border-btn" type="button">
              <span className="txt fz-16 ttu primary-font">{t('cancel')}</span>
            </button>
          </div>
          <div className="rht">
            {((step === 0 && leadFormUserData.id === undefined)) ? (
              // saveClientLoading
              saveLeadLoading
                ? (<div className="item-center c-orange mx-5">
                  {/* <CircularProgress color='inherit' size='20px' /> */}<></>
                </div>)
                : (<button disabled={getLoading()} onClick={handleCreateClient} className="button light-orange-btn" type="button">
                  <span className="txt fz-16 ttu primary-font">{leadData?.id ? t('updateClient') : t('saveClient')}</span>
                </button>)) : (<></>)}

            {saveLeadLoading
              ? (<div className="item-center c-orange mx-5">
                <CircularProgress color='inherit' size='20px' />
              </div>)
              : (<button disabled={getLoading()} onClick={() => handleForward()} className="button orange-btn" type="button">
                <span className="txt fz-16 ttu primary-font">{getPrimaryButton(step, leadData?.id)}</span>
              </button>)}
            {(step === 4)
              ? (saveItineraryLoading)
                ? (<div className="item-center c-orange mx-5">
                  <CircularProgress color='inherit' size='20px' />
                </div>)
                : (<button disabled={getLoading()} onClick={handleCreateItinerary} className="button orange-btn" type="button">
                  <span className="txt fz-16 ttu primary-font">{selectedItinerary ? 'Customize Itinerary' : t('create')}</span>
                </button>)
              : (<></>)}
            {(step === 3)
              ? (<button disabled={getLoading()} onClick={() => setStep(step + 1)} className="button orange-btn" type="button">
                <span className="txt fz-16 ttu primary-font">Generate Itinerary</span>
              </button>)
              : (<></>)}
          </div>
        </div>
      </div>
    </CustomDrawer>
  );
}

export default NewLead;


