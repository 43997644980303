import "./Header.scss";

import calenderImg from "../../assets/images/calender-icon.svg";
import notificationImg from "../../assets/images/notification-icon.svg";
import profileImg from "../../assets/images/profile-image.png";
import dotImg from "../../assets/images/dots-icon.svg";
import Notification from "../Notification/Notification";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

type HeaderProps = {
  title: string;
};

const Header = ({ title }: HeaderProps) => {
  const [openNotification, setOpenNotification] = useState(false);
  const route = useLocation();

  const toggleNotification = (newOpen: boolean) => () => {
    setOpenNotification(newOpen);
  };
  return (
    <div className="header-inner">
      <div className="lft">
        <h2 className="header-title fz-32 primary-font c-black fw-400 mb-0">{title}</h2>
      </div>
      <div className="rht">
        <div className="rht-inner">
          <div className="icn">
            {route.pathname !== "/dashboard/calendar"
              ? (<Link to={'/dashboard/calendar'}>
                <img src={calenderImg} alt="calender" />
              </Link>)
              : (<></>)}
          </div>
          <div className="notifications" onClick={toggleNotification(false)}>
            {/* <span className="notification-num"><span>12</span></span> */}
            <img src={notificationImg} alt="calender" />
          </div>
          <div className="profile">
            <div className="profile-img">
              <img src={profileImg} alt="calender" />
            </div>
            <div className="dot-menu">
              <img src={dotImg} alt="calender" />
            </div>
          </div>
        </div>
      </div>
      <Notification open={openNotification} close={toggleNotification(false)} />
    </div>
  );
};

export default Header;
