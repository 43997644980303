import { useEffect, useRef, useState } from 'react';
import Calendar from '@toast-ui/react-calendar';
import { IGetAllCalenderLead, ILead } from '../../../helpers/Interfaces';
import { formatDate, getLeadStage, getMonthName, getStageColor, getUniqueColor } from '../../../helpers/helperfunctions';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { EventObject } from '@toast-ui/calendar/dist/toastui-calendar.min.css';
import dayjs from 'dayjs';
import '@toast-ui/calendar/dist/toastui-calendar.min.css';
import './CustomCalender.scss';
import CalenderFunctions from '../../../pages/Dashboard/Calender/CalenderFunctions';
import UserFilterRemove from '../../../assets/images/dashboard/UserFilterRemove';
import UserFilter from '../../../assets/images/dashboard/UserFilter';
import FilterIconRemove from '../../../assets/images/dashboard/FilterIconRemove';
import FilterIcon from '../../../assets/images/dashboard/FilterIcon';
import { t } from 'i18next';

type CustomCalenderProps = {
  leadTasks: IGetAllCalenderLead[];
  onSelectCard: (lead: ILead) => void,
  filterLead: any,
  userFilter: CallableFunction,
  handleClick: CallableFunction
};

const CustomCalender = ({ leadTasks, onSelectCard, filterLead, handleClick, userFilter }: CustomCalenderProps) => {
  const functions = new CalenderFunctions();
  const [loading, setLoading] = useState(false);
  const [currentDate, setCurrentDate] = useState<string>('');
  const [selectedValue, setSelectedValue] = useState({ view: 'month', value: 'month' });

  const calendarRef = useRef<Calendar | null>(null);

  useEffect(() => {
    updateCurrentDate();
  }, []);

  useEffect(() => {
    changeView(selectedValue.value);
  }, [selectedValue]);

  useEffect(() => {
    loadTasks(leadTasks);
  }, [leadTasks]);

  const loadTasks = (leadTasks: IGetAllCalenderLead[]) => {

    const loadedEvents: EventObject[] = [];
    for (const leadTask of leadTasks) {
      const color = getUniqueColor(leadTask.id ?? '');
      for (const task of leadTask.tasks) {
        const date = dayjs(task.dueDate);
        const endDate = date.add(0.5, 'hour');
        const event: EventObject = {
          id: leadTask.id,
          title: {
            name: leadTask.leadUser,
            stage: leadTask.leadStage,
            taskName: task.taskName,
            taskStage: task.stage,
          },
          start: date,
          end: endDate,
          category: 'time',
          isReadOnly: true,
          backgroundColor: color.backgroundColor,
          color: color.textColor
        };
        loadedEvents.push(event);
      }
    }

    const calendar = calendarRef.current?.getInstance();
    if (!calendar) return;
    calendar.clear();
    calendar.createEvents(loadedEvents);

  }

  const changeView = (view: any) => {
    const calendar = calendarRef.current?.getInstance();
    if (!calendar) return;
    calendar.changeView(view);
    updateCurrentDate();

  };

  const changeMonth = (action: 'prev' | 'next' | 'today') => {
    const calendar = calendarRef.current?.getInstance();
    if (!calendar) return;

    if (action === 'prev') calendar.prev();
    if (action === 'next') calendar.next();
    if (action === 'today') calendar.today();

    updateCurrentDate();
  };

  const updateCurrentDate = () => {
    const calendar = calendarRef.current?.getInstance();
    if (!calendar) return;

    const date = calendar.getDate();
    const month = getMonthName(date.getMonth() + 1);
    const year = date.getFullYear();

    setCurrentDate(`${month} ${year}`);
  };

  const handleChange = (event: any) => {
    const calendar = calendarRef.current?.getInstance();
    if (!calendar) return;

    const value = event.target.value as string;
    let view = value;

    if (value === 'week') {
      const options = {
        month: {},
        week: {
          taskView: ['task'],
          scheduleView: false,
        },
        day: {}
      };
      calendar.setOptions({
        week: {
          taskView: ['task'],
        }
      });
    } else if (value === 'month') {

    }
    setSelectedValue({ value, view });
  };

  return (
    <div className='custom-calender'>
      <div className="calender-header">
        <div className="date-content">
          <button onClick={() => changeMonth('today')} className="button-today">today</button>
          <span className="calender-date">{currentDate}</span>
          <ArrowBackIos onClick={() => changeMonth('prev')} className='arrow-icon' />
          <ArrowForwardIos onClick={() => changeMonth('next')} className='arrow-icon' />
        </div>
        <div className="filter-content">
          <div className="filter-outer">
            <span onClick={() =>  userFilter(undefined, filterLead.user)}>
              {filterLead.user
                ? (<UserFilterRemove />)
                : (<UserFilter />)}
            </span>
            <span onClick={(event: any) => filterLead.other ? userFilter(undefined, true) : handleClick(event)}>
              {filterLead.other
                ? (<FilterIconRemove />)
                : (<FilterIcon />)}
            </span>
          </div>
          <Select
            classes={{ root: 'drop-down' }}
            value={selectedValue.view}
            onChange={handleChange}
            className="dropdown"
          >
            <MenuItem value="month">Month</MenuItem>
            <MenuItem value="week">Week</MenuItem>
          </Select>
        </div>
      </div>
      <Calendar
        ref={calendarRef}
        isReadOnly
        template={{
          popupDetailTitle: (detail) => (
            `<div class="toastui-calendar-popup-top-line" style="background-color: ${detail.backgroundColor};"></div>`
          ),
          popupDetailDate: (detail) => {
            const color = getStageColor(detail.title.taskStage);
            const stage = getLeadStage(detail.title.stage);
            const html = `
              <div class='popup-details-header'>
                <div class='popup-details-header-title'>${detail.title.name}</div>
                <div class='popup-details-header-stage' style="color: ${stage?.color}">${t(detail.title.stage)}</div>
              </div>  
              <div class='popup-details-body'>
                <div class='popup-details-body-task'>
                  <div class='popup-details-body-task-name'>${detail.title.taskName}</div>
                  <div class='popup-details-body-task-stage' style="background-color: var(${color})">${detail.title.taskStage}</div>
                </div>
                <div class='popup-details-body-task-date'>${formatDate(detail.start, 'dddd, MMMM DD')}</div>
              </div>`;
            const headerElement = document.querySelector(`.toastui-calendar-detail-container`);
            if (headerElement) {
              headerElement.addEventListener('click', () => functions.getLeadById(detail.id, onSelectCard, setLoading));
            }
            return html;
          },
          popupDetailState: (detail) => {
            return ('');
          },
          time: (schedule) => (
            <span style={{ textOverflow: 'ellipsis', color: schedule.color }}>{schedule.title.taskName}</span>
          ),
        }}
        useDetailPopup
        events={[]}
      />

    </div>
  );
};

export default CustomCalender;
