import React from 'react'

const FontSizeIcon = () => {
    return (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_328_410)">
                <path d="M2.03456 4.65903C2.21981 4.67732 2.40684 4.65662 2.5836 4.59825C2.76037 4.53989 2.92296 4.44515 3.06089 4.32015C3.19883 4.19515 3.30907 4.04265 3.38451 3.87246C3.45994 3.70228 3.49891 3.51818 3.49889 3.33203V2.9987H7.16556C7.25396 2.9987 7.33875 3.03382 7.40126 3.09633C7.46377 3.15884 7.49889 3.24363 7.49889 3.33203V16.6654C7.49889 16.7538 7.46377 16.8386 7.40126 16.9011C7.33875 16.9636 7.25396 16.9987 7.16556 16.9987H6.22289C5.88447 16.9931 5.55592 17.1127 5.30038 17.3347C5.04485 17.5566 4.88036 17.8652 4.83856 18.201C4.82027 18.3863 4.84097 18.5733 4.89934 18.7501C4.9577 18.9268 5.05243 19.0894 5.17744 19.2274C5.30244 19.3653 5.45494 19.4755 5.62513 19.551C5.79531 19.6264 5.9794 19.6654 6.16556 19.6654H11.4416C11.78 19.671 12.1085 19.5513 12.3641 19.3294C12.6196 19.1075 12.7841 18.7989 12.8259 18.463C12.8442 18.2778 12.8235 18.0908 12.7651 17.914C12.7067 17.7372 12.612 17.5746 12.487 17.4367C12.362 17.2988 12.2095 17.1885 12.0393 17.1131C11.8691 17.0376 11.685 16.9987 11.4989 16.9987H10.4989C10.4105 16.9987 10.3257 16.9636 10.2632 16.9011C10.2007 16.8386 10.1656 16.7538 10.1656 16.6654V3.33203C10.1656 3.24363 10.2007 3.15884 10.2632 3.09633C10.3257 3.03382 10.4105 2.9987 10.4989 2.9987H14.1656V3.2747C14.1599 3.61311 14.2796 3.94167 14.5015 4.19721C14.7235 4.45274 15.032 4.61722 15.3679 4.65903C15.5531 4.67732 15.7402 4.65662 15.9169 4.59825C16.0937 4.53989 16.2563 4.44515 16.3942 4.32015C16.5322 4.19515 16.6424 4.04265 16.7178 3.87246C16.7933 3.70228 16.8322 3.51818 16.8322 3.33203V1.66536C16.8322 1.31174 16.6917 0.972604 16.4417 0.722556C16.1917 0.472507 15.8525 0.332031 15.4989 0.332031H2.16556C1.81193 0.332031 1.47279 0.472507 1.22275 0.722556C0.972697 0.972604 0.832222 1.31174 0.832222 1.66536V3.2747C0.826587 3.61311 0.946241 3.94167 1.16818 4.19721C1.39012 4.45274 1.69869 4.61722 2.03456 4.65903Z" fill="#134B5F" />
                <path d="M19.5013 10.668H12.8346C12.6578 10.668 12.4883 10.7382 12.3632 10.8632C12.2382 10.9883 12.168 11.1578 12.168 11.3346V12.3346C12.168 12.5114 12.2382 12.681 12.3632 12.806C12.4883 12.9311 12.6578 13.0013 12.8346 13.0013C13.0114 13.0013 13.181 12.9311 13.306 12.806C13.4311 12.681 13.5013 12.5114 13.5013 12.3346V12.0013H15.5013V18.3346H14.8346C14.6578 18.3346 14.4883 18.4049 14.3632 18.5299C14.2382 18.6549 14.168 18.8245 14.168 19.0013C14.168 19.1781 14.2382 19.3477 14.3632 19.4727C14.4883 19.5977 14.6578 19.668 14.8346 19.668H17.5013C17.6781 19.668 17.8477 19.5977 17.9727 19.4727C18.0977 19.3477 18.168 19.1781 18.168 19.0013C18.168 18.8245 18.0977 18.6549 17.9727 18.5299C17.8477 18.4049 17.6781 18.3346 17.5013 18.3346H16.8346V12.0013H18.8346V12.3346C18.8346 12.5114 18.9049 12.681 19.0299 12.806C19.1549 12.9311 19.3245 13.0013 19.5013 13.0013C19.6781 13.0013 19.8477 12.9311 19.9727 12.806C20.0977 12.681 20.168 12.5114 20.168 12.3346V11.3346C20.168 11.1578 20.0977 10.9883 19.9727 10.8632C19.8477 10.7382 19.6781 10.668 19.5013 10.668Z" fill="#134B5F" />
            </g>
            <defs>
                <clipPath id="clip0_328_410">
                    <rect width="20" height="20" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default FontSizeIcon