import Carousel from 'react-material-ui-carousel'
import Slider1 from '../../assets/images/login/slider-01.svg'
import Slider2 from '../../assets/images/login/slider-02.svg'
import './CustomCarousel.scss'

const CustomCarousel = () => {
    var items = [
        {
            img: Slider1,
            name: "Mini-CRM",
            description: "Efficiently manage leads, track interactions and streamline your sales with our Mini-CRM."
        },
        {
            img: Slider2,
            name: "Dynamic Itinerary Builder",
            description: "Create unique itineraries effortlessly with our intuitive Itinerary Builder."
        }
    ]

    return (
        <div className="carousel-inner">
            <Carousel sx={{ width: '56vw' }}>
                {items.map((item, i) => <Item key={i} item={item} />)}
            </Carousel>
        </div>
    )
}

function Item(props: any) {
    return (
        <div className='carousal-content'>
            <div className='image-content'>
                <img src={props.item.img} alt="" />
            </div>
            <div className='text-content'>
                <h2 className='fz-32 primary-font'>{props.item.name}</h2>
                <p className='fz-20 body-font'>{props.item.description}</p>
            </div>
        </div>
    )
}

export default CustomCarousel;
