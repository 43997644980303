import { useEffect, useState } from 'react'
import { IStepperItem } from '../../helpers/Interfaces';

type StepperProps = {
    divBRef: React.MutableRefObject<HTMLDivElement | null>
    divARef: React.MutableRefObject<HTMLDivElement | null>
    steps: IStepperItem[]
    activeStep: number
}

const Stepper = ({ divARef, divBRef, steps, activeStep }: StepperProps) => {
    const [height, setHeight] = useState(0);

    useEffect(() => {
        updateHeight();

        window.addEventListener("resize", updateHeight);

        return () => {
            window.removeEventListener("resize", updateHeight);
        };
    }, []);

    useEffect(() => {
        if (divBRef.current) {
            divBRef.current.style.height = `${height - 40}px`;
        }
    }, [height]);

    const updateHeight = () => {
        if (divARef.current) {
            setHeight(divARef.current.clientHeight);
        }
    };
    return (
        <div className="lft flex-0-a">
            <div ref={divARef} className="lft-inner">
                {steps.map((step, index) => {
                    const current = index + 1;
                    return (<div key={step.title} className={`num-item ${(current === activeStep) ? 'active' : (current < activeStep ? 'compleated' : '')}`}>
                        <div className="txt flex-0-a">
                            <span className="lbl primary-font fz-16 ttu db">{step.title}</span>
                            <span className="des fz-14">{step.description}</span>
                        </div>
                        <div className="number flex-0-a">
                            <span className="num primary-font c-dark-green">{current}</span>
                        </div>
                    </div>);
                })}
            </div>
        </div>
    )
}

export default Stepper