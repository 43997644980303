import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import React, { ReactElement, useEffect, useState } from 'react';
import { BaseSingleInputFieldProps, DateValidationError, FieldSection, UseDateFieldProps } from '@mui/x-date-pickers';
import { Button } from '@mui/material';

type CustomDatePickerProps = {
    children: ReactElement,
    date: string,
    setDate: CallableFunction
}

interface ButtonFieldProps
    extends UseDateFieldProps<Dayjs, false>,
    BaseSingleInputFieldProps<
        Dayjs | null,
        Dayjs,
        FieldSection,
        false,
        DateValidationError
    > {
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const CustomDatePicker = ({ children, date, setDate }: CustomDatePickerProps) => {
    const ButtonField = (props: ButtonFieldProps) => {
        const {
            setOpen,
            id,
            InputProps: { ref } = {},
        } = props;

        return (
            <button
                id={id}
                ref={ref}
                onClick={() => setOpen?.((prev) => !prev)} >
                {children}
            </button>
        );
    }

    const ButtonDatePicker = (props: Omit<DatePickerProps<Dayjs>, 'open' | 'onOpen' | 'onClose'>,) => {
        const [open, setOpen] = useState(false);
        return (
            <DatePicker
                slots={{ ...props.slots, field: ButtonField }}
                slotProps={{ ...props.slotProps, field: { setOpen } as any }}
                {...props}
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
            />
        );
    }
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ButtonDatePicker value={dayjs(date)}
                onChange={(newValue) => { setDate(newValue); }} />
        </LocalizationProvider>
    )
}

export default CustomDatePicker